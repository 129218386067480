import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Avatar, Button, Col, FloatButton, Form, Modal, Row, Image, Table, Tabs, Tooltip } from "antd";
import { DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/MonthlyWorkbookForm";
import StudentCarousel from "components/StudentCarousel";
import PdfModal from "modals/PdfModal";

const Component = ({ permissions, setRoute }) => {
	const initialState = { classes: [], data: [], students: [], pdfState: false };
	const [state, setState] = useState(initialState);

	const { pdfState, pdfUri } = state;

	const pdfModal = (value) => {
		setState((prevState) => ({
			...prevState,
			pdfState: true,
			pdfUri: `${ENV.basePath}${value}`,
		}));
	};

	const handlePdfClose = () => {
		setState((prevState) => ({ ...prevState, pdfState: false }));
	};

	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => index + 1,
		},
		{
			title: resources.image,
			dataIndex: "image",
			render: (value) => {
				let pdfChecker = value.slice(-1);
				return (
					<>
						{pdfChecker == "f" ? (
							<>
								<Button
									type="primary"
									onClick={() => pdfModal(value)}
								>
									دیدن PDF
								</Button>
								<PdfModal
									pdfState={pdfState}
									pdfUri={pdfUri}
									onClose={handlePdfClose}
								/>
							</>
						) : (
							// <object
							// 	data={`${ENV.basePath}${value}`}
							// 	type="application/pdf"
							// />
							<Image
								src={`${ENV.basePath}${value}`}
								// shape="square"
								width={40}
								height={40}
							/>
						)}
					</>
				);
			},
		},
	];
	const { classes, data, loading, selected, students } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue(params);

		if (params.student) {
			setState((prevState) => ({ ...prevState, loading: true }));

			api.stPanel
				.workbook(params)
				.then((data) => {
					setState((prevState) => ({ ...prevState, data, loading: false }));
				})
				.catch(() => {
					setState((prevState) => ({ ...prevState, loading: false }));
				});
		} else {
			setState((prevState) => ({ ...prevState, data: [] }));
		}
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleView = (selected) => {
		//console.log(selected);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const params = GetUrlParams(search);

			const _params = params.class === values.class ? params : {};

			const _search = ObjectToURL({ ..._params, ...values });

			navigate(`${pathname}?${_search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.stPanel
			.workbook()
			.then((res) => {
				//console.log("line 97 is ", res);
				setState((prevState) => ({ ...prevState, data: res }));
			})
			.catch(() => {});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	return (
		<>
			<Tabs
				className="sp-tabs"
				activeKey={4}
				items={[
					{
						key: 1,
						label: <Link to={paths.edustudent.dailyReports}>{`${resources.dailyreport}`}</Link>,
					},
					{
						key: 2,
						label: <Link to={paths.edustudent.dailyActivity}>{`${resources.eduDailyActivity}`}</Link>,
					},
					{
						key: 3,
						label: <Link to={paths.edustudent.classAssessments}>{`${resources.assessment} ${resources.class}`}</Link>,
					},
					{
						key: 4,
						label: <Link to={paths.edustudent.monthlyGradeSheet}>{`${resources.workbook} ${resources.monthly}`}</Link>,
					},
					{
						key: 5,
						label: <Link to={paths.edustudent.generalExams}>{`${resources.exam} ${resources.general}`}</Link>,
					},
					{
						key: 6,
						label: <Link to={paths.edustudent.generalReports}>{`${resources.report} ${resources.total}`}</Link>,
					},
				]}
			/>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={false}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			{contextHolder}
		</>
	);
};

export default Component;
