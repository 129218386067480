import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer, Form, Input, Select } from "antd";
import { debounce } from "lodash";
import PropTypes from "prop-types";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

const Component = ({ data = {}, onUpdate, options: { grades = [] } }) => {
	const initialState = { masters: [], specials: [], teachers: [] };
	const [state, setState] = useState(initialState);
	const { masters, specials, submitting, teachers, visible } = state;
	const { id, name } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const handleSearch = debounce((search, type) => {
		if (search?.length > 1) {
			api.user
				.list({ paging: false, search })
				.then((data) => {
					switch (type) {
						case "teacher": {
							setState((prevState) => ({
								...prevState,
								teachers: data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
							}));

							break;
						}
						case "master": {
							setState((prevState) => ({
								...prevState,
								masters: data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
							}));

							break;
						}
						case "special": {
							setState((prevState) => ({
								...prevState,
								specials: data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
							}));

							break;
						}
					}
				})
				.catch(() => {});
		}
	}, 500);

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));

					const action = hash === `#${Modals.EDIT}` ? api.class.update(values, id) : api.class.create(values);

					action
						.then(() => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({
						...prevState,
						visible: true,
						grade: data.grade?.id,
						teachers: [{ value: data.teacher?.id, label: `${data.teacher?.firstName} ${data.teacher?.lastName}` }],
						masters: [{ value: data.teacher?.id, label: `${data.teacher?.firstName} ${data.teacher?.lastName}` }],
						specials: data.specialTeachers.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })),
					}));

					form.setFieldsValue({
						...data,
						grade: data.grade?.value,
						floorMasterId: data.floorMaster?.id,
						specialTeacherIds: data.specialTeachers.map(({ id }) => id),
						teacherId: data.teacher?.id,
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={hash === `#${Modals.EDIT}` ? `${resources.edit} ${resources.grade} "${name}"` : `${resources.add} ${resources.grade}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="name"
					label={resources.name}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="grade"
					label={resources.class}
					rules={rules.required}
				>
					<Select options={grades} />
				</Form.Item>
				<Form.Item
					name="teacherId"
					label={resources.teacher}
					rules={rules.required}
				>
					<Select
						options={teachers}
						onSearch={(value) => handleSearch(value, "teacher")}
						filterOption={false}
						showSearch
					/>
				</Form.Item>
				<Form.Item
					name="floorMasterId"
					label={resources.manciple}
					rules={rules.required}
				>
					<Select
						options={masters}
						onSearch={(value) => handleSearch(value, "master")}
						filterOption={false}
						showSearch
					/>
				</Form.Item>
				<Form.Item
					name="specialTeacherIds"
					label={resources.generalTeacher}
					rules={rules.required}
				>
					<Select
						mode="multiple"
						options={specials}
						onSearch={(value) => handleSearch(value, "special")}
						filterOption={false}
						showSearch
					/>
				</Form.Item>
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
