import { connect } from "react-redux";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { AlertOutlined, BookOutlined, DashboardOutlined, FolderOutlined, ReadOutlined, SettingOutlined } from "@ant-design/icons";

import { setRoute } from "scripts/reducer";
import { Permissions, Types } from "scripts/settings";
import resources from "scripts/resources";
import paths from "scripts/paths";

import DashboardIcon from "./images/Icons/dashboard.png";
import Disciplinary from "./images/Icons/disciplinary.png";
import Educational from "./images/Icons/educational.png";
import Configs from "./images/Icons/configs.png";
import Refers from "./images/Icons/refers.png";
import UserInfoIcon from "./images/Icons/people.png";

// layouts
import AuthLayout from "layouts/Auth";
import DefaultLayout from "layouts/Default";

// auth
import LoginPage from "pages/auth/Login";
import UserInform from "pages/default/userInfo";

// default
import EmptyPage from "pages/default/Empty";
import Dashboard from "./pages/default/Dashboard";
import StDashboard from "./pages/student/Dashboard";

import AssessmentsPage from "pages/default/Assessments";
import ClassesPage from "pages/default/Classes";
import ClassAssessmentsPage from "pages/default/ClassAssessments";
import GeneralExamsPage from "pages/default/GeneralExams";
import MonthlyWorkbooksPage from "pages/default/MonthlyWorkbooks";
import RolesPage from "pages/default/Roles";
import StudentsPage from "pages/default/Students";
import UsersPage from "pages/default/Users";
import ManageCalendar from "pages/default/ManageCalendar";

import DisGeneralReportsPage from "pages/default/DisGeneralReports";
import DisRefersPage from "pages/default/DisRefers";
import DisRegulationsPage from "pages/default/DisRegulations";
import DisHurryPage from "pages/default/DisHurry";
import DisAbsencePage from "pages/default/DisAbsence";
import DisLatencyPage from "pages/default/DisLatency";
import DisReportsPage from "pages/default/DisReports";

import EduGeneralReportsPage from "pages/default/EduGeneralReports";
import EduRefersPage from "pages/default/EduRefers";
import EduRegulationsPage from "pages/default/EduRegulations";
import EduReportsPage from "pages/default/EduReports";
import EduDailyActivityReportPage from "pages/default/EduDailyActivityReport";

import EduParentDescription from "pages/default/ParentDescriptionEdu";
import DisParentDescription from "pages/default/ParentDescriptionDis";

import SchoolTuition from "pages/default/FinancSchoolTuition";
import BusFee from "pages/default/FinancBusFee";

import MeetParents from "pages/default/Meeting";
import MeetManagment from "pages/default/ManagmentMeeting";

import onlineLearning from "./pages/default/OnlineLearning";

import Documents from "./pages/default/Documents";
import Pixels from "./pages/default/Pixels";

import Library from "./pages/default/Library";

// student
import StDailyReports from "./pages/student/EduDailyReports";
import StDailyActivityReport from "./pages/student/EduDailyActivityReport";
import StClassAssessments from "./pages/student/EduClassAssessment";
import StGeneralReport from "./pages/student/EduGeneralReports";
import StMonthlyGradeSheet from "./pages/student/EduMonthlyWorkbooks";
import StGeneralExams from "./pages/student/EduGeneralExams";
import StDisGeneralReports from "./pages/student/DisEduGeneralReports";
import StudentUserInfo from "./pages/student/userInfo";
import StudentFinancial from "./pages/student/Financial";
import StudentMeetTeacher from "./pages/student/MeetTeacher";
import StudentMeetManagment from "./pages/student/MeetManagment";
import StudentOnlineLerning from "./pages/student/OnlineLearning";
import StudentDocuments from "./pages/student/Documents";
import StudentLibrary from "./pages/student/Library";
import StudentPixels from "./pages/student/Pixels";
import StDisHurryPage from "pages/student/DisHurry";
import StDisAbsencePage from "pages/student/DisAbsence";
import StDisLatencyPage from "pages/student/DisLatency";
import StDisReportsPage from "pages/student/DisReports";

//
// errors
import NotFoundPage from "pages/result/NotFound";

let isAdmin;

// const dashboardi = (userDashboard, permissions) => {
// 	if (userDashboard == 'Admin') {
// 		isAdmin = true
// 	} else {
// 		isAdmin= false
// 	}
// 	isAdmin =
// }
console.log("permmmmmmmmmmmm", Permissions);

let defaultRoutes = [
	{
		icon: (
			<img
				src={Disciplinary}
				alt="disciplinary"
			/>
		),
		label: `${resources.discipline}`,
		name: "disciplinary",
		children: [
			{
				component: DisReportsPage,
				label: `${resources.items} ${resources.discipline}`,
				name: "disReports",
				path: paths.disciplinary.reports,
				permission: Permissions.disciplinaryReport,
			},
			{
				component: DisAbsencePage,
				label: `${resources.items} ${resources.absence}`,
				name: "absenceReports",
				path: paths.disciplinary.absenceReports,
				permission: Permissions.disciplinaryReport,
			},
			{
				component: DisLatencyPage,
				label: `${resources.items} ${resources.latency}`,
				name: "latencyReports",
				path: paths.disciplinary.latencyReports,
				permission: Permissions.disciplinaryReport,
			},
			{
				component: DisHurryPage,
				label: `${resources.items} ${resources.hurry}`,
				name: "hurryReports",
				path: paths.disciplinary.hurryReports,
				permission: Permissions.disciplinaryReport,
			},
			{
				component: DisGeneralReportsPage,
				label: `${resources.report} ${resources.total}`,
				name: "disGeneralReports",
				path: paths.disciplinary.generalReports,
				permission: Permissions.disciplinaryGeneralReport,
			},
			{
				component: DisRegulationsPage,
				label: `${resources.regulation} ${resources.discipline}`,
				name: "disRegulations",
				path: paths.disciplinary.regulations,
				permission: Permissions.disciplinaryRegulation,
			},
		],
	},
	{
		icon: (
			<img
				src={Educational}
				alt="educational"
			/>
		),
		label: `${resources.education}${resources.y}`,
		name: "educational",
		children: [
			{
				component: EduReportsPage,
				label: `${resources.dailyreport}`,
				name: "eduDailyReports",
				path: paths.educational.dailyReports,
				permission: Permissions.educationalDR,
			},
			{
				component: EduDailyActivityReportPage,
				label: `${resources.eduDailyActivity}`,
				name: "EduDailyActivityReportPage",
				path: paths.educational.dailyActivity,
				permission: Permissions.educationalDR,
			},
			{
				component: ClassAssessmentsPage,
				label: `${resources.assessment} ${resources.class}`,
				name: "classAssessments",
				path: paths.educational.classAssessments,
				permission: Permissions.classAssessment,
			},
			{
				component: MonthlyWorkbooksPage,
				label: `${resources.workbook} ${resources.monthly}`,
				name: "monthlyWorkbooks",
				path: paths.educational.monthlyWorkbooks,
				permission: Permissions.monthlyWorkbook,
			},
			{
				component: GeneralExamsPage,
				label: `${resources.exam} ${resources.general}`,
				name: "generalExams",
				path: paths.educational.generalExams,
				permission: Permissions.generalExam,
			},
			{
				component: EduGeneralReportsPage,
				label: `${resources.report} ${resources.total}`,
				name: "eduGeneralReports",
				path: paths.educational.generalReports,
				permission: Permissions.educationalGeneralReport,
			},
			{
				component: EduRegulationsPage,
				label: `${resources.regulation} ${resources.education}${resources.y}`,
				name: "eduRegulations",
				path: paths.educational.regulations,
				permission: Permissions.educationalRegulation,
			},
			{
				component: onlineLearning,
				label: `${resources.education} ${resources.Virtual}`,
				name: "onlineLearning",
				path: paths.onlineLearning.videos,
				permission: Permissions.course,
			},
		],
	},
	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		component: MeetManagment,
		label: `${resources.meet} ${resources.with} ${resources.management}`,
		path: paths.meeting.managment,
		permission: Permissions.managementMeeting,
	},
	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		component: MeetParents,
		label: `${resources.meet} ${resources.with} ${resources.teacher}`,
		path: paths.meeting.meeting,
		permission: Permissions.meeting,
	},

	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		component: Documents,
		label: `${resources.documents}`,
		path: paths.documents.documents,
		permission: Permissions.document,
	},

	{
		icon: (
			<img
				src={Refers}
				alt="educational"
			/>
		),
		label: resources.library,
		name: "library",
		children: [
			{
				component: Library,
				label: `${resources.library}`,
				name: "schoolTuition",
				path: paths.library.library,
				permission: Permissions.document,
			},
			{
				component: Pixels,
				label: `${resources.pixel}`,
				path: paths.pixels.pixels,
				permission: Permissions.pixel,
			},
		],
	},
	{
		icon: (
			<img
				src={Refers}
				alt="financial"
			/>
		),
		label: resources.financial,
		name: "financial",
		children: [
			{
				component: SchoolTuition,
				label: `${resources.tuition} ${resources.school}`,
				name: "schoolTuition",
				path: paths.financial.schoolTuition,
				permission: Permissions.salary,
			},
			{
				component: BusFee,
				label: `${resources.tuition} ${resources.bus}`,
				name: "busTuition",
				path: paths.financial.busFee,
				permission: Permissions.salary,
			},
		],
	},

	{
		icon: (
			<img
				src={Refers}
				alt="refers"
			/>
		),
		label: resources.refers,
		name: "refers",
		children: [
			{
				component: EduRefersPage,
				label: `${resources.education}${resources.y}`,
				name: "eduRefers",
				path: paths.refer.educational,
				permission: Permissions.educationalReference,
			},
			{
				component: DisRefersPage,
				label: `${resources.discipline}`,
				name: "disRefers",
				path: paths.refer.disciplinary,
				permission: Permissions.disciplinaryReference,
			},
		],
	},
	{
		icon: (
			<img
				src={Refers}
				alt="refers"
			/>
		),
		label: `${resources.description} ${resources.parent}`,
		name: "parentDescription",
		children: [
			{
				component: EduParentDescription,
				label: `${resources.education}${resources.y}`,
				name: "eduParentDescription",
				path: paths.parentDescription.eduDescription,
				permission: Permissions.educationalDR,
			},
			{
				component: DisParentDescription,
				label: `${resources.discipline}`,
				name: "disParentDescription",
				path: paths.parentDescription.disDescription,
				permission: Permissions.disciplinaryRegulation,
			},
		],
	},
	{
		icon: (
			<img
				src={Configs}
				alt="configs"
			/>
		),
		label: resources.config,
		name: "configs",
		children: [
			{
				component: StudentsPage,
				label: resources.students,
				name: "students",
				path: paths.configs.students,
				permission: Permissions.student,
			},
			{
				component: UsersPage,
				label: resources.users,
				name: "users",
				path: paths.configs.users,
				permission: Permissions.user,
			},
			{
				component: ClassesPage,
				label: `${resources.class}${resources.ha}`,
				name: "classes",
				path: paths.configs.classes,
				permission: Permissions.class,
			},
			{
				component: AssessmentsPage,
				label: `${resources.assessment}${resources.ha}`,
				name: "assessments",
				path: paths.configs.assessments,
				permission: Permissions.assessment,
			},
			{
				component: ManageCalendar,
				label: `${resources.management} ${resources.calendar}`,
				name: "calendar",
				path: paths.configs.manageCalendar,
				permission: Permissions.role,
			},
			{
				component: RolesPage,
				label: `${resources.role}${resources.ha}`,
				name: "roles",
				path: paths.configs.roles,
				permission: Permissions.role,
			},
		],
	},
	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		label: `${resources.profile}`,
		path: paths.auth.userInfo,
		component: UserInform,
	},
	{
		path: "*",
		component: NotFoundPage,
	},
];

const studentRoutes = [
	{
		path: paths.root,
		redirect: paths.dashboard,
	},
	{
		component: StDashboard,
		icon: (
			<img
				src={DashboardIcon}
				alt="dashboardIcon"
			/>
		),
		label: resources.dashboard,
		name: "dashboard",
		path: paths.dashboard,
	},
	{
		icon: (
			<img
				src={Disciplinary}
				alt="disciplinary"
			/>
		),
		label: `${resources.discipline}`,
		name: "discipline",
		children: [
			{
				component: StDisGeneralReports,
				label: `${resources.report} ${resources.total}`,
				name: "disGeneralReports",
				path: paths.edustudent.disGeneralReports,
			},
			{
				component: StDisReportsPage,
				label: `${resources.report} ${resources.discipline}`,
				name: "StDisReportsPage",
				path: paths.edustudent.disReports,
			},
			{
				component: StDisLatencyPage,
				label: `${resources.report} ${resources.latency}`,
				name: "StDisLatencyPage",
				path: paths.edustudent.latencyReports,
			},
			{
				component: StDisAbsencePage,
				label: `${resources.report} ${resources.absence}`,
				name: "StDisAbsencePage",
				path: paths.edustudent.absenceReports,
			},
			{
				component: StDisHurryPage,
				label: `${resources.report} ${resources.hurry}`,
				name: "StDisHurryPage",
				path: paths.edustudent.hurryReports,
			},
		],
	},
	{
		icon: (
			<img
				src={Educational}
				alt="educational"
			/>
		),
		label: `${resources.education}${resources.y}`,
		name: "education",
		children: [
			{
				component: StDailyReports,
				label: `${resources.dailyreport}`,
				name: "dailyReports",
				path: paths.edustudent.dailyReports,
			},
			{
				component: StDailyActivityReport,
				label: `${resources.eduDailyActivity}`,
				name: "dailyActivity",
				path: paths.edustudent.dailyActivity,
			},
			{
				component: StClassAssessments,
				label: `${resources.assessment} ${resources.class}`,
				name: "classAssessments",
				path: paths.edustudent.classAssessments,
			},
			{
				component: StMonthlyGradeSheet,
				label: `${resources.workbook} ${resources.monthly}`,
				name: "monthlyGradeSheet",
				path: paths.edustudent.monthlyGradeSheet,
			},
			{
				component: StGeneralExams,
				label: `${resources.exam} ${resources.general}`,
				name: "generalExams",
				path: paths.edustudent.generalExams,
			},
			{
				component: StGeneralReport,
				label: `${resources.report} ${resources.total}`,
				name: "stGeneralReport",
				path: paths.edustudent.generalReports,
			},
			{
				component: StudentOnlineLerning,
				label: `${resources.education} ${resources.Virtual}`,
				name: "onlineLearning",

				path: paths.edustudent.onlineLearning,
			},
		],
	},
	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		label: `${resources.financial}`,
		path: paths.edustudent.financial,
		component: StudentFinancial,
	},
	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		component: StudentMeetManagment,
		label: `${resources.meet} ${resources.with} ${resources.management}`,
		path: paths.edustudent.meetManagment,
	},
	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		component: StudentMeetTeacher,
		label: `${resources.meet}`,
		path: paths.edustudent.meetTeacher,
	},

	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		component: StudentDocuments,
		label: `${resources.documents}`,
		path: paths.edustudent.documents,
	},
	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		component: StudentPixels,
		label: `${resources.pixel}`,
		path: paths.edustudent.pixel,
	},
	{
		icon: (
			<img
				src={Educational}
				alt="educational"
			/>
		),
		component: StudentLibrary,
		label: `${resources.library}`,
		path: paths.edustudent.library,
	},
	{
		icon: (
			<img
				src={UserInfoIcon}
				alt="userInfoIcon"
			/>
		),
		component: StudentUserInfo,
		label: `${resources.profile}`,
		path: paths.edustudent.studentUserInfo,
	},
];

const menuCreator = (routes, permissions, userDashboard) => {
	if (userDashboard == Types.dashboard.admin) {
		if (routes[0].path != "/") {
			routes.unshift(
				{
					// redirect: useDashboard == 'Default' && permission.
					path: paths.root,
					redirect: paths.dashboard,
				},
				{
					component: Dashboard,
					icon: (
						<img
							src={DashboardIcon}
							alt="dashboardIcon"
						/>
					),
					label: resources.dashboard,
					name: "dashboard",
					path: paths.dashboard,
				}
			);
		}
	} else if (userDashboard == Types.dashboard.default) {
		let redirect;

		if (permissions.EducationalDR.read) {
			redirect = paths.educational.dailyReports;
		} else if (permissions.ClassAssessment.read) {
			redirect = paths.educational.classAssessments;
		} else if (permissions.MonthlyWorkbook.read) {
			redirect = paths.educational.monthlyWorkbooks;
		} else if (permissions.GeneralExam.read) {
			redirect = paths.educational.generalExams;
		} else if (permissions.DisciplinaryReport.read) {
			redirect = paths.disciplinary.reports;
		} else {
			redirect = paths.auth.userInfo;
		}

		routes.unshift({
			// redirect: useDashboard == 'Default' && permission.
			path: paths.root,
			redirect: redirect,
		});
	}
	const _routes = [];
	routes?.forEach(({ children, icon, label, name, path, permission, redirect }) => {
		if (children?.length) {
			const _children = menuCreator(children, permissions);

			if (_children.length) _routes.push({ children: _children, icon, label, name, path });
		} else if (!redirect && (!permission || permissions[permission]?.read)) {
			_routes.push({ icon, label, name, path });
		}
	});

	return _routes;
};

const routeCreator = (routes, permissions, setRoute, parent) => {
	const _routes = [];
	routes?.forEach(({ children, component: Component, label, name, path, permission, redirect }) => {
		if (redirect) {
			_routes.push({
				path,
				element: (
					<Navigate
						to={redirect}
						replace
					/>
				),
			});
		} else if (children?.length) {
			const _children = routeCreator(children, permissions, setRoute, name);

			_children.forEach((child) => _routes.push(child));
		} else if (!permission || permissions[permission]?.read) {
			_routes.push({
				path,
				element: (
					<Component
						permissions={permissions[permission] || {}}
						setRoute={() => setRoute({ parent, name })}
						title={label}
					/>
				),
			});
		}
	});

	return _routes;
};

const Component = ({ isStudent, onSetRoute, permissions, state, userDashboard }) => {
	const router = createBrowserRouter(
		[
			// 404
			{
				path: paths.notfound,
				element: <NotFoundPage />,
			},

			// auth
			{
				path: paths.auth.root,
				element: <AuthLayout />,
				children: [
					{
						path: paths.auth.root,
						element: (
							<Navigate
								to={paths.auth.login}
								replace
							/>
						),
					},
					{
						path: paths.auth.login,
						element: <LoginPage />,
					},
				],
			},
			// app
			{
				path: paths.root,
				element: <DefaultLayout routes={menuCreator(isStudent ? studentRoutes : defaultRoutes, permissions, userDashboard)} />,
				children: routeCreator(isStudent ? studentRoutes : defaultRoutes, permissions, onSetRoute),
			},
			{
				path: paths.auth.userInfo,
				element: <UserInform setRoute={() => setRoute({ parent, name })} />,
			},
			{
				path: "*",
				element: <NotFoundPage />,
			},
		],
		{
			basename: paths.basePath,
		}
	);

	return <RouterProvider router={router} />;
};

export default connect(
	(state) => ({
		isStudent: state.isStudent,
		permissions: state.permissions,
		userDashboard: state.user.dashboard,
	}),
	(dispatch) => ({
		onSetRoute: (data) => dispatch(setRoute(data)),
	})
)(Component);
