import { Layout, Result } from "antd";
import { Link } from "react-router-dom";

import resources from "scripts/resources";

const Component = () => {
	return (
		<Layout className="sp-result-layout">
			<Result
				status="404"
				title={resources.notfound}
				extra={
					<Link
						to="/"
						className="ant-btn ant-btn-primary"
					>
						{`${resources.page} ${resources.main}`}
					</Link>
				}
			/>
		</Layout>
	);
};

export default Component;
