import api from "../scripts/api";
const AddNotification = () => {
	const windowSize = window.innerWidth;
	// const check = document.evaluate("/html/body/div/div/div/header/span", document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
	if (windowSize > 1200) {
		const notfound = document.evaluate(
			"/html/body/div/div/aside/div/ul",
			document,
			null,
			XPathResult.FIRST_ORDERED_NODE_TYPE,
			null
		).singleNodeValue.lastChild;
		// document.$x("/html/body/div/div/aside/div/ul/li[7]");
		if (notfound) {
			notfound.style.visibility = "hidden";
		}
	}

	api.notifications().then(notification => {
		let notifications = Object.entries(notification);
		//console.log(notification);
		notifications.map((el, key) => {
			let element = document.getElementsByName(el[0])[0];

			if (element) {
				const badge = document.getElementById(el[0]);

				if (el[1]) {
					badge.style.visibility = "visible";
					if (
						el[0] == "classAssessments" ||
						el[0] == "eduDailyReports" ||
						el[0] == "generalExams" ||
						el[0] == "monthlyWorkbooks"
					) {
						const parentBadge = document.getElementById(`educational-parent`);
						if (parentBadge) {
							parentBadge.style.visibility = "visible";
						}
					}
					if (el[0] == "disRefers" || el[0] == "eduRefers") {
						const parentBadge = document.getElementById(`refers-parent`);
						if (parentBadge) {
							parentBadge.style.visibility = "visible";
						}
					}
					if (el[0] == "disReports") {
						const parentBadge = document.getElementById(`disciplinary-parent`);
						if (parentBadge) {
							parentBadge.style.visibility = "visible";
						}
					}
				} else {
					badge.style.visibility = "hidden";
					// if (el[0] == "classAssessments" || el[0] == "eduDailyReports" || el[0] == "generalExams" || el[0] == "monthlyWorkbooks") {
					// 	const parentBadge = document.getElementById(`educational-parent`);
					// 	if (parentBadge) {
					// 		parentBadge.style.visibility = "hidden";
					// 	}
					// }
					// if (el[0] == "disRefers" || el[0] == "eduRefers") {
					// 	const parentBadge = document.getElementById(`refers-parent`);
					// 	if (parentBadge) {
					// 		parentBadge.style.visibility = "hidden";
					// 	}
					// }
					// if (el[0] == "disReports") {
					// 	const parentBadge = document.getElementById(`disciplinary-parent`);
					// 	if (parentBadge) {
					// 		parentBadge.style.visibility = "hidden";
					// 	}
					// }
				}

				if (
					!notification.classAssessments &&
					!notification.eduDailyReports &&
					!notification.generalExams &&
					!notification.monthlyWorkbooks
				) {
					const parentBadge = document.getElementById(`educational-parent`);
					if (parentBadge) {
						parentBadge.style.visibility = "hidden";
					}
				}
				if (!notification.disRefers && !notification.eduRefers) {
					const parentBadge = document.getElementById(`refers-parent`);
					if (parentBadge) {
						parentBadge.style.visibility = "hidden";
					}
				}
				if (!notification.disReports) {
					const parentBadge = document.getElementById(`disciplinary-parent`);
					if (parentBadge) {
						parentBadge.style.visibility = "hidden";
					}
				}
			}
		});
	});
};

const CheckPermission = (permissions, permission) => {
	return permissions?.indexOf(permission) > -1;
};

const CopyTextToClipboard = text => {
	const textArea = document.createElement("textarea");
	let result;

	textArea.style.position = "fixed";
	textArea.style.top = 0;
	textArea.style.left = 0;
	textArea.style.padding = 0;
	textArea.style.zIndex = -1;
	textArea.style.width = "2em";
	textArea.style.height = "2em";
	textArea.style.border = "none";
	textArea.style.outline = "none";
	textArea.style.boxShadow = "none";
	textArea.style.background = "transparent";
	textArea.value = text.toLowerCase();
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		result = document.execCommand("copy");
	} catch (err) {
		result = false;
	}

	document.body.removeChild(textArea);

	return !!result;
};

const DelCookie = (name, domain) => {
	document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
};

const GetBase64 = file => {
	return new Promise((resolve, reject) => {
		const reader = new window.FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
};

const GetCookie = name => {
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");

	name = `${name}=`;

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];

		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}

		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}

	return "";
};

const GetUrlParams = () => {
	const pl = /\+/g;
	const search = /([^&=]+)=?([^&]*)/g;
	const decode = s => {
		return decodeURIComponent(s.replace(pl, " "));
	};
	const query = window.location.search.substring(1);
	const urlParams = {};
	let match;

	while ((match = search.exec(query)))
		urlParams[decode(match[1])] = decode(match[2]);

	Object.keys(urlParams).map(keyName => {
		if (!isNaN(urlParams[keyName]))
			urlParams[keyName] = parseFloat(urlParams[keyName]);
	});

	return urlParams;
};

const ImageResizer = (src, width, height) => {
	if (src) {
		const split = src.split(".");
		const len = split.length - 2;

		split.splice(len, 1, `${split[len]}-${width}-${height}`);

		return split.join(".");
	} else {
		return src;
	}
};

const IsArray = arr => {
	return Array.isArray(arr);
};

const IsObject = obj => {
	return obj === Object(obj) && !IsArray(obj) && typeof obj !== "function";
};

const KeysToCamel = obj => {
	if (IsObject(obj)) {
		const n = {};

		Object.keys(obj).forEach(k => {
			n[ToCamel(k)] = KeysToCamel(obj[k]);
		});

		return n;
	} else if (IsArray(obj)) {
		return obj.map(i => {
			return KeysToCamel(i);
		});
	}

	return obj;
};

const KeysToSnake = obj => {
	if (IsObject(obj)) {
		const n = {};

		Object.keys(obj).forEach(k => {
			n[ToSnake(k)] = KeysToSnake(obj[k]);
		});

		return n;
	} else if (IsArray(obj)) {
		return obj.map(i => {
			return KeysToSnake(i);
		});
	}

	return obj;
};

const minuteFormat = value => {
	const minutes = Math.trunc(value / 60);
	const seconds = value % 60;

	return `${minutes < 10 ? `0${minutes}` : minutes}:${
		seconds < 10 ? `0${seconds}` : seconds
	}`;
};

const ListToTree = (list = [], treeSelect, selectable) => {
	const roots = [];
	const map = {};

	list = list.map((o, i) => {
		map[o.id] = i;

		if (treeSelect)
			return {
				value: o.id,
				label: o.title,
				parentId: o.parentId,
				selectable: true,
			};
		else return { ...o, selectable, selectable: true };
	});

	list.map(o => {
		if (o.parentId) {
			const item = list[map[o.parentId]];

			if (!item.children) {
				item.children = [];
				item.selectable = !selectable;
			}

			item.children.push(o);
		} else {
			roots.push(o);
		}
	});

	return roots;
};

const MinuteFormat = value => {
	const minutes = Math.trunc(value / 60);
	const seconds = value % 60;

	return `${minutes < 10 ? `0${minutes}` : minutes}:${
		seconds < 10 ? `0${seconds}` : seconds
	}`;
};

const ObjectToURL = obj => {
	if (IsObject(obj)) {
		return Object.keys(obj)
			.filter(k => !!obj[k])
			.map(k => {
				return `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
			})
			.join("&");
	} else {
		return "";
	}
};

const enToFaDigits = obj => {
	const id = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

	if (IsObject(obj)) {
		const n = {};

		Object.keys(obj).forEach(k => (n[k] = enToFaDigits(obj[k])));

		return n;
	} else if (IsArray(obj)) {
		return obj.map(i => {
			return enToFaDigits(i);
		});
	} else {
		return obj?.toString().replace(/[0-9]/g, w => id[+w]);
	}
};

const PriceFormat = value => {
	return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const SetCookie = (name, domain, value, days) => {
	const d = new Date();

	d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);

	const expires = "expires=" + d.toUTCString();

	document.cookie = `${name}=${value}; ${expires}; domain=${domain}; path=/`;
};

const StringFormat = (value, args = []) => {
	return value.replace(/{(\d+)}/g, (match, number) => {
		return typeof args[number] !== "undefined" ? args[number] : match;
	});
};

const ToCamel = value => {
	return value.replace(/([-_][a-z])/gi, $1 => {
		return $1.toUpperCase().replace("-", "").replace("_", "");
	});
};

const ToSnake = value => {
	return value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

export {
	CheckPermission,
	CopyTextToClipboard,
	DelCookie,
	enToFaDigits,
	GetBase64,
	GetCookie,
	GetUrlParams,
	ImageResizer,
	KeysToCamel,
	KeysToSnake,
	ListToTree,
	MinuteFormat,
	ObjectToURL,
	PriceFormat,
	SetCookie,
	StringFormat,
	AddNotification,
};
