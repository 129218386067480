import { useState } from "react";
import { Progress, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { ENV, Types } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";

const Component = ({ onChange, value = [] }) => {
  const [progress, setProgress] = useState(0);

  const customRequest = (options) => {
    const { file } = options;

    if (Types.uploadImageFormats.indexOf(file.type) >= 0) {
      if (file.size < parseInt(process.env.REACT_APP_IMAGE_SIZE_LIMIT)) {
        const formData = new FormData();

        formData.append("file", file);

        api
          .file(formData, (progress) => setProgress(progress))
          .then((data) => {
            setProgress(0);
            onChange([...value, data]);
          })
          .catch((error) => {});
      } else {
        message.error(resources.uploadErros?.size);
      }
    } else {
      message.error(resources.uploadErros?.extension);
    }
  };

  const uploadOpt = { showUploadList: true, customRequest };
  return (
    <Upload
      {...uploadOpt}
      className="sp-upload-image"
      listType="picture"
      fileList={value.map((img) => ({
        uid: img.id,
        url: `${ENV.basePath}${img.uri}`,
        name: img.id,
        status: "done",
      }))}
      onRemove={(removingItem) => {}}
    >
      {progress > 0 ? (
        <>
          <span className="uploading">{resources.upload}</span>
          <Progress percent={progress} showInfo={false} />
        </>
      ) : !!value?.uri ? (
        <img
          src={`${ENV.basePath}${value[value.length - 1].uri}`}
          alt="avatar"
          className="image"
        />
      ) : (
        <PlusOutlined className="icon" />
      )}
    </Upload>
  );
};

Component.propTypes = {
  aspect: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default Component;
