import { useEffect, useRef, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Avatar, Button, Card, Carousel } from "antd";
import { LeftOutlined, RightOutlined, ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV } from "scripts/settings";
import api from "scripts/api";

import { register } from "swiper/element/bundle";
register();

const Component = ({ onFetch, students }) => {
	const initialState = { data: [], student: [] };
	const [state, setState] = useState(initialState);
	let [selectedSlideIndex, setSelectedSlideIndex] = useState();

	const { classId, data, selected, student } = state;
	const { pathname, search } = useLocation();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);
		// if (params.class) {
		// 	setState((prevState) => ({ ...prevState, selected: params.student }));

		// 	if (params.class !== classId) {
		// 		api.student
		// 			.related(params.class)
		// 			.then((student) => {
		// 				setState((prevState) => ({ ...prevState, student, classId: params.class }));
		// 				// if (onFetch)
		// 				onFetch(students.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
		// 			})
		// 			.catch(() => {});
		// 	}
		// } else {
		// 	setState((prevState) => ({ ...prevState, data: [], classId: null }));

		// 	if (onFetch) onFetch([]);
		// }
	};

	const handleSelect = (selected) => {
		const params = GetUrlParams(search);
		const _search = ObjectToURL({ ...params, student: selected.id });

		setState((prevState) => ({ ...prevState, selected }));

		setSelectedSlideIndex(selected.id);
		onFetch(selected);
		navigate(`${pathname}?${_search}`);
	};

	const componentDidUpdate = () => {
		fetchData();
	};

	useEffect(componentDidUpdate, [search]);

	const ref = useRef();

	return !!students.length ? (
		<div className="test">
			<swiper-container
				className="carousel-div"
				navigation={{
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				}}
				slides-per-view="4"
				speed="500"
				// loop="true"
				space-between="20"
				style={{
					"--swiper-navigation-color": "#96BD8F",
				}}
			>
				{students.map((el) => (
					<swiper-slide
						key={el.id}
						onClick={() => handleSelect(el)}
						style={{
							borderRadius: "10px",
							backgroundColor: selectedSlideIndex === el.id ? "#96BD8F" : "#f5f5f5",
							cursor: "pointer",
						}}
					>
						<div
							className="student-card-fixer"
							style={{ display: "flex", alignItems: "center" }}
						>
							<div
								className="ant-card"
								style={{
									cursor: "pointer",
									lineHeight: "36px",
									padding: "8px 8px 8px 16px",
									display: "table-cell",
								}}
							>
								<img
									src={`${ENV.basePath}${el.avatar}`}
									alt={`${el.firstName} ${el.lastName}`}
									width={50}
									height={50}
									style={{ borderRadius: "100%", display: "inline", verticalAlign: "middle" }}
								/>
								<div style={{ display: "inline", verticalAlign: "middle", margin: "auto", paddingRight: "10px" }}>{`${el.firstName} ${el.lastName}`}</div>
							</div>
						</div>
					</swiper-slide>
				))}
			</swiper-container>
		</div>
	) : null;
};

Component.propTypes = {
	onFetch: PropTypes.func,
};

export default Component;
