import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Input, FloatButton, Form, Modal, Row, Select, Table, Image, Tooltip, Checkbox, message } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { BookOutlined, CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, CloseOutlined, AppstoreOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/DisReportForm";
import StudentCarousel from "components/StudentCarousel";
import DisAddToClassForm from "modals/DisAddToClassForm";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {
		assessments: [],
		classes: [],
		data: [],
		regulations: [],
		students: [],
		EducationalYear: [],
	};
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => index + 1,
		},
		Table.EXPAND_COLUMN,
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		// {
		// 	title: resources.regulation,
		// 	dataIndex: "regulation",
		// 	className: "center-cell",
		// 	render: value => value?.title,
		// },
		{
			title: resources.duration,
			dataIndex: "duration",
			className: "center-cell",
			render: (value) => (value ? value : "-"),
		},
		{
			title: resources.assessment,
			dataIndex: "assessment",
			className: "center-cell",
			render: (value) => value?.title,
		},
		{
			title: `${resources.wihtout} ${resources.score}`,
			dataIndex: "effectOnBar",
			className: "center-cell",
			render: (value) => (!value ? <CheckOutlined /> : <CloseOutlined />),
		},
		// {
		// 	title: `${resources.Excused}`,
		// 	dataIndex: "isExcused",
		// 	className: "center-cell",
		// 	editable: true,
		// 	render: (value, record) => (
		// 		<>
		// 			{record.isExcused}
		// 			<Checkbox
		// 				title={resources.edit}
		// 				checked={value}
		// 				onClick={() => handleCheckbox(value, record)}
		// 			></Checkbox>
		// 		</>
		// 	),
		// },
	];
	const [state, setState] = useState(initialState);
	const {
		assessments,
		classes,
		data,
		loading,
		regulations,
		selected,
		students,
		selectStudent,
		selectedStudent,
		selectedStudentId,
		buttonclicked,
		EducationalYear,
	} = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);
		form.resetFields();
		form.setFieldsValue({
			...params,
			date: params.date ? dayjs(params.date) : undefined,
		});
		params.type = "Hurry";

		if (params.student) {
			setState((prevState) => ({
				...prevState,
				selectedStudentId: params.student,
				loading: true,
			}));

			api.disReport
				.limited(params)
				.then((data) => {
					setState((prevState) => ({ ...prevState, data, loading: false }));
				})
				.catch(() => {
					setState((prevState) => ({ ...prevState, loading: false }));
				});
		} else {
			setState((prevState) => ({ ...prevState, data: [] }));
		}
	};

	const handleStudentFilter = debounce(() => {
		form
			.validateFields()
			.then((values) => {
				const params = GetUrlParams(search);
				const _params = params.search === values.search ? params : {};

				const _search = ObjectToURL({ ..._params, ...values });

				navigate(`${pathname}?${_search}`);
			})
			.then(() => {
				const params = GetUrlParams();
				const classId = params.class;
				const searchText = params.search;
				if (params.search && params.class) {
					let searchText = params.search;

					api.student
						.related(params.class, searchText)
						.then((students) => {
							console.log(students);
							setState((prevState) => ({
								...prevState,
								students,
								classId: params.class,
								loading: false,
							}));
							// if (onFetch) onFetch(data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
						})
						.catch(() => {});
				} else if (params.class) {
					api.student
						.related(params.class)
						.then((students) => {
							console.log(students);
							setState((prevState) => ({
								...prevState,
								students,
								classId: params.class,
								loading: false,
							}));
							let selectStudent = students.map(({ id, firstName, lastName }) => ({
								value: id,
								label: `${firstName} ${lastName}`,
							}));
							setState((prevState) => ({ ...prevState, selectStudent }));

							// if (onFetch) onFetch(data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
						})
						.catch(() => {});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}

				if (params.search) {
					api.student
						.related(classId, searchText)
						.then((students) => {})
						.catch(() => {
							setState((prevState) => ({ ...prevState, loading: false }));
						});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}
			});
	}, 1000);

	const handleStudents = (onestudent) => {
		setState((prevState) => ({
			...prevState,
			selectedStudent: onestudent,
			students,
		}));
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`);
		}
	};

	const handleAddToClassModalModal = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			navigate(`${pathname}${search}#${Modals.ADDTOCLASS}`);
		}
	};

	const handleCheckbox = (value, record) => {
		let isExcused = value ? false : true;
		let recordId = record.id;

		api.disReport
			.makeExcuse(record.id)
			.then(() => {
				fetchData();
			})
			.catch(() => {});
	};

	const handleRefer = (id) => {
		modal.confirm({
			title: resources.referConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.disReport
					.refer(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.disReport
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const params = GetUrlParams(search);

			const _params = params.class === values.class ? params : {};

			const _search = ObjectToURL({ ..._params, ...values });

			navigate(`${pathname}?${_search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.class
			.related()
			.then((classes) => {
				setState((prevState) => ({ ...prevState, classes }));
			})
			.catch((e) => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});

		api.disRegulation
			.list({ paging: false })
			.then((data) => {
				setState((prevState) => ({
					...prevState,
					regulations: data.map(({ id, title }) => ({
						value: id,
						label: title,
					})),
				}));
			})
			.catch(() => {});

		api.assessment
			.list({ paging: false })
			.then((data) => {
				setState((prevState) => ({
					...prevState,
					assessments: data.map(({ id, title }) => ({
						value: id,
						label: title,
					})),
				}));
			})
			.catch(() => {});
		api.educationalYear
			.list()
			.then((EducationalYear) => {
				setState((prevState) => ({ ...prevState, EducationalYear }));
			})
			.catch(() => {});
	};

	const expandedRowRender = (record) => {
		const columns = [
			{
				title: resources.description,
				dataIndex: "description",
				key: "description",
			},
			{
				title: `${resources.description} ${resources.parent}`,
				dataIndex: "parentDescription",
				key: "parentDescription",
			},
			{
				title: ` ${resources.image} ${resources.description} ${resources.parent}`,
				key: "parentImage",
				render: (value, record) => (
					<>
						{record.parentImage != "-" ? (
							<Image
								src={`${ENV.basePath}${record.parentImage}`}
								// shape="square"
								width={40}
								height={40}
							/>
						) : (
							<>-</>
						)}
					</>
				),
			},
		];
		const data = [];
		data.push({
			description: record.description,
			parentDescription: record.parentDescription,
			parentImage: record.parentImage,
		});

		return (
			<Table
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
		);
	};

	const handleCloseProfile = () => {
		api.disReport.closeProfile(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({
				...prevState,
				buttonclicked: !buttonclicked,
				loading: false,
			}));
			handleStudentFilter();
		});
	};

	const handleOpenProfile = () => {
		api.disReport.openProfile(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({
				...prevState,
				buttonclicked: !buttonclicked,
				loading: false,
			}));
			handleStudentFilter();
		});
	};

	const handleAddWarning = () => {
		api.disReport.warning(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({
				...prevState,
				buttonclicked: !buttonclicked,
				loading: false,
			}));
			handleStudentFilter();
		});
	};

	const handleRemoveWarning = () => {
		api.disReport.removewarning(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({
				...prevState,
				buttonclicked: !buttonclicked,
				loading: false,
			}));
			handleStudentFilter();
		});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.refer || permissions.update || permissions.remove) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.refer && (
						<Tooltip title={`${resources.refer}${record.isReferred ? ` ${resources.done}` : ""}`}>
							<Button
								type="link"
								icon={<BookOutlined />}
								onClick={record.isReferred ? null : () => handleRefer(value)}
								disabled={record.isReferred}
							/>
						</Tooltip>
					)}

					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="class">
							<Select
								placeholder={resources.class}
								options={classes}
								onChange={handleStudentFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="EducationalYear">
							<Select
								placeholder={`${resources.academicYear}`}
								options={EducationalYear}
								onChange={handleStudentFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="search">
							<Input
								suffix={<SearchOutlined />}
								onChange={handleStudentFilter}
								placeholder={`${resources.search} ${resources.student}`}
								allowClear
							/>
						</Form.Item>
					</Col>

					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="date">
							<DatePickerJalali
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			{permissions.suspend && (
				<Row
					gutter={24}
					// className="row-top-table"
				>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
						className="row-top-table"
					>
						<Col>
							{selectedStudent && !selectedStudent.disSuspend && (
								<Button
									type="primary"
									className="open-button"
									onClick={handleOpenProfile}
									style={{ display: "block" }}
								>
									{`${resources.open} ${resources.profile}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && selectedStudent.disSuspend && (
								<Button
									className="close-button"
									onClick={handleCloseProfile}
									style={{ display: "block" }}
								>
									{`${resources.close} ${resources.profile}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && selectedStudent.disWarning && (
								<Button
									className="warning-button"
									onClick={handleAddWarning}
									style={{ display: "block" }}
								>
									{`${resources.warning} ${resources.workbook}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && !selectedStudent.disWarning && (
								<Button
									className="warning-button"
									onClick={handleRemoveWarning}
									style={{ display: "block" }}
								>
									{`${resources.delete} ${resources.warning} ${resources.workbook}`}
								</Button>
							)}
						</Col>
					</Col>
				</Row>
			)}

			<StudentCarousel
				students={students}
				onFetch={handleStudents}
			/>
			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				pagination={false}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
				expandable={{
					expandedRowRender: (record) => expandedRowRender(record),
				}}
				// expandable={{ expandedRowRender: (record) => `${resources.description}: ${record.description}` }}
			/>

			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={resources.add}
					icon={<PlusOutlined />}
					onClick={() => handleForm()}
				/>
			)}

			{/* {permissions.create && (
				<FloatButton
					type="primary"
					tooltip={`${resources.add} ${resources.toall}`}
					style={{ left: 90 }}
					icon={
						<>
							<AppstoreOutlined />
						</>
					}
					onClick={() => handleAddToClassModalModal()}
				/>
			)} */}

			{(permissions.create || permissions.update) && (
				<FormModal
					type={"Hurry"}
					data={selected}
					onUpdate={handleForm}
					options={{ assessments, regulations, selectStudent }}
				/>
			)}

			{/* {permissions.create && (
				<DisAddToClassForm
					type={"Hurry"}
					data={classes}
					onUpdate={handleAddToClassModalModal}
					options={{ classes, assessments, regulations, selectStudent }}
				/>
			)} */}

			{contextHolder}
		</>
	);
};

export default Component;
