import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, FloatButton, Form, Modal, Row, Table, Select, Tooltip } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/MeetingForm";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = { data: [], pagination: {}, classes: [], teachers: [] };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
		},
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: resources.start,
			dataIndex: "start",
			className: "center-cell",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{time}</span>
					</>
				);
			},
		},
		{
			title: resources.end,
			dataIndex: "end",
			className: "center-cell",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{time}</span>
					</>
				);
			},
		},
		{
			title: `${resources.firstName} ${resources.teacher}`,
			dataIndex: "teacher",
			className: "center-cell",
			render: (value) => {
				return (
					<>
						<span className="name">{`${value.firstName} ${value.lastName}`}</span>
					</>
				);
			},
		},
		{
			title: resources.student,
			dataIndex: "student",
			className: "center-cell",
			render: (value) => {
				return <>{value ? <span className="name">{`${value.firstName} ${value.lastName}`}</span> : <span>-</span>}</>;
			},
		},
		{
			title: resources.parent,
			dataIndex: "parent",
			className: "center-cell",
		},
	];
	const [state, setState] = useState(initialState);
	const { data, loading, pagination, selected, classes, teachers } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue({ ...params, date: params.date ? dayjs(params.date) : undefined });
		setState((prevState) => ({ ...prevState, loading: true }));

		api.meeting
			.list({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then(({ list: data, pagination }) => {
				setState((prevState) => ({ ...prevState, data, pagination, loading: false }));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`);
		}
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.meeting
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleTacherFilter = (selectedClass) => {
		let classId = selectedClass;
		if (classId) {
			api.meeting
				.teacherList(classId)
				.then((data) => {
					setState((prevState) => ({ ...prevState, teachers: data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) }));
				})
				.catch(() => {});
		}
	};
	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const search = ObjectToURL({ page: 1, ...values });

			navigate(`${pathname}?${search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();
		api.class
			.related()
			.then((classes) => {
				setState((prevState) => ({ ...prevState, classes }));
			})
			.catch(() => {});

		// api.meeting
		// 	.teacherList()
		// 	.then((classes) => {
		// 		setState((prevState) => ({ ...prevState, classes }));
		// 	})
		// 	.catch(() => {});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.remove || permissions.update) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="class">
							<Select
								placeholder={resources.class}
								options={classes}
								onChange={handleTacherFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="teacher">
							<Select
								placeholder={resources.teacher}
								options={teachers}
								onChange={handleFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="date">
							<DatePickerJalali
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={resources.add}
					icon={<PlusOutlined />}
					onClick={() => handleForm()}
				/>
			)}

			{(permissions.create || permissions.update) && (
				<FormModal
					data={selected}
					onUpdate={handleForm}
					options={{ teachers, classes }}
				/>
			)}

			{contextHolder}
		</>
	);
};

export default Component;
