export default {
	basePath: "/",
	dashboard: "/dashboard",
	landing: "/landing",
	notfound: "/404",
	password: "/password",
	root: "/",
	auth: {
		root: "/auth",
		login: "/auth/login",
		forgot: "/auth/forgot",
		userInfo: "/auth/userinfo",
	},
	configs: {
		assessments: "/configs/assessments",
		classes: "/configs/classes",
		roles: "/configs/roles",
		students: "/configs/students",
		users: "/configs/users",
		manageCalendar: "/configs/calendar",
	},
	disciplinary: {
		generalReports: "/disciplinary/general-reports",
		regulations: "/disciplinary/regulations",
		reports: "/disciplinary/reports",
		hurryReports: "/disciplinary/hurry-reports",
		absenceReports: "/disciplinary/absence-reports",
		latencyReports: "/disciplinary/latency-reports",
	},
	documents: {
		documents: "/Identity-documents",
	},
	pixels: {
		pixels: "/pixel",
	},
	library: {
		library: "/library",
	},
	educational: {
		classAssessments: "/educational/class-assessments",
		dailyReports: "/educational/daily-reports",
		dailyActivity: "/educational/daily-activity",
		generalExams: "/educational/general-exams",
		generalReports: "/educational/general-reports",
		monthlyWorkbooks: "/educational/monthly-workbooks",
		regulations: "/educational/regulations",
	},
	financial: {
		schoolTuition: "/financial/school-tuition",
		busFee: "/financial/bus-fee",
	},
	meeting: {
		meeting: "/meeting/parent-meeting",
		managment: "/meeting/managment-meeting",
	},
	onlineLearning: {
		videos: "/online-learning/videos",
	},
	parentDescription: {
		eduDescription: "parent-description/educational",
		disDescription: "parent-description/disciplinary",
	},
	refer: {
		disciplinary: "/refers/disciplinary",
		educational: "/refers/educational",
	},
	edustudent: {
		dailyReports: "/edustudent/daily-reports",
		dailyActivity: "/edustudent/daily-activity",
		classAssessments: "/edustudent/class-assessments",
		generalReports: "/edustudent/general-reports",
		hurryReports: "/student/hurry-reports",
		absenceReports: "/student/absence-reports",
		latencyReports: "/student/latency-reports",
		monthlyGradeSheet: "/edustudent/grade-sheet",
		generalExams: "/edustudent/general-exams",
		disGeneralReports: "/disustudent/general-reports",
		studentUserInfo: "/student/userinfo",
		meetTeacher: "/student/meetTeacher",
		meetManagment: "/student/meet-managment",
		onlineLearning: "/student/online-Learning",
		financial: "/student/financial",
		documents: "/student/document",
		library: "/student/library",
		pixel: "/student/pixel",
		disReports: "/student/reports",
	},
};
