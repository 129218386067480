import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Modal, Row, Spin, Col, Image } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment-jalaali";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";
import { ENV } from "scripts/settings";

const Component = ({ permissions, setRoute }) => {
  const initialState = { data: [], pagination: {} };

  const [state, setState] = useState(initialState);
  const { data, loading, pagination, selected } = state;
  const { pathname, search } = useLocation();
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();

  const BackCard = {
    backgroundColor: "#d5e5d2",
    padding: "30px",
    marginRight: "10%",
    marginLeft: "10%",
    borderRadius: "10px",
  };
  const RowStyle = {
    borderRadius: "10px",
  };

  const imageStyle = {
    width:'100%',
    borderRadius :'10px',
    display:'flex',
    justifyContent :"center",
    marginTop:'10px'

  }
  const fetchData = () => {
    AddNotification();
    const params = GetUrlParams(search);

    setState((prevState) => ({ ...prevState, loading: true }));

    api.stPanel
      .financial({ pageSize: process.env.REACT_APP_PAGE_SIZE })
      .then((data) => {
        setState((prevState) => ({
          ...prevState,
          data,
          pagination,
          loading: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  };

  const ReturnData = () => {
    if (data == [] || !data) {
      return (
        <div>
          <p>اطلاعاتی وجود ندارد!</p>
        </div>
      );
    } else {
      const [date, time] = moment(data.timeout)
        .format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
        .split(" ");
    }
    return (
      <Row>
        {data.map((el) => {
          return (
            <div style={BackCard}>
              {el.type == "Bus" ? (
                <h2>{`${resources.tuition} ${resources.bus}`}</h2>
              ) : (
                <h2>{`${resources.tuition} ${resources.school}`}</h2>
              )}
              <Row style={RowStyle} gutter={48}>
                <Col className="financial-Page-row">
                  <p>مهلت پرداخت</p>
                </Col>
                <Col className="financial-Page-row">
                  {el.timeout ? (
                    <p>
                      {
                        moment(el.timeout)
                          .format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
                          .split(" ")[0]
                      }
                    </p>
                  ) : (
                    <p>-</p>
                  )}
                </Col>
              </Row>
              <Row gutter={48}>
                <Col className="financial-Page-row">
                  <p>مجموع پرداختی</p>
                </Col>
                <Col className="financial-Page-row">
                  {/* <p>{el.credit.toLocaleString()}</p> */}
                </Col>
              </Row>

              <Row gutter={48}>
                <Col
                  className="financial-Page-row"
                  span={8}
                  xs={24}
                  sm={24}
                  md={8}
                >
                  <p>مانده</p>
                </Col>
                <Col
                  className="financial-Page-row"
                  span={16}
                  xs={24}
                  sm={24}
                  md={16}
                >
                  {/* <p>{el.remain.toLocaleString()}</p> */}
                </Col>
              </Row>
              <Row gutter={48}>
                <Col className="financial-Page-row">
                  <p>{`${resources.image}`}</p>
                </Col>
                <Col className="financial-Page-row">
                  {el.uri ? (
                    <Image
                      src={`${ENV.basePath}${el.uri}`}
                      // shape="square"
                      width={40}
                      height={40}
                    ></Image>
                  ) : (
                    <p>-</p>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
      </Row>
    );
  };
  const componentDidUpdate = () => {
    fetchData();
  };

  const componentDidMount = () => {
    setRoute();
    fetchData();
  };

  useEffect(() => {
    api.stPanel
      .financial({ pageSize: process.env.REACT_APP_PAGE_SIZE })
      .then((data) => {
        setState((prevState) => ({
          ...prevState,
          data,
          pagination,
          loading: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, []);
  useEffect(componentDidUpdate, [search]);
  useEffect(componentDidMount, []);

  const imgUri = data[0]?.uri;

  return (
    <div>
      {/* {loading ? <Spin>Loading...</Spin> : <ReturnData />} */}

      {imgUri?.map((item) => {
        return (
          <div style={imageStyle}>
            <Image src={`${ENV.basePath}${item}`} width={`100%`}/>
          </div>
        );
      })}
    </div>
  );
  // return (
  // 	<>
  // 		<ReturnData data={data} />
  // 		{/* <Tabs
  // 			className="sp-tabs"
  // 			activeKey={2}
  // 			items={[
  // 				{ key: 1, label: <Link to={paths.refer.educational}>{`${resources.education}${resources.y}`}</Link> },
  // 				{ key: 2, label: <Link to={paths.refer.disciplinary}>{`${resources.discipline}${resources.y}`}</Link> },
  // 			]}
  // 		/> */}
  // 		{contextHolder}
  // 	</>
  // );
};

export default Component;
