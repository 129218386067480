import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, FloatButton, Form, Input, Modal, Row, Table, Tabs, Tooltip, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/DisRegulationForm";

const Component = ({ permissions, setRoute }) => {
	const initialState = { data: [], pagination: {} };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
		},
		Table.EXPAND_COLUMN,
		{
			title: resources.title,
			dataIndex: "title",
		},
	];
	const [state, setState] = useState(initialState);
	const { data, loading, pagination, selected } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue(params);

		setState((prevState) => ({ ...prevState, loading: true }));

		api.disRegulation
			.list({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then(({ list: data, pagination }) => {
				setState((prevState) => ({ ...prevState, data, pagination, loading: false }));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
		};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`);
		}
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.disRegulation
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const search = ObjectToURL({ page: 1, ...values });

			navigate(`${pathname}?${search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.remove || permissions.update) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			{/* <Tabs
				className="sp-tabs"
				activeKey={1}
				items={[
					{ key: 1, label: <Link to={paths.disciplinary.regulations}>{`${resources.regulation} ${resources.discipline}${resources.y}`}</Link> },
					{ key: 2, label: <Link to={paths.disciplinary.reports}>{`${resources.items} ${resources.discipline}${resources.y}`}</Link> },
					{ key: 3, label: <Link to={paths.disciplinary.generalReports}>{`${resources.report} ${resources.total}`}</Link> },
				]}
			/> */}

			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="search">
							<Input
								suffix={<SearchOutlined />}
								onChange={handleFilter}
								placeholder={resources.search}
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
				expandable={{ expandedRowRender: (record) => `${resources.description}: ${record.description}` }}
			/>

			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={resources.add}
					icon={<PlusOutlined />}
					onClick={() => handleForm()}
				/>
			)}

			{(permissions.create || permissions.update) && (
				<FormModal
					data={selected}
					onUpdate={handleForm}
				/>
			)}

			{contextHolder}
		</>
	);
};

export default Component;
