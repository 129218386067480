import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer, Form, Modal } from "antd";
import PropTypes from "prop-types";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

import UploadImage from "components/UploadImage";

const Component = ({ data = {}, pdfUri, pdfState, onClose }) => {
	const initialState = { visible: pdfState };
	const [state, setState] = useState(initialState);
	const { submitting, visible } = state;
	const { hash } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const handleCancel = () => {
		setState((prevState) => ({ ...prevState, visible: false }));
		onClose(); // Call the onClose prop to notify the parent component that the modal is closed
	};

	const componentDidUpdate = () => {
		setState((prevState) => ({ ...prevState, visible: pdfState }));
	};

	const componentDidMount = () => {
		setState((prevState) => ({ ...prevState, visible: true }));
	};

	useEffect(componentDidUpdate, [pdfState]);
	useEffect(componentDidMount, []);

	return visible ? (
		<Modal
			centered
			open={pdfState}
			onOk={handleCancel}
			onCancel={handleCancel}
			width={1000}
		>
			<object
				data={pdfUri}
				width={900}
				height={500}
				type="application/pdf"
			/>
		</Modal>
	) : (
		<></>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
