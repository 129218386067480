import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Input, FloatButton, Form, Modal, Row, Select, Table, Image, Tooltip, message, Alert } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { BookOutlined, CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, CloseOutlined, AppstoreOutlined, SearchOutlined } from "@ant-design/icons";

import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/PixelForm";
import StudentCarousel from "components/StudentCarousel";
import DisAddToClassForm from "modals/DisAddToClassForm";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {
		assessments: [],
		classes: [],
		data: [],
		regulations: [],
		students: [],
	};
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => index + 1,
		},
		{
			title: resources.subject,
			dataIndex: "title",
			className: "center-cell desc-cell ltr-cell",
			width: "150px",
			render: (value) => {
				return (
					<>
						<span className="name">{value}</span>
					</>
				);
			},
		},
		{
			title: resources.student,
			dataIndex: "students",
			className: "center-cell desc-cell ltr-cell",
			width: "150px",
			render: (value) => {
				return value ? (
					value.map((item, i) => (
						<span
							className="name"
							key={i}
						>
							{item}
						</span>
					))
				) : (
					<span>-</span>
				);
			},
		},
		{
			title: resources.description,
			dataIndex: "description",
			className: "center-cell desc-cell ltr-cell",
			width: "150",
			render: (value) => {
				return (
					<>
						<span className="name">{value}</span>
					</>
				);
			},
		},
		{
			title: resources.borrowTime,
			dataIndex: "borrowTime",
			className: "center-cell desc-cell ltr-cell",
			width: "150px",
			render: (value) => {
        const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return value ? (
					<span
						className="name"
					>
						{date}
					</span>
				) : (
					<span>-</span>
				);
			},
		},
		{
			title: resources.unBorrowTime,
			dataIndex: "unBorrowTime",
			className: "center-cell desc-cell ltr-cell",
			width: "150px",
			render: (value) => {
        const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return value ? (
					<span
						className="name"
					>
						{date}
					</span>
				) : (
					<span>-</span>
				);
			},
		},
		{
			title: ` ${resources.image}`,
			key: "fileUrl",
			width: "150",

			render: (value, record) => (
				<>
					{record.fileUrl ? (
						<Image
							src={`${ENV.basePath}${record.fileUrl}`}
							// shape="square"
							width={40}
							height={40}
						/>
					) : (
						<span>-</span>
					)}
				</>
			),
		},

		{
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					<Tooltip title={resources.edit}>
						<Button
							type="link"
							icon={<EditOutlined />}
							onClick={() => handleForm(record)}
						/>
					</Tooltip>

					<Tooltip title={resources.delete}>
						<Button
							type="link"
							icon={<DeleteOutlined />}
							onClick={() => handleDelete(value)}
						/>
					</Tooltip>
				</>
			),
		},
	];
	const [state, setState] = useState(initialState);
	const { assessments, classes, data, loading, regulations, selected, students, selectStudent, selectedStudent, selectedStudentId, buttonclicked } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue({
			...params,
			date: params.date ? dayjs(params.date) : undefined,
		});

		setState((prevState) => ({
			...prevState,
			selectedStudentId: params.student,
			loading: true,
		}));

		api.pixel
			.list(params)
			.then((data) => {
				console.log("SALAM", data);

				setState((prevState) => ({
					...prevState,
					data: data,
					loading: false,
				}));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};
	const handleStudentFilter = debounce(() => {
		form
			.validateFields()
			.then((values) => {
				const params = GetUrlParams(search);
				const _params = params.search === values.search ? params : {};

				const _search = ObjectToURL({ ..._params, ...values });

				navigate(`${pathname}?${_search}`);
			})
			.then(() => {
				const params = GetUrlParams();
				const classId = params.class;
				const searchText = params.search;
				if (params.search && params.class) {
					let searchText = params.search;

					api.student
						.related(params.class, searchText)
						.then((students) => {
							setState((prevState) => ({
								...prevState,
								students,
								classId: params.class,
								loading: false,
							}));
							// if (onFetch) onFetch(data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
						})
						.catch(() => {});
				} else if (params.class) {
					api.student
						.related(params.class)
						.then((students) => {
							setState((prevState) => ({
								...prevState,
								students,
								classId: params.class,
								loading: false,
							}));
							let selectStudent = students.map(({ id, firstName, lastName }) => ({
								value: id,
								label: `${firstName} ${lastName}`,
							}));
							setState((prevState) => ({ ...prevState, selectStudent }));

							// if (onFetch) onFetch(data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
						})
						.catch(() => {});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}

				if (params.search) {
					api.student
						.related(classId, searchText)
						.then((students) => {})
						.catch(() => {
							setState((prevState) => ({ ...prevState, loading: false }));
						});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}
			});
	}, 1000);

	const handleStudents = (onestudent) => {
		setState((prevState) => ({
			...prevState,
			selectedStudent: onestudent,
			students,
		}));
	};

	const handleForm = (selected, reload) => {
		console.log("salam chetor", selected);

		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`);
		}
	};

	const handleRefer = (id) => {
		modal.confirm({
			title: resources.referConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.disReport
					.refer(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.pixel
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const params = GetUrlParams(search);
			if (values.Start) {
				let months = values.Start.$M + 1;
				let start = `${values.Start.$y}-${months}-${values.Start.$D}`;
				values.Start = start;
			}
			if (values.End) {
				let months = values.End.$M + 1;
				let end = `${values.End.$y}-${months}-${values.End.$D}`;
				values.End = end;
			}
			const _params = params.class === values.class ? params : {};

			const _search = ObjectToURL({ ..._params, ...values });

			navigate(`${pathname}?${_search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.class
			.related()
			.then((classes) => {
				setState((prevState) => ({ ...prevState, classes }));
			})
			.catch((e) => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});

		api.disRegulation
			.list({ paging: false })
			.then((data) => {
				setState((prevState) => ({
					...prevState,
					regulations: data?.map(({ id, title }) => ({
						value: id,
						label: title,
					})),
				}));
			})
			.catch(() => {});

		api.assessment
			.list({ paging: false })
			.then((data) => {
				setState((prevState) => ({
					...prevState,
					assessments: data?.map(({ id, title }) => ({
						value: id,
						label: title,
					})),
				}));
			})
			.catch(() => {});
	};

	const handleCloseProfile = () => {
		api.documents.closeProfile(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({
				...prevState,
				buttonclicked: !buttonclicked,
				loading: false,
			}));
			handleStudentFilter();
		});
	};

	const handleOpenProfile = () => {
		api.documents.openProfile(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({
				...prevState,
				buttonclicked: !buttonclicked,
				loading: false,
			}));
			handleStudentFilter();
		});
	};

	const handleAddWarning = () => {
		api.documents.warning(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({
				...prevState,
				buttonclicked: !buttonclicked,
				loading: false,
			}));
			handleStudentFilter();
		});
	};

	const handleRemoveWarning = () => {
		api.documents.removewarning(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({
				...prevState,
				buttonclicked: !buttonclicked,
				loading: false,
			}));
			handleStudentFilter();
		});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.refer || permissions.update || permissions.remove) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.refer && (
						<Tooltip title={`${resources.refer}${record.isReferred ? ` ${resources.done}` : ""}`}>
							<Button
								type="link"
								icon={<BookOutlined />}
								onClick={record.isReferred ? null : () => handleRefer(value)}
								disabled={record.isReferred}
							/>
						</Tooltip>
					)}
					{/* 
					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)} */}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="class">
							<Select
								placeholder={resources.class}
								options={classes}
								onChange={handleStudentFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="Start">
							<DatePickerJalali
								onChange={handleFilter}
								style={{ display: "block" }}
								placeholder={`${resources.date} ${resources.start}`}
							/>
						</Form.Item>
					</Col>{" "}
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="End">
							<DatePickerJalali
								onChange={handleFilter}
								style={{ display: "block" }}
								placeholder={`${resources.date} ${resources.end}`}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			{permissions.suspend && (
				<Row
					gutter={24}
					// className="row-top-table"
				>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
						className="row-top-table"
					>
						<Col>
							{selectedStudent && !selectedStudent.docSuspend && (
								<Button
									type="primary"
									className="open-button"
									onClick={handleOpenProfile}
									style={{ display: "block" }}
								>
									{`${resources.open} ${resources.profile}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && selectedStudent.docSuspend && (
								<Button
									className="close-button"
									onClick={handleCloseProfile}
									style={{ display: "block" }}
								>
									{`${resources.close} ${resources.profile}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && selectedStudent.docWarning && (
								<Button
									className="warning-button"
									onClick={handleAddWarning}
									style={{ display: "block" }}
								>
									{`${resources.warning} ${resources.documents}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && !selectedStudent.docWarning && (
								<Button
									className="warning-button"
									onClick={handleRemoveWarning}
									style={{ display: "block" }}
								>
									{`${resources.delete} ${resources.warning} ${resources.documents}`}
								</Button>
							)}
						</Col>
					</Col>
				</Row>
			)}

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				pagination={false}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			<FloatButton
				type="primary"
				tooltip={resources.add}
				icon={<PlusOutlined />}
				onClick={() => handleForm()}
			/>

			<FormModal
				data={selected}
				onUpdate={handleForm}
				options={{ assessments, regulations, selectStudent, classes }}
				fetchData={fetchData}
			/>

			{contextHolder}
		</>
	);
};

export default Component;
