import { useState, useEffect } from "react";
import { Progress, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { ENV, Types } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";

const Component = ({ onChange, value }) => {
	const initialState = { uploadedFile: [] };

	const [progress, setProgress] = useState(0);
	const [fileList, setFileList] = useState([]);
	const [state, setState] = useState(initialState);
	const [doneCount, setDoneCount] = useState(0);

	const { uploadedFile } = state;

	useEffect(() => {
		if (doneCount === fileList.length) {
			// Call the onChange function with the uploadedFile state
			onChange(uploadedFile);
		}
	}, [doneCount, fileList, uploadedFile, onChange]);

	const onChang = ({ file, fileList }) => {
		let newFileList = [...fileList];

		newFileList = newFileList.slice(-2);

		newFileList = newFileList.map((file) => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});
		setFileList(newFileList);

		if (file.status === "done") {
			setDoneCount((prevCount) => prevCount + 1);
		} else if (file.status === "removed") {
			setDoneCount((prevCount) => prevCount - 1);
		}
	};

	const customRequest = async (options) => {
		const { file, onSuccess } = options;
		console.log("options options", file);

		if (Types.uploadImageFormats.indexOf(file.type) >= 0) {
			if (file.size < parseInt(process.env.REACT_APP_IMAGE_SIZE_LIMIT)) {
				const formData = new FormData();

				formData.append("file", file);

				api
					.file(formData, (progress) => setProgress(progress))
					.then((data) => {
						setState((prevState) => ({ uploadedFile: [...prevState.uploadedFile, data] }));

						setProgress(0);
						onSuccess(data, file);
					})
					.catch((error) => {});
			} else {
				message.error(resources.uploadErros?.size);
			}
		} else {
			message.error(resources.uploadErros?.extension);
		}
		console.log("uploadedFile uploadedFile uploadedFile", uploadedFile);
	};

	const uploadOpt = {
		showUploadList: true,
		multiple: true,
		onChange: onChang,
		customRequest,
	};

	return (
		<Upload
			{...uploadOpt}
			fileList={fileList}
			className="sp-upload-image"
		>
			{progress > 0 ? (
				<>
					<span className="uploading">{resources.upload}</span>
					<Progress
						percent={progress}
						showInfo={false}
					/>
				</>
			) : !!value?.uri ? (
				<img
					src={`${ENV.basePath}${value.uri}`}
					alt="avatar"
					className="image"
				/>
			) : (
				<PlusOutlined className="icon" />
			)}
		</Upload>
	);
};

Component.propTypes = {
	aspect: PropTypes.number,
	onChange: PropTypes.func,
	value: PropTypes.any,
};

export default Component;
