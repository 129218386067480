import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  FloatButton,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Checkbox,
  Tooltip,
} from "antd";
import {
  DatePicker as DatePickerJalali,
  Calendar as CalendarJalali,
  useJalaliLocaleListener,
} from "antd-jalali";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/ClassAssessmentForm";
import StudentCarousel from "components/StudentCarousel";

const Component = ({ permissions, setRoute }) => {
  useJalaliLocaleListener();
  dayjs.calendar("jalali");

  const initialState = {
    assessments: [],
    classes: [],
    data: [],
    lessons: [],
    students: [],
  };
  const tableColumns = [
    {
      title: resources.row,
      dataIndex: "index",
      className: "center-cell",
      width: "60px",
      render: (value, record, index) => index + 1,
    },
    Table.EXPAND_COLUMN,
    {
      title: resources.date,
      dataIndex: "date",
      className: "center-cell desc-cell ltr-cell",
      width: "100px",
      render: (value) => {
        const [date, time] = moment(value)
          .format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
          .split(" ");

        return (
          <>
            <span className="name">{date}</span>
          </>
        );
      },
    },
    {
      title: resources.student,
      dataIndex: "student",
      className: "center-cell",
      render: (value) => `${value.firstName} ${value.lastName}`,
    },
    {
      title: resources.lesson,
      dataIndex: "lesson",
      className: "center-cell",
      render: (value) => (value ? value?.label : "-"),
    },
    {
      title: `${resources.seen} ${resources.parent}`,
      dataIndex: "showToParents",
      className: "center-cell",
      editable: true,
      render: (value, record) => (
        <>
          {record.showToParents}
          <Checkbox
            title={resources.edit}
            checked={value}
            onClick={() => handleCheckbox(value, record)}
          ></Checkbox>
        </>
      ),
    },
    {
      title: `${resources.subject} ${resources.lesson}`,
      dataIndex: "topic",
    },
    {
      title: resources.assessment,
      dataIndex: "assessment",
      className: "center-cell",
      render: (value) => (value ? value?.title : "-"),
    },
  ];
  const [state, setState] = useState(initialState);
  const {
    assessments,
    classes,
    data,
    lessons,
    loading,
    selected,
    students,
    selectStudent,
    selectedStudent,
  } = state;
  const { pathname, search } = useLocation();
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const fetchData = () => {
    AddNotification();
    const params = GetUrlParams(search);

    form.resetFields();
    form.setFieldsValue({
      ...params,
      date: params.date ? dayjs(params.date) : undefined,
    });

    if (params.student) {
      setState((prevState) => ({ ...prevState, loading: true }));
      let studentId = params.student;
      // //console.log(" line 83 ", params.student);
      api.classAssessment
        .ByStudent(params)
        .then((data) => {
          setState((prevState) => ({ ...prevState, data, loading: false }));
        })
        .catch(() => {
          setState((prevState) => ({ ...prevState, loading: false }));
        });
    } else {
      setState((prevState) => ({ ...prevState, data: [] }));
    }
  };
  const handleStudentFilter = debounce(() => {
    form
      .validateFields()
      .then((values) => {
        const params = GetUrlParams(search);
        const _params = params.search === values.search ? params : {};

        const _search = ObjectToURL({ ..._params, ...values });

        navigate(`${pathname}?${_search}`);
      })
      .then(() => {
        const params = GetUrlParams();
        const classId = params.class;
        const searchText = params.search;
        if (params.search && params.class) {
          let searchText = params.search;

          api.student
            .related(params.class, searchText)
            .then((students) => {
              setState((prevState) => ({
                ...prevState,
                students,
                classId: params.class,
                loading: false,
              }));
              if (onFetch)
                onFetch(
                  data.map(({ id, firstName, lastName }) => ({
                    value: id,
                    label: `${firstName} ${lastName}`,
                  }))
                );
            })
            .catch(() => {});
        } else if (params.class) {
          api.student
            .related(params.class)
            .then((students) => {
              setState((prevState) => ({
                ...prevState,
                students,
                classId: params.class,
                loading: false,
              }));
              let selectStudent = students.map(
                ({ id, firstName, lastName }) => ({
                  value: id,
                  label: `${firstName} ${lastName}`,
                })
              );
              setState((prevState) => ({ ...prevState, selectStudent }));
            })
            .catch(() => {});
        } else {
          setState((prevState) => ({ ...prevState, data: [] }));
        }
      });
  }, 1000);

  const handleStudents = (selectedStudent) => {
    setState((prevState) => ({ ...prevState, selectedStudent }));
  };

  const handleView = (selected) => {};

  const handleForm = (selected, reload) => {
    if (reload) {
      fetchData();
    } else {
      setState((prevState) => ({ ...prevState, selected }));

      navigate(
        `${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`
      );
    }
  };

  const handleDelete = (id) => {
    modal.confirm({
      title: resources.deleteConfirm,
      okText: resources.yes,
      okType: "danger",
      cancelText: resources.no,
      onOk: () => {
        setState((prevState) => ({ ...prevState, loading: true }));

        api.classAssessment
          .delete(id)
          .then(() => {
            fetchData();
          })
          .catch(() => {
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      },
    });
  };

  // const handleTable = ({ current }) => {
  // 	const params = GetUrlParams(search);
  // 	const query = ObjectToURL({ ...params, page: current });

  // 	navigate(`${pathname}?${query}`);
  // };

  const handleFilter = debounce(() => {
    form.validateFields().then((values) => {
      const params = GetUrlParams(search);

      const _params = params.class === values.class ? params : {};

      const _search = ObjectToURL({ page: 1, ..._params, ...values });

      navigate(`${pathname}?${_search}`);
    });
  }, 1000);

  const componentDidUpdate = () => {
    fetchData();
  };

  const componentDidMount = () => {
    setRoute();

    api.class
      .related()
      .then((classes) => {
        setState((prevState) => ({ ...prevState, classes }));
      })
      .catch(() => {});

    api
      .lessons()
      .then((lessons) => {
        setState((prevState) => ({ ...prevState, lessons }));
      })
      .catch(() => {});

    api.assessment
      .list({ paging: false })
      .then((data) => {
        setState((prevState) => ({
          ...prevState,
          assessments: data.map(({ id, title }) => ({
            value: id,
            label: title,
          })),
        }));
      })
      .catch(() => {});
  };

  const handleCheckbox = (value, record) => {
    let showToParents = value ? false : true;
    let recordId = record.id;

    api.classAssessment
      .ChangeShowToParents(recordId)
      .then(() => {
        fetchData();
      })
      .catch(() => {});
  };

  useEffect(componentDidUpdate, [search]);
  useEffect(componentDidMount, []);

  if (permissions.remove || permissions.update) {
    tableColumns.push({
      title: resources.action,
      dataIndex: "id",
      className: "center-cell",
      width: "120px",
      render: (value, record) => (
        <>
          {permissions.update && (
            <Tooltip title={resources.edit}>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleForm(record)}
              />
            </Tooltip>
          )}
          {permissions.remove && (
            <Tooltip title={resources.delete}>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(value)}
              />
            </Tooltip>
          )}
        </>
      ),
    });
  }

  return (
    <>
      {/* <Tabs
				className="sp-tabs"
				activeKey={2}
				items={[
					{ key: 1, label: <Link to={paths.educational.dailyReports}>{`${resources.dailyreport}`}</Link> },
					{ key: 2, label: <Link to={paths.educational.classAssessments}>{`${resources.assessment} ${resources.class}${resources.y}`}</Link> },
					{ key: 3, label: <Link to={paths.educational.monthlyWorkbooks}>{`${resources.workbook} ${resources.monthly}`}</Link> },
					{ key: 4, label: <Link to={paths.educational.generalExams}>{`${resources.exam} ${resources.general}`}</Link> },
					{ key: 5, label: <Link to={paths.educational.generalReports}>{`${resources.report} ${resources.total}`}</Link> },
					{ key: 6, label: <Link to={paths.educational.regulations}>{`${resources.regulation} ${resources.education}${resources.y}`}</Link> },
				]}
			/> */}

      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="class">
              <Select
                placeholder={resources.class}
                options={classes}
                onChange={handleStudentFilter}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="search">
              <Input
                suffix={<SearchOutlined />}
                onChange={handleStudentFilter}
                placeholder={`${resources.search} ${resources.student}`}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="date">
              <DatePickerJalali
                onChange={handleFilter}
                style={{ display: "block" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <StudentCarousel students={students} onFetch={handleStudents} />

      <Table
        columns={tableColumns}
        dataSource={data}
        loading={loading}
        // onChange={handleTable}
        pagination={false}
        scroll={{ x: 930 }}
        size="small"
        rowKey="id"
        expandable={{
          expandedRowRender: (record) =>
            `${resources.description}: ${record.description}`,
        }}
      />

      {permissions.create && (
        <>
          <FloatButton
            type="primary"
            tooltip={resources.add}
            icon={<PlusOutlined />}
            onClick={() => handleForm()}
          />

          <FormModal
            data={selected}
            onUpdate={handleForm}
            options={{ assessments, lessons, selectStudent }}
          />
        </>
      )}

      {contextHolder}
    </>
  );
};

export default Component;
