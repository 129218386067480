import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer, Form, Input, Spin } from "antd";
import PropTypes from "prop-types";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

const Component = ({ data = {}, onUpdate }) => {
	const initialState = {};
	const [state, setState] = useState(initialState);
	const { loading, submitting, visible } = state;
	const { id, title } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));

					const action = hash === `#${Modals.EDIT}` ? api.disRegulation.update(values, id) : api.disRegulation.create(values);

					action
						.then(() => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, loading: false, visible: true }));

					form.setFieldsValue(data);
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={
				hash === `#${Modals.EDIT}`
					? `${resources.edit} ${resources.regulation} ${resources.discipline} "${title}"`
					: `${resources.add} ${resources.regulation} ${resources.discipline}`
			}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				{loading ? (
					<Spin className="sp-center-spin" />
				) : (
					<>
						<Form.Item
							name="title"
							label={resources.title}
							rules={rules.required}
						>
							<Input />
						</Form.Item>
						<Form.Item
							name="description"
							label={resources.description}
						>
							<Input.TextArea rows={4} />
						</Form.Item>
					</>
				)}
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
};

export default Component;
