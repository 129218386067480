import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, DatePicker, Drawer, Form, Input, Select } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

// import UploadImage from "components/UploadImageCropper";
import UploadImage from "components/UploadImage";

const Component = ({ data = {}, onUpdate, type }) => {
  const initialState = {};
  const [state, setState] = useState(initialState);
  const { submitting, visible } = state;
  const { id, firstName, lastName } = data;
  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    if (!submitting) {
      form
        .validateFields()
        .then((values) => {
          setState((prevState) => ({ ...prevState, submitting: true }));

          if (values.descriptionImage) {
            values.parentImageId = values.descriptionImage.id;
          }
          // values.description = values.parentDescription;

          delete values.descriptionImage;
          // delete values.parentDescription;

          const action =
            hash === `#${Modals.EDIT}`
              ? api.stPanel.eduParentDescription(values, id)
              : api.student.create(values);

          action
            .then((res) => {
              setTimeout(function () {
                onUpdate(null, true);
                navigate(-1);
              }, 500);
            })
            .catch((e) => {
              setState((prevState) => ({ ...prevState, submitting: false }));
            });
        })
        .catch((e) => {});
    }
  };

  const componentDidUpdate = () => {
    switch (hash) {
      case `#${Modals.CREATE}`: {
        setState((prevState) => ({ ...prevState, visible: true }));

        break;
      }
      case `#${Modals.EDIT}`: {
        if (id) {
          setState((prevState) => ({ ...prevState, visible: true }));

          form.setFieldsValue({
            ...data,
            birthDate: dayjs(data.birthDate),
            classId: data.class?.id,
          });
        } else {
          navigate(`${pathname}${search}`, { replace: true });
        }

        break;
      }
      default: {
        if (visible) form.resetFields();

        setState(initialState);

        break;
      }
    }
  };

  useEffect(componentDidUpdate, [hash]);

  return (
    <Drawer
      className="sp-form-drawer"
      placement="right"
      footer={
        <>
          <Button type="primary" onClick={handleSubmit} loading={submitting}>
            {resources.confirm}
          </Button>
          <Button onClick={() => navigate(-1)} disabled={submitting}>
            {resources.cancel}
          </Button>
        </>
      }
      onClose={() => navigate(-1)}
      title={
        hash === `#${Modals.EDIT}`
          ? `${resources.description} ${resources.parent} `
          : `${resources.add} ${resources.student}`
      }
      maskClosable={false}
      open={visible}
      width={320}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          name="description"
          label={resources.description}
          rules={rules.required}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item name="descriptionImage" label={resources.image}>
          <UploadImage />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

Component.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default Component;
