import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Input, Button, Col, FloatButton, Form, Modal, Row, Select, Table, Tabs, Tooltip, Image } from "antd";
import { DeleteOutlined, EyeOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/GeneralExamForm";
import StudentCarousel from "components/StudentCarousel";

const Component = ({ permissions, setRoute }) => {
	const initialState = { classes: [], data: [], students: [] };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => index + 1,
		},
		{
			title: resources.image,
			dataIndex: "image",
			render: (value) => (
				<>
					<Image
						src={`${ENV.basePath}${value}`}
						// shape="square"
						width={40}
						height={40}
					/>
				</>
			),
		},
		{
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		},
	];
	const [state, setState] = useState(initialState);
	const { classes, data, loading, selected, students, selectStudent, selectedStudent } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue(params);

		if (params.student) {
			setState((prevState) => ({ ...prevState, loading: true }));

			api.generalExam
				.list(params)
				.then((data) => {
					setState((prevState) => ({ ...prevState, data, loading: false }));
				})
				.catch(() => {
					setState((prevState) => ({ ...prevState, loading: false }));
				});
		} else {
			setState((prevState) => ({ ...prevState, data: [] }));
		}
	};

	const handleStudentFilter = debounce(() => {
		form
			.validateFields()
			.then((values) => {
				const params = GetUrlParams(search);
				const _params = params.search === values.search ? params : {};

				const _search = ObjectToURL({ ..._params, ...values });

				navigate(`${pathname}?${_search}`);
			})
			.then(() => {
				const params = GetUrlParams();
				const classId = params.class;
				const searchText = params.search;
				if (params.search && params.class) {
					let searchText = params.search;

					api.student
						.related(params.class, searchText)
						.then((students) => {
							setState((prevState) => ({ ...prevState, students, classId: params.class, loading: false }));
						})
						.catch(() => {});
				} else if (params.class) {
					api.student
						.related(params.class)
						.then((students) => {
							setState((prevState) => ({ ...prevState, students, classId: params.class, loading: false }));
							let selectStudent = students.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` }));
							setState((prevState) => ({ ...prevState, selectStudent }));
						})
						.catch(() => {});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}

				if (params.search) {
					api.student
						.related(classId, searchText)
						.then((students) => {})
						.catch(() => {
							setState((prevState) => ({ ...prevState, loading: false }));
						});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}
			});
	}, 1000);

	const handleStudents = (selectedStudent) => {
		setState((prevState) => ({ ...prevState, selectedStudent }));
	};

	const handleView = (selected) => {};

	const handleForm = (reload) => {
		if (reload) {
			fetchData();
		} else {
			navigate(`${pathname}${search}#${Modals.CREATE}`);
		}
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.generalExam
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const params = GetUrlParams(search);

			const _params = params.class === values.class ? params : {};

			const _search = ObjectToURL({ ..._params, ...values });

			navigate(`${pathname}?${_search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.class
			.related()
			.then((classes) => {
				setState((prevState) => ({ ...prevState, classes }));
			})
			.catch(() => {});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	return (
		<>
			{/* <Tabs
				className="sp-tabs"
				activeKey={4}
				items={[
					{ key: 1, label: <Link to={paths.educational.dailyReports}>{`${resources.dailyreport}`}</Link> },
					{ key: 2, label: <Link to={paths.educational.classAssessments}>{`${resources.assessment} ${resources.class}${resources.y}`}</Link> },
					{ key: 3, label: <Link to={paths.educational.monthlyWorkbooks}>{`${resources.workbook} ${resources.monthly}`}</Link> },
					{ key: 4, label: <Link to={paths.educational.generalExams}>{`${resources.exam} ${resources.general}`}</Link> },
					{ key: 5, label: <Link to={paths.educational.generalReports}>{`${resources.report} ${resources.total}`}</Link> },
					{ key: 6, label: <Link to={paths.educational.regulations}>{`${resources.regulation} ${resources.education}${resources.y}`}</Link> },
				]}
			/> */}

			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="class">
							<Select
								placeholder={resources.class}
								options={classes}
								onChange={handleStudentFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="search">
							<Input
								suffix={<SearchOutlined />}
								onChange={handleStudentFilter}
								placeholder={`${resources.search} ${resources.student}`}
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<StudentCarousel
				students={students}
				onFetch={handleStudents}
			/>
			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={false}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			{permissions.create && (
				<>
					<FloatButton
						type="primary"
						tooltip={resources.add}
						icon={<PlusOutlined />}
						onClick={() => handleForm()}
					/>

					<FormModal
						data={selected}
						onUpdate={handleForm}
						options={{ selectStudent }}
					/>
				</>
			)}

			{contextHolder}
		</>
	);
};

export default Component;
