import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import { Avatar, Drawer, Dropdown, Layout, Menu, Spin, Badge } from "antd";
import { LockOutlined, MenuOutlined, PoweroffOutlined, BellOutlined } from "@ant-design/icons";
import MediaQuery from "react-responsive";

import { openMenu, updateUser } from "scripts/reducer";
import { ENV } from "scripts/settings";
import paths from "scripts/paths";
import resources from "scripts/resources";

import Footer from "components/Footer";
import LogoImg from "images/logo.png";
import AvatarImg from "images/avatar.png";
import api from "scripts/api";

const { Header, Sider, Content } = Layout;

const Component = ({ openKey, onOpenMenu, onUpdateUser, routes, selectedKey, user }) => {
	const initialState = {
		notification: {},
	};
	const [state, setState] = useState(initialState);
	const { visible, notification, loaded } = state;
	const showDrawer = () => {
		setState((prevState) => ({ ...prevState, visible: true }));
	};

	const hideDrawer = () => {
		setState((prevState) => ({ ...prevState, visible: false }));
	};

	const handleMenu = ({ key }) => {
		onOpenMenu(openKey === key ? "" : key);
	};

	const handleLogout = () => {
		api.delCookie();
		window.location = paths.auth.login;

		// api.auth
		//   .logout()
		//   .then(() => {
		//     api.delCookie();
		//   })
		//   .catch(() => {});
	};

	const componentDidMount = () => {
		const token = api.getCookie();

		if (token) {
			api.auth
				.me()
				.then((result) => {
					onUpdateUser(result);

					api.notifications().then((notification) => {
						setState((prevState) => ({ ...prevState, loaded: true, notification }));
					});
				})
				.catch(() => {
					window.location = paths.auth.login;
				});
		} else {
			window.location = paths.auth.login;
		}
	};

	useEffect(componentDidMount, []);

	const menu = (
		<>
			<Link
				to="/"
				className="sp-default-logo"
			>
				<img src={LogoImg} />
			</Link>
			<Menu
				mode="inline"
				items={routes.map(({ children, icon, label, name, path }) => {
					if (children) {
						const notif = !!children.find(({ name }) => notification[name]);

						return {
							key: name,
							icon: icon,
							label: (
								<>
									{label}
									<Badge
										dot
										color="red"
										id={name + "-parent"}
										style={{ visibility: "hidden" }}
									></Badge>
								</>
							),
							children: children.map((o) => ({
								key: o.name,
								icon: o.icon,
								label: (
									<>
										<Link
											to={o.path}
											name={o.name}
										>
											{o.label}
										</Link>
										<Badge
											dot
											color="red"
											id={o.name}
											style={{ visibility: "hidden" }}
										></Badge>
									</>
								),
							})),
							onTitleClick: handleMenu,
						};
					} else {
						return {
							key: name,
							icon,
							label: <Link to={path}>{label}</Link>,
							onClick: hideDrawer,
						};
					}
				})}
				selectedKeys={[selectedKey]}
				openKeys={[openKey]}
				className="sp-default-menu"
			/>
		</>
	);

	const studentHeaderMenu = {
		items: [
			{
				icon: <LockOutlined />,
				label: <Link to={paths.edustudent.studentUserInfo}>{resources.profile}</Link>,
				key: "0",
			},
			{
				type: "divider",
			},
			{
				icon: <PoweroffOutlined />,
				label: resources.exit,
				key: "1",
				onClick: handleLogout,
			},
		],
	};

	const headerMenu = {
		items: [
			{
				icon: <LockOutlined />,
				label: <Link to={paths.auth.userInfo}>{resources.profile}</Link>,
				key: "0",
			},
			{
				type: "divider",
			},
			{
				icon: <PoweroffOutlined />,
				label: resources.exit,
				key: "1",
				onClick: handleLogout,
			},
		],
	};

	return loaded ? (
		<Layout className="sp-default-layout">
			<MediaQuery minWidth={1200}>
				<Sider width={260}>{menu}</Sider>
			</MediaQuery>
			<Layout>
				<Header className="nt-layout-header">
					<MediaQuery maxWidth={1200}>
						<MenuOutlined
							className="drawer-trigger"
							onClick={showDrawer}
						/>
					</MediaQuery>
					{user.dashboard == "Student" ? (
						<Dropdown
							menu={studentHeaderMenu}
							trigger={["click"]}
						>
							<div>
								<Avatar src={`${ENV.basePath}${user.avatar}`} />
								{`${user.firstName} ${user.lastName}`}
							</div>
						</Dropdown>
					) : (
						<Dropdown
							menu={headerMenu}
							trigger={["click"]}
						>
							<div>
								<Avatar src={`${ENV.basePath}${user.avatar}`} />
								{`${user.firstName} ${user.lastName}`}
							</div>
						</Dropdown>
					)}
				</Header>
				<Content style={{ marginTop: "100px" }}>
					<Outlet />
				</Content>
				<Footer />
				<MediaQuery maxWidth={1200}>
					<Drawer
						open={visible}
						closable={false}
						onClose={hideDrawer}
						placement="right"
						width={220}
						className="sp-default-drawer"
					>
						{menu}
					</Drawer>
				</MediaQuery>
			</Layout>
		</Layout>
	) : (
		<Spin className="sp-center-spin" />
	);
};

export default connect(
	(state) => ({
		openKey: state.openKey,
		selectedKey: state.selectedKey,
		user: state.user,
	}),
	(dispatch) => ({
		onOpenMenu: (data) => dispatch(openMenu(data)),
		onUpdateUser: (data) => dispatch(updateUser(data)),
	})
)(Component);
