import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Avatar, Button, Col, FloatButton, Form, Input, Modal, Row, Select, Table, Tabs, Tooltip, DatePicker } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";

import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import moment, { months } from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/StudentForm";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");
	const initialState = {
		data: [],
		pagination: {},
		date: moment().format(process.env.REACT_APP_DATE_FORMAT).split(" "),
		EducationalYear: [],
	};
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
		},
		Table.EXPAND_COLUMN,
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: resources.title,
			dataIndex: "regulation",
			className: "center-cell",
		},
		{
			title: `${resources.firstName} ${resources.lesson}`,
			dataIndex: "lesson",
			className: "center-cell",
		},
		{
			title: `${resources.subject} ${resources.lesson}`,
			dataIndex: "topic",
			className: "center-cell",
		},
		{
			title: resources.assessment,
			dataIndex: "assessment",
			className: "center-cell ltr-cell",
		},
	];
	const [state, setState] = useState(initialState);
	const { classes, data, loading, pagination, selected, EducationalYear } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);
		form.resetFields();
		let paramsForSetting = params;

		form.setFieldsValue({
			...params,
			startDate: params.startDate ? dayjs(params.startDate) : undefined,
			endDate: params.endDate ? dayjs(params.endDate) : undefined,
		});

		setState((prevState) => ({ ...prevState, loading: true }));

		api.stPanel
			.generalReports({
				...paramsForSetting,
				pageSize: process.env.REACT_APP_PAGE_SIZE,
			})
			.then(({ list: data, pagination }) => {
				setState((prevState) => ({
					...prevState,
					data,
					pagination,
					loading: false,
				}));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`);
		}
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			if (values.startDate) {
				let months = values.startDate.$M + 1;
				let start = `${values.startDate.$y}-${months}-${values.startDate.$D}`;
				values.startDate = start;
			}
			if (values.endDate) {
				let months = values.endDate.$M + 1;
				let end = `${values.endDate.$y}-${months}-${values.endDate.$D}`;
				values.endDate = end;
			}

			const search = ObjectToURL({ page: 1, ...values });

			navigate(`${pathname}?${search}`);
		});
	}, 1000);

	const handleStudentFilter = debounce(() => {
		form
			.validateFields()
			.then((values) => {
				const params = GetUrlParams(search);
				const _params = params.search === values.search ? params : {};

				const _search = ObjectToURL({ ..._params, ...values });

				navigate(`${pathname}?${_search}`);
			})
			.then(() => {
				const params = GetUrlParams();
				const classId = params.class;
				const searchText = params.search;
				if (params.search && params.class) {
					let searchText = params.search;

					api.student
						.related(params.class, searchText)
						.then((students) => {
							setState((prevState) => ({
								...prevState,
								students,
								classId: params.class,
								loading: false,
							}));
							if (onFetch)
								onFetch(
									data.map(({ id, firstName, lastName }) => ({
										value: id,
										label: `${firstName} ${lastName}`,
									}))
								);
						})
						.catch(() => {});
				} else if (params.class) {
					api.student
						.related(params.class)
						.then((students) => {
							setState((prevState) => ({
								...prevState,
								students,
								classId: params.class,
								loading: false,
							}));

							if (onFetch)
								onFetch(
									data.map(({ id, firstName, lastName }) => ({
										value: id,
										label: `${firstName} ${lastName}`,
									}))
								);
						})
						.catch(() => {});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}

				if (params.search) {
					api.student
						.related(classId, searchText)
						.then((students) => {})
						.catch(() => {
							setState((prevState) => ({ ...prevState, loading: false }));
						});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}
			});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.stPanel
			.generalReports({ pageSize: process.env.REACT_APP_PAGE_SIZEE })
			.then(({ list: data, pagination }) => {
				setState((prevState) => ({
					...prevState,
					data,
					pagination,
					loading: false,
				}));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
		api.educationalYear
			.list()
			.then((EducationalYear) => {
				setState((prevState) => ({ ...prevState, EducationalYear }));
			})
			.catch(() => {});
	};

	const expandedRowRender = (record) => {
		const columns = [
			{
				title: resources.description,
				key: "description",
				dataIndex: "description",

				render: (value, record) => {
					return <> {`${record.description ? record.description : "-"}`}</>;
				},
			},
		];

		const data = [];
		data.push({
			description: record.description,
		});
		return (
			<Table
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
		);
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.remove || permissions.update) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			<Tabs
				className="sp-tabs"
				activeKey={6}
				items={[
					{
						key: 1,
						label: <Link to={paths.edustudent.dailyReports}>{`${resources.dailyreport}`}</Link>,
					},
					{
						key: 2,
						label: <Link to={paths.edustudent.dailyActivity}>{`${resources.eduDailyActivity}`}</Link>,
					},
					{
						key: 3,
						label: <Link to={paths.edustudent.classAssessments}>{`${resources.assessment} ${resources.class}`}</Link>,
					},
					{
						key: 4,
						label: <Link to={paths.edustudent.monthlyGradeSheet}>{`${resources.workbook} ${resources.monthly}`}</Link>,
					},
					{
						key: 5,
						label: <Link to={paths.edustudent.generalExams}>{`${resources.exam} ${resources.general}`}</Link>,
					},
					{
						key: 6,
						label: <Link to={paths.edustudent.generalReports}>{`${resources.report} ${resources.total}`}</Link>,
					},
				]}
			/>

			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					{/*  */}
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="EducationalYear">
							<Select
								placeholder={`${resources.academicYear}`}
								options={EducationalYear}
								onChange={handleStudentFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					{/*  */}
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="startDate">
							<DatePickerJalali
								placeholder={`${resources.date} ${resources.start}`}
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="endDate">
							<DatePickerJalali
								placeholder={`${resources.date} ${resources.end}`}
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
				// expandable={{ expandedRowRender: (record) => `${resources.description}: ${record.description}` }}
				expandable={{
					expandedRowRender: (record) => expandedRowRender(record),
				}}
			/>

			{contextHolder}
		</>
	);
};

export default Component;
