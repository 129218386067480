import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, Drawer, Form, Input, Select } from "antd";
import {
  DatePicker as DatePickerJalali,
  Calendar as CalendarJalali,
  useJalaliLocaleListener,
} from "antd-jalali";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { Modals, Types } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

import UploadFile from "components/UploadMultiVideo";

const Component = ({ data = [], onUpdate, options: { classes = [] } }) => {
  useJalaliLocaleListener();
  dayjs.calendar("jalali");

  const initialState = {};
  const [state, setState] = useState(initialState);
  const { submitting, visible } = state;
  const { id, firstName, lastName } = data;
  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    if (!submitting) {
      form
        .validateFields()
        .then((values) => {
          //   values.date.$D =
          //     values.date.$D < 10 ? `0${values.date.$D}` : values.date.$D;
          //   values.date.$M =
          //     values.date.$M < 10 ? `0${values.date.$M + 1}` : values.date.$M + 1;
          //   const formattedDate = `${values.date.$y}-${values.date.$M}-${values.date.$D}`;
          //   values.date = formattedDate;

          setState((prevState) => ({ ...prevState, submitting: true }));

          // values.classId = values.class;
          let fileId = [];
          if (values.file?.length > 0) {
            values.file.map((item) => {
              fileId.push(item.id);
            });
          }
          values.files = fileId;
          values.id = data.id;
          delete values.file;
          delete values.file;
          // delete values.class;

          const params = values;
          api.course
            .create(params)
            .then(() => {
              setTimeout(function () {
                onUpdate(null, true);
                navigate(-1);
              }, 500);
            })
            .catch((error) => {
              setState((prevState) => ({ ...prevState, submitting: false }));
              return error;
            });
        })
        .catch(() => {});
    }
  };

  const componentDidUpdate = () => {
    switch (hash) {
      case `#${Modals.CREATE}`: {
        setState((prevState) => ({ ...prevState, visible: true }));

        break;
      }
      case `#${Modals.EDIT}`: {
        if (id) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            visible: true,
          }));

          form.setFieldsValue({
            ...data,
            timeout: dayjs(data.date),
            debt: data.debt,
            credit: data.credit,
            user: data.id,
          });
        } else {
          navigate(`${pathname}${search}`, { replace: true });
        }

        break;
      }
      default: {
        if (visible) form.resetFields();

        setState(initialState);

        break;
      }
    }
  };

  useEffect(componentDidUpdate, [hash]);

  return (
    <Drawer
      className="sp-form-drawer"
      placement="right"
      footer={
        <>
          <Button type="primary" onClick={handleSubmit} loading={submitting}>
            {resources.confirm}
          </Button>
          <Button onClick={() => navigate(-1)} disabled={submitting}>
            {resources.cancel}
          </Button>
        </>
      }
      onClose={() => navigate(-1)}
      title={hash === `#${Modals.FILE} ${resources.add} ${resources.file}`}
      maskClosable={false}
      open={visible}
      width={320}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          name="classId"
          label={resources.class}
          rules={rules.required}
        >
          <Select options={classes} />
        </Form.Item>
        <Form.Item
          name="title"
          label={`${resources.title} ${resources.file}`}
          rules={rules.required}
        >
          <Input />
        </Form.Item>
        <Form.Item name="date" label={resources.date} rules={rules.required}>
          <DatePickerJalali style={{ display: "block" }} />
        </Form.Item>
        <Form.Item name="file" label={resources.file} rules={rules.required}>
          <UploadFile />
        </Form.Item>
      </Form>
      {/* {resArray && (
				<Card>
					{data.map((el) => {
						return <p>{el}</p>;
					})}
				</Card>
			)} */}
    </Drawer>
  );
};

Component.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

export default Component;
