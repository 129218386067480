import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Avatar, Button, Col, FloatButton, Form, Input, Modal, Row, Table, Tabs, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals, Types } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/UserForm";
import RoleModal from "modals/UserRole";

const Component = ({ permissions, setRoute }) => {
	const initialState = { data: [], pagination: {} };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
		},
		{
			title: resources.user,
			dataIndex: "avatar",
			className: "profile-cell",
			render: (value, record) => (
				<>
					<Tooltip
						title={
							<Avatar
								src={`${ENV.basePath}${value?.uri}`}
								size={200}
								shape="square"
							/>
						}
					>
						<Avatar src={`${ENV.basePath}${value?.uri}`} />
					</Tooltip>
					{`${record.firstName} ${record.lastName}`}
				</>
			),
		},
		{
			title: resources.dashboard,
			dataIndex: "dashboard",
			className: "center-cell",
			render: (value) => {
				switch (value) {
					case Types.dashboard.admin:
						return resources.management;
					case Types.dashboard.default:
						return `${resources.wihtout} ${resources.dashboard}`;
				}
			},
		},
		{
			title: resources.nationalCode,
			dataIndex: "nationalCode",
			className: "center-cell ltr-cell",
			width: "100px",
		},
		{
			title: resources.role,
			dataIndex: "role",
			className: "center-cell ltr-cell",
		},
	];
	const [state, setState] = useState(initialState);
	const { data, loading, pagination, roles, selected } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue(params);

		setState((prevState) => ({ ...prevState, loading: true }));

		api.user
			.list({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then(({ list: data, pagination }) => {
				setState((prevState) => ({ ...prevState, data, pagination, loading: false }));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`);
		}
	};

	const handleRole = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${Modals.ROLE}`);
		}
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.user
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const search = ObjectToURL({ page: 1, ...values });

			navigate(`${pathname}?${search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.role
			.list({ paging: false })
			.then((data) => {
				setState((prevState) => ({ ...prevState, roles: data.map(({ id, name }) => ({ value: id, label: name })) }));
			})
			.catch(() => {});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.remove || permissions.role || permissions.update) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.role && (
						<Tooltip title={resources.role}>
							<Button
								type="link"
								icon={<EyeOutlined />}
								onClick={() => handleRole(record)}
							/>
						</Tooltip>
					)}
					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			{/* <Tabs
				className="sp-tabs"
				activeKey={2}
				items={[
					{ key: 1, label: <Link to={paths.configs.students}>{resources.students}</Link> },
					{ key: 2, label: <Link to={paths.configs.users}>{resources.users}</Link> },
					{ key: 3, label: <Link to={paths.configs.classes}>{`${resources.class}${resources.ha}`}</Link> },
					{ key: 4, label: <Link to={paths.configs.assessments}>{`${resources.assessment}${resources.ha}`}</Link> },
					{ key: 5, label: <Link to={paths.configs.roles}>{`${resources.role}${resources.ha}`}</Link> },
				]}
			/> */}

			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="search">
							<Input
								suffix={<SearchOutlined />}
								onChange={handleFilter}
								placeholder={resources.search}
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={resources.add}
					icon={<PlusOutlined />}
					onClick={() => handleForm()}
				/>
			)}

			{(permissions.create || permissions.update) && (
				<FormModal
					data={selected}
					onUpdate={handleForm}
					options={{ roles }}
				/>
			)}

			<RoleModal
				data={selected}
				onUpdate={handleRole}
				options={{ roles }}
			/>

			{contextHolder}
		</>
	);
};

export default Component;
