import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Checkbox, Row, Col, Drawer, Form, Input, Select } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import locale from "antd/locale/fa_IR";
// import "dayjs/locale/fa_IR";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

import UploadFile from "components/UploadMultiImage";

const Component = ({ data = {}, onUpdate, options: { assessments = [], regulations = [], selectStudent = [] } }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible } = state;
	const { id } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	var d = new Date(2018, 8, 18);

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then(async (values) => {
					let titles = [];
					for (const key in values) {
						if (values[key] && key != "studentId") {
							titles.push(key);
						}
					}

					delete values.StudentPhotos;
					delete values.StudentBirthCertificate;
					delete values.FatherBirthCertificate;
					delete values.MotherBirthCertificate;
					delete values.FatherNationalCard;
					delete values.MotherNationalCard;
					delete values.StudentVaccineCard;
					delete values.StudentsTestResult;
					delete values.StudentExaminations;
					delete values.HealthCard;
					delete values.Other;

					let date = new Date();

					values.titles = titles;
					values.date = date;

					if (hash === `#${Modals.CREATE}`) {
						setState((prevState) => ({ ...prevState, submitting: true }));
						const action = api.documents.create(values);
						action
							.then((res) => {})
							.catch(() => {
								setState((prevState) => ({ ...prevState, submitting: false }));
							});

						setTimeout(function () {
							onUpdate(null, true);
							navigate(-1);
						}, 500);
					} else {
						setState((prevState) => ({ ...prevState, submitting: true }));

						const action = api.documents.update(values, id);
						action
							.then(async (res) => {
								setTimeout(function () {
									onUpdate(null, true);
									navigate(-1);
								}, 500);
							})
							.catch(() => {
								setState((prevState) => ({ ...prevState, submitting: false }));
							});
					}
				})
				.catch(() => {});
		}
	};

	const disabledDateTime = () => ({
		disabledHours: () => range(0, 24).splice(4, 20),
		disabledMinutes: () => range(30, 60),
		disabledSeconds: () => [55, 56],
	});

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true }));
					form.setFieldsValue({
						...data,
						date: dayjs(data.date),
						assessmentId: data.assessment?.id,
						lesson: data.lesson?.value,
						regulationId: data.regulation?.id,
						studentId: data.student?.id,
						effectOnBar: !data.effectOnBar,
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={hash === `#${Modals.FILE} ${resources.add} ${resources.file}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="studentId"
					label={resources.student}
					rules={rules.required}
				>
					<Select options={selectStudent} />
				</Form.Item>
				<Col>
					<Form.Item
						name="StudentPhotos"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.image} ${resources.student}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="StudentBirthCertificate"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.StudentBirthCertificate}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="FatherBirthCertificate"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.FatherBirthCertificate}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="MotherBirthCertificate"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.MotherBirthCertificate}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="FatherNationalCard"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.FatherNationalCard}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="MotherNationalCard"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.MotherNationalCard}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="StudentVaccineCard"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.vaccineCard} ${resources.student}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="StudentsTestResult"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.test} ${resources.student}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="StudentExaminations"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.medicalExaminationsCard}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="HealthCard"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.healthCard}`}</Checkbox>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item
						name="Other"
						valuePropName="checked"
					>
						<Checkbox>{`${resources.other}`}</Checkbox>
					</Form.Item>
				</Col>
			</Form>
			{/* {resArray && (
				<Card>
					{data.map((el) => {
						return <p>{el}</p>;
					})}
				</Card>
			)} */}
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
