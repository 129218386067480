import { Card, Spin, Avatar, Row, Col } from "antd";

import { useState, useEffect } from "react";
import BarComponent from "../../components/BarComponent";

import api from "../../scripts/api";
import resources from "scripts/resources";
import { ENV } from "scripts/settings";

const Component = () => {
	const presidentMainDivStyle = {
		width: "100%",
		rowGap: "16px",
		display: "flex",
		flexWrap: "wrap",
		marginTop: "16px",
		alignItems: "center",
		justifyContent: "space-evenly",
	};
	const UserInfoCardData = {
		width: "100%",
		rowGap: "16px",
		display: "flex",
		flexWrap: "wrap",
		marginTop: "16px",
		alignItems: "center",
		justifyContent: "space-evenly",
	};

	const BackCard = {
		backgroundColor: "#d5e5d2",
		padding: "100px",
		marginRight: "10%",
		marginLeft: "10%",
		borderRadius: "10px",
	};
	const style = {
		background: "#abcaa5",
		padding: "20px 10px 20px 10px",
		borderRadius: "5px",
	};
	const initialState = {};

	const [data, setData] = useState([]);
	const [state, setState] = useState(initialState);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		api.auth
			.me()
			.then((res) => {
				// responseData = res;
				setData(res);

				//console.log("log line 64 dash is ", res);
				setLoading(false);
			})
			.catch((e) => {
				setState((prevState) => ({ ...prevState, submitting: false }));
			});
	}, []);

	const RenderUserInfo = () => {
		if (data == [] || !data) {
			return <div></div>;
		}
		return (
			<div style={BackCard}>
				<Row
					style={{ marginBottom: "20px" }}
					justify="center"
					gutter={16}
				>
					<Col
						className="gutter-row"
						span={8}
					></Col>
					<Col
						className="gutter-row"
						span={8}
					>
						<Avatar
							style={{ marginRight: "20%", border: "2px solid  #5a7156" }}
							src={`${ENV.basePath}${data.user.avatar}`}
							size={{ xs: 24, sm: 60, md: 80, lg: 100, xl: 120, xxl: 250 }}
						/>
					</Col>
					<Col
						className="gutter-row"
						span={8}
					></Col>
				</Row>
				<Row
					style={style}
					gutter={16}
				>
					<Col
						className="gutter-row"
						span={12}
						xs={24}
						sm={24}
						md={12}
					>
						<div>
							{`${resources.firstName} ${resources.and} ${resources.lastName} :`}
							<br />
							{`${data.user.firstName} ${data.user.lastName}`}
						</div>
					</Col>

					<Col
						className="gutter-row"
						span={12}
						xs={24}
						sm={24}
						md={12}
					>
						<div>
							{resources.role} :
							<br />
							{data.user.role}
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	return <div>{loading ? <Spin>Loading...</Spin> : <RenderUserInfo />}</div>;
};

export default Component;
