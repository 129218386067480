import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Checkbox, TimePicker, Drawer, Form, Input, Select } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

const Component = ({ data = {}, type, onUpdate, options: { assessments = [], lessons = [], regulations = [], selectStudent = [] } }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");
	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible } = state;
	const { id } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const format = "HH:mm";

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					values.date.$D = values.date.$D < 10 ? `0${values.date.$D}` : values.date.$D;
					values.date.$M = values.date.$M < 10 ? `0${values.date.$M + 1}` : values.date.$M + 1;
					const formattedDate = `${values.date.$y}-${values.date.$M}-${values.date.$D}`;
					values.date = formattedDate;
					values.type = type;

					if (values.callTime) {
						let callHaour = values.callTime.$H < 10 ? `0${values.callTime.$H}` : values.callTime.$H;

						let callMinut = values.callTime.$m < 10 ? `0${values.callTime.$m}` : values.callTime.$m;

						let callTime = `${callHaour}:${callMinut}:07`;

						delete values.callTime;
						values.callTime = callTime;
					}
					if (hash === `#${Modals.CREATE}`) {
						values.effectOnBar = !values.effectOnBar;
						for (let value of values.studentId) {
							values.studentId = value;
							setState((prevState) => ({ ...prevState, submitting: true }));

							const action = api.eduReport.create(values);
							action
								.then((res) => {
									console.log("this is from edu form ", res);
								})
								.catch(() => {
									setState((prevState) => ({ ...prevState, submitting: false }));
								});
						}
						setTimeout(function () {
							onUpdate(null, true);
							navigate(-1);
						}, 500);
					} else {
						values.effectOnBar = !values.effectOnBar;
						setState((prevState) => ({ ...prevState, submitting: true }));

						const action = api.eduReport.update(values, id);
						action
							.then((res) => {
								setTimeout(function () {
									onUpdate(null, true);
									navigate(-1);
								}, 500);
							})
							.catch(() => {
								setState((prevState) => ({ ...prevState, submitting: false }));
							});
					}
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true }));

					form.setFieldsValue({
						...data,
						date: dayjs(data.date),
						callTime: dayjs(data.callTime),
						assessmentId: data.assessment?.id,
						lesson: data.lesson?.value,
						regulationId: data.regulation?.id,
						studentId: data.student?.id,
						effectOnBar: !data.effectOnBar,
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={`${hash === `#${Modals.EDIT}` ? resources.edit : resources.add} ${`${resources.dailyreport}`}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="lesson"
					label={resources.lesson}
				>
					<Select
						options={lessons}
						allowClear
					/>
				</Form.Item>
				{type == "Other" && (
					<Form.Item
						name="assessmentId"
						label={resources.assessment}
					>
						<Select
							options={assessments}
							allowClear
						/>
					</Form.Item>
				)}
				<Form.Item
					name="topic"
					label={`${resources.subject} ${resources.lesson}`}
				>
					<Input />
				</Form.Item>
				{type == "Other" && (
					<Form.Item
						name="regulationId"
						label={resources.regulation}
						rules={rules.required}
					>
						<Select options={regulations} />
					</Form.Item>
				)}
				<Form.Item
					name="studentId"
					label={resources.student}
					rules={rules.required}
				>
					<Select
						mode="multiple"
						options={selectStudent}
						filterOption={false}
						showSearch
					/>
				</Form.Item>
				<Form.Item
					name="date"
					label={resources.date}
					rules={rules.required}
				>
					<DatePickerJalali style={{ display: "block" }} />
				</Form.Item>
				{type == "Other" && (
					<Form.Item
						name="callTime"
						label={`${resources.hour} ${resources.call}`}
					>
						<TimePicker format={format} />
					</Form.Item>
				)}

				<Form.Item
					name="description"
					label={resources.description}
				>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item
					name="effectOnBar"
					valuePropName="checked"
				>
					<Checkbox>{`${resources.wihtout} ${resources.score}`}</Checkbox>
				</Form.Item>
				<Form.Item
					name="needsParentDescription"
					valuePropName="checked"
				>
					<Checkbox>{`${resources.need} ${resources.description} ${resources.parent}`}</Checkbox>
				</Form.Item>
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
