import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	openKey: "",
	selectedKey: "",
	permissions: {},
	user: {},
};

export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		openMenu: (state, action) => {
			state.openKey = action.payload;
		},
		setRoute: (state, action) => {
			state.openKey = action.payload.parent;
			state.selectedKey = action.payload.name;
		},
		updateUser: (state, action) => {
			state.permissions = action.payload.policies;
			state.user = action.payload.user;
			state.isStudent = action.payload.isStudent;
		},
	},
});

export const { openMenu, setRoute, updateUser } = appSlice.actions;

export default appSlice.reducer;
