import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Checkbox, DatePicker, Drawer, Form, Input, Select } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

const Component = ({ data = {},type, onUpdate, options: { classes = [], assessments = [], lessons = [], regulations = [], students = [] } }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");
	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible } = state;
	const { id } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					values.type = type
					values.date.$D = values.date.$D < 10 ? `0${values.date.$D}` : values.date.$D;
					values.date.$M = values.date.$M < 10 ? `0${values.date.$M + 1}` : values.date.$M + 1;
					const formattedDate = `${values.date.$y}-${values.date.$M}-${values.date.$D}`;
					values.date = formattedDate;

					values.effectOnBar = !values.effectOnBar;
					setState((prevState) => ({ ...prevState, submitting: true }));

					// const action = hash === `#${Modals.EDIT}` ? api.eduReport.update(values, id) : api.eduReport.create(values);

					api.disReport
						.addToClass(values)
						.then((res) => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch((e) => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.ADDTOCLASS}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={`${hash === `#${Modals.EDIT}` ? resources.edit : resources.add} ${`${resources.dailyreport}`}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="classId"
					label={resources.class}
					rules={rules.required}
				>
					<Select options={classes} />
				</Form.Item>
				<Form.Item
					name="assessmentId"
					label={resources.assessment}
				>
					<Select
						options={assessments}
						allowClear
					/>
				</Form.Item>
				{/* <Form.Item
					name="topic"
					label={`${resources.subject} ${resources.lesson}`}
				> */}
				{/* <Input />
				</Form.Item> */}
				<Form.Item
					name="regulationId"
					label={resources.regulation}
					rules={rules.required}
				>
					<Select options={regulations} />
				</Form.Item>
				{/* <Form.Item
					name="studentId"
					label={resources.student}
					rules={rules.required}
				>
					<Select options={students} />
				</Form.Item> */}
				<Form.Item
					name="date"
					label={resources.date}
					rules={rules.required}
				>
					<DatePickerJalali style={{ display: "block" }} />
				</Form.Item>
				<Form.Item
					name="description"
					label={resources.description}
				>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item
					name="effectOnBar"
					valuePropName="checked"
				>
					<Checkbox>{`${resources.wihtout} ${resources.score}`}</Checkbox>
				</Form.Item>
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
