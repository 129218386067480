import "core-js";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

import faIR from "antd/locale/fa_IR";
import fa_IR from "antd/lib/locale/fa_IR";

import store from "scripts/store";

import Router from "router";

import "styles/index.less";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<ConfigProvider
			direction="rtl"
			locale={fa_IR}
			theme={{
				components: {
					Layout: {
						colorBgHeader: "#FFFFFF",
					},
					Menu: {
						darkItemSelectedColor: "#1A1A1A",
						darkItemColor: "#1A1A1A",
						darkItemBg: "#FFFFFF",
						darkSubMenuItemBg: "#FAFAFA",
						darkSubMenuItemColor: "#FAFAFA",
						darkItemHoverBg: "#D5E5D2",
						darkItemHoverColor: "#1A1A1A",
						itemMarginInline: 8,
					},
				},
				token: {
					colorPrimary: "#96BD8F",
					fontFamily: "IRANSans",
				},
			}}
		>
			<Router />
		</ConfigProvider>
	</Provider>
);
