import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer, Form, InputNumber, Spin, Select, Checkbox } from "antd";
import PropTypes from "prop-types";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import dayjs from "dayjs";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";
import UploadFile from "components/UploadMultiImage";

const Component = ({ data = {}, onUpdate, options: { type, selectStudent = [] } }) => {
	const initialState = { debt: "262000000", credit: "0", withoutDate: false };
	const [state, setState] = useState(initialState);
	// const [debt, setDebt] = useState("26000000");
	const { loading, submitting, visible, debt, credit, remain, withoutDate } = state;
	const { id, title } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));

					let fileId = [];
					if (values.file?.length > 0) {
						values.file.map((item) => {
							fileId.push(item.id);
						});
					}
					values.files = fileId;
					values.id = data.id;
					delete values.file;

					// values.timeout.$D = values.timeout.$D < 10 ? `0${values.timeout.$D}` : values.timeout.$D;
					// values.timeout.$M = values.timeout.$M < 10 ? `0${values.timeout.$M + 1}` : values.timeout.$M + 1;
					// const formattedDate = `${values.timeout.$y}-${values.timeout.$M}-${values.timeout.$D}`;
					// values.timeout = formattedDate;

					// if (!values.timeout) {
					// 	values.timeout = null;
					// }

					values.type = type;
					const action = hash === `#${Modals.EDIT}` ? api.financial.update(values, id) : api.financial.addFinanc(values);

					action
						.then(() => {
							onUpdate(null, true);
							navigate(-1);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));
				form.setFieldsValue({
					debt: debt,
					remain: debt - credit,
				});
				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({
						...prevState,
						loading: false,
						visible: true,
					}));

					form.setFieldsValue({
						...data,
						timeout: dayjs(data.timeout),
						debt: data.debt,
						credit: data.credit,
						user: data.id,
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);
	useEffect(() => {
		setState((prevState) => ({ ...prevState, remain: debt - credit }));
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));
				form.setFieldsValue({
					debt: debt,
					remain: debt - credit,
				});
				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({
						...prevState,
						loading: false,
						visible: true,
					}));

					form.setFieldsValue({
						...data,
						debt: data.debt,
						credit: credit,
						remain: data.debt - credit,
						timeout: dayjs(data.timeout),
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	}, [debt, credit]);

	const handleCheckbox = () => {
		setState((prevState) => ({ ...prevState, withoutDate: !withoutDate }));
	};

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={
				hash === `#${Modals.EDIT}`
					? `${resources.edit} ${resources.financial} ${resources.school}`
					: `${resources.add} ${resources.financial} ${resources.school}`
			}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				{loading ? (
					<Spin className="sp-center-spin" />
				) : (
					<>
						<Form.Item
							name="user"
							label={resources.student}
							rules={rules.required}
						>
							<Select options={selectStudent} />
						</Form.Item>
						{/* <Form.Item
							name="debt"
							label={resources.debtor}
							rules={rules.required}
						>
							<InputNumber
								controls={false}
								formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
								onChange={(value) => setState((prevState) => ({ ...prevState, debt: value }))}
							/>
						</Form.Item> */}
						{/* <Form.Item
							name="credit"
							label={resources.creditor}
						>
							<InputNumber
								controls={false}
								formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
								onChange={(value) => setState((prevState) => ({ ...prevState, credit: value }))}
							/>
						</Form.Item> */}
						{/* <Form.Item
							name="remain"
							label={resources.remaining}
							initialValue={remain}
						>
							<InputNumber
								controls={false}
								formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item> */}
						<Form.Item name="withouttime">
							<Checkbox onChange={handleCheckbox}>{`${resources.wihtout} ${resources.date}`}</Checkbox>
						</Form.Item>
						<Form.Item
							name="timeout"
							label={`${resources.deadline} ${resources.payment}`}
							initialValue={dayjs("2024-02-19", "YYYY-MM-DD")}
						>
							{!withoutDate ? (
								<DatePickerJalali
									style={{ display: "block" }}
									initialValue={dayjs("2024-02-19", "YYYY-MM-DD")}
								/>
							) : (
								<DatePickerJalali
									style={{ display: "block" }}
									disabled={true}
								/>
							)}
						</Form.Item>
						<Form.Item
							name="file"
							rules={rules.required}
							label={resources.file}
						>
							<UploadFile />
						</Form.Item>
					</>
				)}
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
};

export default Component;
