export default function BarComponent({ amount, maxAmountAtEachSide }) {
	let rightColor;
	let leftColor;
	let rightAmount;
	let leftAmount;
	if (amount >= 0 && amount < 30) {
		rightColor = "#00ff00";
		leftColor = "#ffffff";
		rightAmount = amount;
		leftAmount = 0;
	} else if (amount >= 30 && amount < 60) {
		rightColor = "#00ff00";
		leftColor = "#ffffff";
		rightAmount = amount;
		leftAmount = 0;
	} else if (amount >= 60 && amount < 90) {
		rightColor = "#0000ff";
		leftColor = "#ffffff";
		rightAmount = amount;
		leftAmount = 0;
	} else if (amount >= -30 && amount < 0) {
		rightColor = "#ffffff";
		leftColor = "#ffff00";
		rightAmount = 0;
		leftAmount = amount * -1;
	} else if (amount >= -60 && amount < -30) {
		rightColor = "#ffffff";
		leftColor = "#ff0000";
		rightAmount = 0;
		leftAmount = amount * -1;
	}

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				minWidth: "80px",
				height: "8px",
				borderRadius: "50px",
				alignItems: "center",
			}}
		>
			{/* REGION, right side */}
			<span
				style={{
					marginLeft: "4px",
				}}
			></span>

			<div
				style={{
					width: "50%",
					position: "relative",
					backgroundColor: "#f0f0f0",
					zIndex: 0,
					height: "inherit",
					borderTopRightRadius: "50px",
					borderBottomRightRadius: "50px",
				}}
			>
				<div
					style={{
						width: rightAmount < 25 ? `${(rightAmount / maxAmountAtEachSide) * 100}%` : "100%",
						position: "absolute",
						backgroundColor: rightColor ?? "#f0f0f0",
						zIndex: 2,
						height: "inherit",
						left: 0,
						borderTopRightRadius: "50px",
						borderBottomRightRadius: "50px",
					}}
				></div>
			</div>

			{/* REGION, left side */}
			<div
				style={{
					width: "50%",
					position: "relative",
					backgroundColor: "#f0f0f0",
					zIndex: 0,
					height: "inherit",
					borderTopLeftRadius: "50px",
					borderBottomLeftRadius: "50px",
					display: "flex",
					alignItems: "center",
				}}
			>
				<div
					style={{
						width: leftAmount < 25 ? `${(leftAmount / maxAmountAtEachSide) * 100}%` : "100%",
						position: "absolute",
						backgroundColor: leftColor ?? "#f0f0f0",
						zIndex: 2,
						height: "inherit",
						right: 0,

						borderTopLeftRadius: "50px",
						borderBottomLeftRadius: "50px",
					}}
				></div>
			</div>

			<span
				style={{
					marginRight: "4px",
				}}
			></span>
		</div>
	);
}
