import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Checkbox, Col, Drawer, Form, Row, Spin } from "antd";
import PropTypes from "prop-types";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";

const Component = ({ data = {}, onUpdate, options: { roles = [] } }) => {
	const initialState = {};
	const [state, setState] = useState(initialState);
	const { loading, submitting, visible } = state;
	const { firstName, id, lastName } = data;
	const { hash, pathname, search } = useLocation();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));

					api.user
						.role(values, id)
						.then(() => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.ROLE}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true, loading: true }));

					api.user
						.select(id)
						.then((data) => {
							form.setFieldsValue(data);
						})
						.finally(() => {
							setState((prevState) => ({ ...prevState, loading: false }));
						});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={`${resources.edit} ${resources.role} "${firstName} ${lastName}"`}
			maskClosable={false}
			open={visible}
			width={414}
		>
			<Form
				layout="vertical"
				form={form}
			>
				{loading ? (
					<Spin className="sp-center-spin" />
				) : (
					<Form.Item
						name="roles"
						required
					>
						<Checkbox.Group>
							<Row gutter={[16, 8]}>
								{roles.map(({ label, value }) => (
									<Col
										xs={24}
										key={value}
									>
										<Checkbox value={value}>{label}</Checkbox>
									</Col>
								))}
							</Row>
						</Checkbox.Group>
					</Form.Item>
				)}
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
