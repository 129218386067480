import { Card, Spin } from "antd";
import { useState, useEffect } from "react";
// import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
// import { Calendar } from "@ijavad805/react-datepicker";

import dayjs from "dayjs";

import api from "../../scripts/api";
import resources from "scripts/resources";

import BarComponent from "../../components/StudentBarComponent";
import StCalendar from "../../components/calendar/StCalendar";

const OverviewCard = ({ cardTitle, BarData, style }) => {
	//console.log("bar data in line 9 is ", BarData);
	return (
		<Card className="stOverviewCard" title={cardTitle} style={style}>
			{BarData.map((bar, index) => {
				return (
					<div key={index}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: "8px",
								marginTop: "8px",
							}}
						>
							{bar.disciplinaryBarTitle}
						</div>
						<BarComponent
							amount={bar.disciplinaryBarAmount}
							maxAmountAtEachSide={bar.maxAmountAtEachSide}
						/>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: "8px",
								marginTop: "8px",
							}}
						>
							{bar.educationalBarTitle}
						</div>
						<BarComponent
							amount={bar.educationalBarAmount}
							maxAmountAtEachSide={bar.maxAmountAtEachSide}
						/>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: "8px",
								marginTop: "8px",
							}}
						>
							{bar.bankBarTitle}
						</div>
						<BarComponent
							amount={bar.bankBarAmount}
							maxAmountAtEachSide={bar.maxAmountAtEachSide}
						/>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: "8px",
								marginTop: "8px",
							}}
						>
							{bar.libraryBarTitle}
						</div>
						<BarComponent
							amount={bar.libraryBarAmount}
							maxAmountAtEachSide={bar.maxAmountAtEachSide}
						/>
					</div>
				);
			})}
		</Card>
	);
};

const Component = () => {
	// useJalaliLocaleListener();

	// If you want to all new instanses of dayjs use jalali calendar (no matter what is the locale),
	// you can set default calendar for dayjs and remove useJalaliLocaleListener hook.
	dayjs.calendar("jalali");

	const datee = dayjs("1399-01-01", { jalali: true });

	const initialState = {};

	const [data, setData] = useState([]);
	const [state, setState] = useState(initialState);
	const [loading, setLoading] = useState(true);

	let responseData = [];
	useEffect(() => {
		api.dashboard
			.list()
			.then(res => {
				//console.log("log line 61 dash is ", res.bankBar);

				responseData.push(res);
				setData(responseData);
				//console.log("log line 64 dash is ", responseData);
				setLoading(false);
			})
			.catch(e => {
				//console.log("e line 69 dash is ", e);
				setState(prevState => ({ ...prevState, submitting: false }));
				setLoading(false);
			});
	}, []);

	const studentMainDivClass = {
		marginTop: "16px",
	};
	let events = [
		{
			id: 1, // it should unique
			title: "Test",
			date: "2024-02-14",
			className: "test",
			dotColor: "#f1f1f1",
			disabled: false,
			icon: "$", // also you can use component
			style: {
				// what ever you want
			},
		},
		{
			id: 1, // it should unique
			title: "Test",
			date: { start: "2024-02-16", end: "2024-02-20" },
			className: "2222222",
			dotColor: "#f1f1f1",
			disabled: false,
			icon: "$", // also you can use component
			style: {
				// what ever you want
			},
		},
	];
	const RenderDashboard = () => {
		//console.log("log line 132 data", data);
		if (data == [] || !data) {
			return (
				<div>
					<p>کلاسی وارد نشده است!</p>
				</div>
			);
		}
		return (
			<div style={studentMainDivClass}>
				{data.map(el => {
					return (
						<>
							<OverviewCard
								// TODO => use data here
								BarData={[
									{
										maxAmountAtEachSide: 25,
										disciplinaryBarAmount: el.disciplinaryBar,
										disciplinaryBarTitle: `${resources.state} ${resources.discipline}`,
										educationalBarAmount: el.educationalBar,
										educationalBarTitle: `${resources.state} ${resources.education}${resources.y}`,
										bankBarAmount: el.bankBar,
										bankBarTitle: `${resources.state} ${resources.library}`,
										libraryBarAmount: el.libraryBar,
										libraryBarTitle: `${resources.state} ${resources.bank}`,
									},
								]}
								cardTitle={el.className}
							/>
						</>
					);
				})}
				<StCalendar />
			</div>
		);
	};

	return <div>{loading ? <Spin>Loading...</Spin> : <RenderDashboard />}</div>;
};

export default Component;
