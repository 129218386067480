import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, FloatButton, Modal, Table, Tabs, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/RoleForm";

const Component = ({ permissions, setRoute }) => {
	const initialState = { data: [], pagination: {}, permissionList: [] };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) =>
				(pagination.current - 1) * pagination.pageSize + index + 1,
		},
		{
			title: resources.title,
			dataIndex: "name",
		},
	];
	const [state, setState] = useState(initialState);
	const { data, loading, pagination, permissionList, selected } = state;
	const [modal, contextHolder] = Modal.useModal();
	const { pathname, search } = useLocation();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		setState(prevState => ({ ...prevState, loading: true }));

		api.role
			.list({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then(({ list: data, pagination }) => {
				setState(prevState => ({
					...prevState,
					data,
					pagination,
					loading: false,
				}));
			})
			.catch(() => {
				setState(prevState => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState(prevState => ({ ...prevState, selected }));

			navigate(
				`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`
			);
		}
	};

	const handleDelete = id => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState(prevState => ({ ...prevState, loading: true }));

				api.role
					.delete(id)
					.then(() => {
						setState(prevState => ({ ...prevState, loading: false }));

						fetchData();
					})
					.catch(() => {
						setState(prevState => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		fetchData();

		setRoute();

		api
			.permissions()
			.then(permissionList => {
				setState(prevState => ({ ...prevState, permissionList }));
			})
			.catch(() => {});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.remove || permissions.update) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={resources.add}
					icon={<PlusOutlined />}
					onClick={() => handleForm()}
				/>
			)}

			{(permissions.create || permissions.update) && (
				<FormModal
					data={selected}
					onUpdate={handleForm}
					options={{ permissions: permissionList }}
				/>
			)}

			{contextHolder}
		</>
	);
};

export default Component;
