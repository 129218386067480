export default function BarComponent({ amount, maxAmountAtEachSide }) {
	let rightColor;
	let leftColor;
	let rightAmount;
	let leftAmount;
	console.log(amount);
	if (amount >= 0 && amount < 8) {
		rightColor = "#00ff00";
		leftColor = "#ffffff";
		rightAmount = amount;
		leftAmount = 0;
	} else if (amount >= 8 && amount < 16) {
		rightColor = "#00ff00";
		leftColor = "#ffffff";
		rightAmount = amount;
		leftAmount = 0;
	} else if (amount >= 16 && amount < 25) {
		rightColor = "#0000ff";
		leftColor = "#ffffff";
		rightAmount = amount;
		leftAmount = 0;
	} else if (amount >= -8 && amount < 0) {
		rightColor = "#ffffff";
		leftColor = "#ffff00";
		rightAmount = 0;
		leftAmount = amount * -1;
	} else if (amount >= -25 && amount < -8) {
		rightColor = "#ffffff";
		leftColor = "#ff0000";
		rightAmount = 0;
		leftAmount = amount * -1;
	}

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				minWidth: "80px",
				height: "8px",
				borderRadius: "50px",
				alignItems: "center",
				border: "3px",
			}}
		>
			{/* REGION, right side */}
			<span
				style={{
					marginLeft: "4px",
					border: "3px",
				}}
			>
				{rightAmount}
			</span>
			<div
				style={{
					width: "50%",
					position: "relative",
					backgroundColor: "#f0f0f0",
					zIndex: 0,
					height: "inherit",
					borderTopRightRadius: "50px",
					borderBottomRightRadius: "50px",
					border: "1px solid ",
					borderRadius:'5px',
					padding:"4px"
				}}
			>
				<div
					style={{
						width: rightAmount < 25 ? `${(rightAmount / maxAmountAtEachSide) * 100}%` : "100%",
						position: "absolute",
						backgroundColor: rightColor ?? "#f0f0f0",
						zIndex: 2,
						height: "inherit",
						left: 0,
						top:"0",
						borderTopRightRadius: "50px",
						borderBottomRightRadius: "50px",
						borderRight: "1px solid",
						borderRadius:"5px",
						

						
					}}
				></div>
			</div>
			0{/* REGION, left side */}
			<div
				style={{
					width: "50%",
					position: "relative",
					backgroundColor: "#f0f0f0",
					zIndex: 0,
					height: "inherit",
					borderTopLeftRadius: "50px",
					borderBottomLeftRadius: "50px",
					display: "flex",
					alignItems: "center",
					border: "1px solid",
					borderRadius:'5px',
					padding:'4px'

				}}
			>
				<div
					style={{
						width: leftAmount < 25 ? `${(leftAmount / maxAmountAtEachSide) * 100}%` : "100%",
						position: "absolute",
						backgroundColor: leftColor ?? "#f0f0f0",
						zIndex: 2,
						height: "inherit",
						right: 0,
						borderTopLeftRadius: "50px",
						borderBottomLeftRadius: "50px",
						borderLeft:'1px solid',
						borderRadius:'5px'
					}}
				></div>
			</div>
			<span
				style={{
					marginRight: "4px",
					border: "3px",
				}}
			>
				{leftAmount}
			</span>
		</div>
	);
}
