import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import StudentCarousel from "components/StudentCarousel";

import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { Col, FloatButton, Modal, Row, Select, Table, Image, Tooltip, message, Alert, Button, Drawer, Form, Input } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import locale from "antd/locale/fa_IR";
// import "dayjs/locale/fa_IR";
// import UploadDocuments from "components/UploadDocuments";
import { BookOutlined, CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, CloseOutlined, AppstoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

import UploadImage from "components/UploadImage";
const Component = ({
	data = {},
	onUpdate,
	options: { classes = [] },
	fetchData,
	//   students
}) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {
		selected: [],
		student: [],
		cancel: false,
		browed: false,
		borrowTime: "",
		unBorrowTime: "",
	};
	const [state, setState] = useState(initialState);
	const { submitting, visible, cancel } = state;
	const { id } = data;
	const { classId, student, selected, browed, borrowTime, unBorrowTime } = state;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	var d = new Date(2018, 8, 18);

	const handleStudentFilter = (value) => {
		setState((prev) => ({
			...prev,
			classId: value,
		}));
	};
	const unborrowHandler = async () => {
		try {
			if (data.id) {
				await api.pixel.unborrow(data?.id);
				setState((prev) => ({
					...prev,
					cancel: true,
				}));
				// window.location.reload();
			}
		} catch (err) {
			console.log("ERROR-unBorrow", err);
		}
	};

	const borrowHandler = () => {
		console.log("before if");

		if (data.id && state.selected) {
			console.log("before");

			const params = {
				students: selected,
				borrowTime: borrowTime,
				unBorrowTime: unBorrowTime,
			};
			console.log("after ", params);

			api.pixel
				.borrow(data?.id, params)
				.then((res) => {
					setState((prev) => ({
						...prev,
						browed: true,
					}));
				})
				.catch((err) => console.log(err));
			// window.location.reload();
		}

		// api.pixel.borrow(data?.id, state?.selected)
	};
	const selectedStudentHandler = (value) => {
		setState((prev) => ({
			...prev,
			selected: value,
		}));
	};

	const borrowTimeHandler = (value) => {
		console.log("value day", value);

		const date = value.$D < 10 ? `0${value.$D}` : value.$D;
		console.log("date day", date);

		const month = value.$M < 10 ? `0${value.$M + 1}` : value.$M + 1;
		console.log("month month", month);
		const formattedDate = `${value.$y}-${month}-${date}`;

		setState((prev) => ({
			...prev,
			borrowTime: formattedDate,
		}));
	};

	const unBorrowTimeHandler = (value) => {
		const date = value.$D < 10 ? `0${value.$D}` : value.$D;

		const month = value.$M < 10 ? `0${value.$M + 1}` : value.$M + 1;
		const formattedDate = `${value.$y}-${month}-${date}`;

		setState((prev) => ({
			...prev,
			unBorrowTime: formattedDate,
		}));
	};

	const fetchStudent = async () => {
		try {
			const res = await api.student.related(classId);

			const transformedOptions = res.map(({ id, firstName, lastName }) => ({
				value: id,
				label: `${firstName} ${lastName}`,
			}));
			setState((prev) => ({
				...prev,
				student: transformedOptions,
			}));
		} catch (err) {
			console.log("Error fetching student data:", err);
		}
	};

	useEffect(() => {
		if (classId) {
			fetchStudent();
		}
	}, [classId]);
	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));
					if (values.file?.id) values.fileId = values.file.id ? values.file.id : "";
					//   values.id = data.id;
					delete values.file;
					// values.id = data.id;
					console.log("VALUEEEE", values);

					const action = hash === `#${Modals.EDIT}` ? api.pixel.update(values, id) : api.pixel.create(values);

					const params = values;
					action
						.then(() => {
							onUpdate(null, true);
							navigate(-1);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const disabledDateTime = () => ({
		disabledHours: () => range(0, 24).splice(4, 20),
		disabledMinutes: () => range(30, 60),
		disabledSeconds: () => [55, 56],
	});

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				setState((prevState) => ({ ...prevState, visible: true }));
				form.setFieldsValue({
					...data,
					title: data.title,
					description: data.description,
					file: data.fileUrl ? data.fileUrl : "",
				});

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);
	const onClose = () => {
		fetchData();
		navigate(-1);
	};
	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={onClose}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={onClose}
			title={`${hash === `#${Modals.EDIT}` ? resources.edit : resources.add} `}
			maskClosable={false}
			open={visible}
			width={500}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="title"
					label={`${resources.subject}`}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="description"
					label={`${resources.description} `}
					rules={rules.required}
				>
					<Input />
				</Form.Item>{" "}
				<Form.Item
					name="file"
					label={resources.file}
					initialValue={""}
				>
					<UploadImage />
				</Form.Item>
				{hash === `#${Modals.EDIT}` &&
					(data.borrowed ? (
						<>
							<Button
								onClick={unborrowHandler}
								loading={submitting}
							>
								{cancel ? "لغو شد" : `لغو امانت`}
							</Button>
						</>
					) : (
						<>
							<Col>
								<Form.Item name="class">
									<Select
										placeholder={resources.class}
										options={classes}
										onChange={handleStudentFilter}
										allowClear
									/>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item name="students">
									<Select
										placeholder={resources.students}
										mode="multiple"
										options={student}
										filterOption={false}
										showSearch
										onChange={selectedStudentHandler}
										allowClear
									/>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item
									name="borrowTime"
									label={resources.borrowTime}
									initialValue={""}
								>
									<DatePickerJalali
										format="YYYY-MM-DD"
										style={{ display: "block" }}
										onChange={borrowTimeHandler}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item
									name="unBorrowTime"
									label={resources.unBorrowTime}
									initialValue={""}
								>
									<DatePickerJalali
										format="YYYY-MM-DD"
										style={{ display: "block" }}
										onChange={unBorrowTimeHandler}
									/>
								</Form.Item>
							</Col>

							<Button
								onClick={borrowHandler}
								loading={submitting}
								disabled={browed ? true : false}
							>
								{browed ? "انجام شد" : `امانت`}
							</Button>
						</>
					))}
			</Form>

			{/* {resArray && (
				<Card>
					{data.map((el) => {
						return <p>{el}</p>;
					})}
				</Card>
			)} */}
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
