import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Button,
	Card,
	Drawer,
	Form,
	Input,
	Select,
	message,
	notification,
	Row,
} from "antd";
import PropTypes from "prop-types";

import { Modals, Types } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

import UploadDocuments from "components/UploadDocuments";

const Component = ({ data = [], onUpdate }) => {
	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible, resArray } = state;
	const { id, firstName, lastName } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then(async (values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));
					if (values.file) {
						let fileIds = await values.file.map((item) => {
							return item.id;
						});

						values.fileIds = fileIds;
					}
					console.log("value file ", values.fileIds);
					values.id = data.id;
					delete values.file;

					const params = values;
					api.stPanel
						.postDocuments(params)
						.then(response => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch(error => {
							setState(prevState => ({ ...prevState, submitting: false }));
							// if (error.status) {
							// 	//console.log("if 11111111 is : ", error.tatus);
							// 	notification.error({
							// 		message: error.name,
							// 		description: error.message,
							// 	});
							// } else {
							// 	//console.log("if 222222222 is : ", typeof error.status);
							// 	// notification.error({
							// 	// 	message: `Request Error: ${url}`,
							// 	// 	description: error.message,
							// 	// });
							// }
							return error;
							// //console.log(error);
							// message.error(error);
						});
				})
				.catch(() => {});
		}
	};
	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.FILE}`: {
				setState(prevState => ({ ...prevState, visible: true }));

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button type="primary" onClick={handleSubmit} loading={submitting}>
						{resources.confirm}
					</Button>
					<Button onClick={() => navigate(-1)} disabled={submitting}>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={hash === `#${Modals.FILE} ${resources.add} ${resources.file}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="file"
					label={resources.file}
				>
					<UploadDocuments />
				</Form.Item>
			</Form>
			<div>
				{resArray && (
					<Card className="remaining-file">
						{resArray.map((el, index) => (
							<Row key={index} className="bullet-item">
								<li className="remaining-students-file">{el}</li>
							</Row>
						))}
					</Card>
				)}
			</div>
			{/* {resArray && (
				<Card>
					{data.map((el) => {
						return <p>{el}</p>;
					})}
				</Card>
			)} */}
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
};

export default Component;
