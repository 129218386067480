import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
	Button,
	Col,
	Input,
	FloatButton,
	Form,
	Modal,
	Row,
	Alert,
	Table,
	Image,
	Tooltip,
	Checkbox,
} from "antd";
import {
	DatePicker as DatePickerJalali,
	Calendar as CalendarJalali,
	useJalaliLocaleListener,
} from "antd-jalali";
import {
	BookOutlined,
	CheckOutlined,
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	CloseOutlined,
	AppstoreOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/student/DocumentForm";
import StudentCarousel from "components/StudentCarousel";
import DisAddToClassForm from "modals/DisAddToClassForm";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {
		assessments: [],
		classes: [],
		data: [],
		regulations: [],
		students: [],
	};
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => index + 1,
		},
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: value => {
				const [date, time] = moment(value)
					.format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
					.split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: `${resources.title} ${resources.documents}`,
			dataIndex: "title",
			className: "center-cell",
			render: value => `${resources[value]}`,
		},
		{
			title: `${resources.state}`,
			dataIndex: "state",
			className: "center-cell",
			render: value => {
				if (value == "Required") {
					return <Alert message={`${resources.Required}`} type="warning" />;
				} else if (value == "Awaiting") {
					return <Alert message={`${resources.Awaiting}`} type="warning" />;
				} else if (value == "Completed") {
					return <Alert message={`${resources.Completed}`} type="success" />;
				} else {
					return <Alert message={`${resources.Rejected}`} type="error" />;
				}
			},
		},
		{
			title: `${resources.image}`,
			dataIndex: "uris",
			className: "center-cell",
			render: (value) =>
				value.length == 0
					? `-`
					: value.map((item) => {
							return (
								<Image
									src={`${ENV.basePath}${item}`}
									// shape="square"
									width={40}
									height={40}
								/>
							);
					  }),
		},
		{
			title: `${resources.upload}`,
			dataIndex: "state",
			className: "center-cell",
			render: (value, record) => {
				return value == "Required" || value == "Rejected" ? (
					value == "MotherBirthCertificate" || value == "FatehrBirthCertificate" || value == "studentBirthCertificate" ? (
						<Button
							type="primary"
							onClick={() => handleForm(record, (multiUpload = true))}
						>
							آپلود مدرک
						</Button>
					) : (
						<Button
							type="primary"
							onClick={() => handleForm(record)}
						>
							آپلود مدرک
						</Button>
					)
				) : (
					<Button type="primary" disabled={true}>
						آپلود مدرک
					</Button>
				);
			},
		},
	];
	const [state, setState] = useState(initialState);
	const { assessments, classes, data, loading, documents, selected } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue({
			...params,
			date: params.date ? dayjs(params.date) : undefined,
		});
		api.stPanel
			.getDocuments()
			.then(({ list }) => {
				setState(prevState => ({ ...prevState, data: list, loading: false }));
			})
			.catch(() => {
				setState(prevState => ({ ...prevState, loading: false }));
			});
		if (params.student) {
			setState(prevState => ({ ...prevState, loading: true }));

			// api.stPanel
			// 	.getDocuments(params)
			// 	.then((data) => {
			// 		setState((prevState) => ({ ...prevState, data, documents: data, loading: false }));
			// 	})
			// 	.catch(() => {
			// 		setState((prevState) => ({ ...prevState, loading: false }));
			// 	});
		} else {
			setState(prevState => ({ ...prevState, data: [] }));
		}
	};

	const handleForm = (selected, reload, multiUpload) => {
		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));
			if (multiUpload) {
				navigate(`${pathname}${search}#${selected && Modals.FILE}`);
			}
			navigate(`${pathname}${search}#${selected && Modals.FILE}`);
		}
	};

	const handleRefer = id => {
		modal.confirm({
			title: resources.referConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState(prevState => ({ ...prevState, loading: true }));

				api.disReport
					.refer(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState(prevState => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleDelete = id => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState(prevState => ({ ...prevState, loading: true }));

				api.disReport
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState(prevState => ({ ...prevState, loading: false }));
					});
			},
		});
	};
	const componentDidUpdate = () => {
		// fetchData();
	};

	const componentDidMount = () => {
		setRoute();
		fetchData();
		api.stPanel
			.getDocuments()
			.then(({ list }) => {
				setState(prevState => ({ ...prevState, data: list, loading: false }));
			})
			.catch(() => {
				setState(prevState => ({ ...prevState, loading: false }));
			});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.refer || permissions.update || permissions.remove) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.refer && (
						<Tooltip
							title={`${resources.refer}${
								record.isReferred ? ` ${resources.done}` : ""
							}`}
						>
							<Button
								type="link"
								icon={<BookOutlined />}
								onClick={record.isReferred ? null : () => handleRefer(value)}
								disabled={record.isReferred}
							/>
						</Tooltip>
					)}

					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				pagination={false}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"

				// expandable={{ expandedRowRender: (record) => `${resources.description}: ${record.description}` }}
			/>
			<FormModal data={selected} onUpdate={handleForm} />
			{contextHolder}
		</>
	);
};

export default Component;
