import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Col, Input, FloatButton, Form, Modal, Row, Select, Table, message, Tooltip, Image } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { BookOutlined, CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, CloseOutlined, AppstoreOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/FinancialForm";
import StudentCarousel from "components/StudentCarousel";
import EduAddToClassForm from "modals/EduAddToClassForm";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = { assessments: [], classes: [], data: [], lessons: [], regulations: [], students: [] };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => index + 1,
		},
		// {
		// 	title: resources.creditor,
		// 	dataIndex: "credit",
		// 	className: "center-cell",
		// },
		// {
		// 	title: resources.debtor,
		// 	dataIndex: "debt",
		// 	className: "center-cell",
		// },
		// {
		// 	title: `${resources.remaining}`,
		// 	dataIndex: "remain",
		// 	className: "center-cell",
		// },
		{
			title: `${resources.date}`,
			dataIndex: "timeout",
			className: "center-cell",
			render: (value) => {
				if (value) {
					const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");
					return (
						<>
							<span className="name">{date}</span>
						</>
					);
				} else {
					return (
						<>
							<span className="name">-</span>
						</>
					);
				}
			},
		},
		{
			title: ` ${resources.image}`,
			key: "uri",
			render: (value, record) => {
				return (
					<>
						{record.uri.length > 0 ? (
							record.uri.map((image, key) => {
								return(
								<Image
									src={`${ENV.basePath}${image}`}
									// shape="square"
									width={40}
									height={40}
								/>)
							})
						) : (
							<span>-</span>
						)}
					</>
				);
			},
		},
	];
	const [state, setState] = useState(initialState);
	const { classes, data, loading, selected, students, selectStudent, selectedStudent, selectedStudentId, buttonclicked } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue({ ...params, date: params.date ? dayjs(params.date) : undefined });
		params.type = "School";
		delete params.class;

		if (params.student) {
			setState((prevState) => ({ ...prevState, selectedStudentId: params.student, loading: true }));
			api.financial
				.getAdmin(params)
				.then((data) => {
					if (data.length === 0) {
						setState((prevState) => ({ ...prevState, data, loading: false }));
					} else {
						let arrayData = [];
						arrayData.push(data);
						setState((prevState) => ({ ...prevState, data: arrayData, loading: false }));
					}
				})
				.catch(() => {
					setState((prevState) => ({ ...prevState, loading: false }));
				});
		} else {
			setState((prevState) => ({ ...prevState, data: [] }));
		}
	};

	const handleStudentFilter = debounce(() => {
		form
			.validateFields()
			.then((values) => {
				const params = GetUrlParams(search);
				const _params = params.search === values.search ? params : {};

				const _search = ObjectToURL({ ..._params, ...values });

				navigate(`${pathname}?${_search}`);
			})
			.then(() => {
				const params = GetUrlParams();
				const classId = params.class;
				const searchText = params.search;
				if (params.search && params.class) {
					let searchText = params.search;

					api.student
						.related(params.class, searchText)
						.then((students) => {
							setState((prevState) => ({ ...prevState, students, classId: params.class, loading: false }));
							// if (onFetch) onFetch(data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
						})
						.catch(() => {});
				} else if (params.class) {
					api.student
						.related(params.class)
						.then((students) => {
							setState((prevState) => ({ ...prevState, students, classId: params.class, loading: false }));
							let selectStudent = students.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` }));
							setState((prevState) => ({ ...prevState, selectStudent }));
							// if (onFetch) onFetch(data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
						})
						.catch(() => {});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}

				if (params.search) {
					api.student
						.related(classId, searchText)
						.then((students) => {})
						.catch(() => {
							setState((prevState) => ({ ...prevState, loading: false }));
						});
				} else {
					setState((prevState) => ({ ...prevState, data: [] }));
				}
			});
	}, 1000);

	// };
	const handleStudents = (onestudent) => {
		setState((prevState) => ({ ...prevState, selectedStudent: onestudent }));
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			// if (selected) {
			// 	delete selected.timeout;
			// }
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`);
		}
	};

	const handleRefer = (id) => {
		modal.confirm({
			title: resources.referConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.eduReport
					.refer(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.financial
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const params = GetUrlParams(search);

			const _params = params.class === values.class ? params : {};

			const _search = ObjectToURL({ ..._params, ...values });

			navigate(`${pathname}?${_search}`);
		});
	}, 1000);

	const handleCloseProfile = () => {
		api.financial.closeProfile(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({ ...prevState, buttonclicked: !buttonclicked, loading: false }));
			handleStudentFilter();
		});
	};

	const handleOpenProfile = () => {
		api.financial.openProfile(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);
			setState((prevState) => ({ ...prevState, buttonclicked: !buttonclicked, loading: false }));
			handleStudentFilter();
		});
	};

	const handleAddWarning = () => {
		api.financial.warning(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({ ...prevState, buttonclicked: !buttonclicked, loading: false }));
			handleStudentFilter();
		});
	};

	const handleRemoveWarning = () => {
		api.financial.removewarning(selectedStudentId).then((response) => {
			message.success(`${resources.message.succses}`);

			setState((prevState) => ({ ...prevState, buttonclicked: !buttonclicked, loading: false }));
			handleStudentFilter();
		});
	};

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.class
			.related()
			.then((classes) => {
				setState((prevState) => ({ ...prevState, classes }));
			})
			.catch(() => {});
	};

	useEffect(componentDidUpdate, [search, students]);
	useEffect(componentDidMount, []);

	if (permissions.refer || permissions.update || permissions.remove) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.refer && (
						<Tooltip title={`${resources.refer}${record.isReferred ? ` ${resources.done}` : ""}`}>
							<Button
								type="link"
								icon={<BookOutlined />}
								onClick={record.isReferred ? null : () => handleRefer(value)}
								disabled={record.isReferred}
							/>
						</Tooltip>
					)}

					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="class">
							<Select
								placeholder={resources.class}
								options={classes}
								onChange={handleStudentFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="search">
							<Input
								suffix={<SearchOutlined />}
								onChange={handleStudentFilter}
								placeholder={`${resources.search} ${resources.student}`}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="date">
							<DatePickerJalali
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			{permissions.suspend && (
				<Row
					gutter={24}
					// className="row-top-table"
				>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
						className="row-top-table"
					>
						<Col>
							{selectedStudent && selectedStudent && !selectedStudent.salSuspend && (
								<Button
									type="primary"
									className="open-button"
									onClick={handleOpenProfile}
									style={{ display: "block" }}
								>
									{`${resources.open} ${resources.profile}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && selectedStudent && selectedStudent.salSuspend && (
								<Button
									className="close-button"
									onClick={handleCloseProfile}
									style={{ display: "block" }}
								>
									{`${resources.close} ${resources.profile}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && selectedStudent && selectedStudent.salWarning && (
								<Button
									className="warning-button"
									onClick={handleAddWarning}
									style={{ display: "block" }}
								>
									{`${resources.warning} ${resources.financial}`}
								</Button>
							)}
						</Col>
						<Col>
							{selectedStudent && selectedStudent && !selectedStudent.salWarning && (
								<Button
									className="warning-button"
									onClick={handleRemoveWarning}
									style={{ display: "block" }}
								>
									{`${resources.delete} ${resources.warning} ${resources.financial}`}
								</Button>
							)}
						</Col>
					</Col>
				</Row>
			)}
			<StudentCarousel
				students={students}
				onFetch={handleStudents}
			/>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				// onChange={handleTable}
				pagination={false}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={resources.add}
					icon={<PlusOutlined />}
					onClick={() => handleForm()}
				/>
			)}

			{(permissions.create || permissions.update) && (
				<FormModal
					data={selected}
					onUpdate={handleForm}
					options={{ type: "School", selectStudent }}
				/>
			)}

			{contextHolder}
		</>
	);
};

export default Component;
