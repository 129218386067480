import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  FloatButton,
  Form,
  Modal,
  Row,
  Select,
  Table,
  Image,
  Tooltip,
  Card,
} from "antd";
import {
  DatePicker as DatePickerJalali,
  Calendar as CalendarJalali,
  useJalaliLocaleListener,
} from "antd-jalali";
import {
  BookOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  CloseOutlined,
  AppstoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import LibraryForm from "modals/LibraryForm";
import StudentCarousel from "components/StudentCarousel";
import LibraryAddForm from "modals/LibraryAddForm";

const Component = ({ permissions, setRoute }) => {
  useJalaliLocaleListener();
  dayjs.calendar("jalali");

  const initialState = {
    assessments: [],
    classes: [],
    data: [],
    lessons: [],
    regulations: [],
    students: [],
    EducationalYear: [],
  };
  const tableColumns = [
    {
      title: resources.row,
      dataIndex: "index",
      className: "center-cell",
      width: "60px",

      render: (value, record, index) => index + 1,
    },
    // Table.EXPAND_COLUMN,
    {
      title: resources.name,
      dataIndex: "name",
      className: "center-cell",
      width: "100px",

      render: (value) => (value ? value : "-"),
    },
    {
      title: resources.start,
      dataIndex: "start",
      className: "center-cell",
      width: "100px",
      render: (value) => {
        const [date, time] = moment(value)
          .format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
          .split(" ");

        return (
          <>
            <span className="name">{date}</span>
          </>
        );
      },
    },
    {
      title: resources.end,
      dataIndex: "end",
      className: "center-cell",
      width: "100px",
      render: (value) => {
        const [date, time] = moment(value)
          .format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
          .split(" ");

        return (
          <>
            <span className="name">{date}</span>
          </>
        );
      },
    },
  ];
  const [state, setState] = useState(initialState);
  const {
    classId,
    assessments,
    classes,
    data,
    lessons,
    loading,
    regulations,
    selected,
    students,
    selectStudent,
    selectedStudent,
    EducationalYear,
  } = state;
  const { pathname, search } = useLocation();
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const fetchData = () => {
    AddNotification();
    const params = GetUrlParams(search);

	
    form.resetFields();
    form.setFieldsValue({
      ...params,
      Start: params.Start ? dayjs(params.Start) : undefined,
      End: params.End ? dayjs(params.End) : undefined,
    });

    if (params.student) {
      setState((prevState) => ({ ...prevState, loading: true }));
	  delete params.class;
	  params.StudentId = params.student;
	  delete params.student;

      api.library
        .list(params)
        .then((data) => {
          setState((prevState) => ({
            ...prevState,
            data: data?.list,
            loading: false,
          }));
          // console.log("DDDDDD", data);
        })
        .catch(() => {
          setState((prevState) => ({ ...prevState, loading: false }));
        });
    } else {
      setState((prevState) => ({ ...prevState, data: [] }));
    }
  };

  const handleStudentFilter = debounce(() => {
    form
      .validateFields()
      .then((values) => {
        const params = GetUrlParams(search);
        const _params = params.search === values.search ? params : {};

        const _search = ObjectToURL({ ..._params, ...values });

        navigate(`${pathname}?${_search}`);
      })
      .then(() => {
        const params = GetUrlParams();
        const classId = params.class;
        const searchText = params.search;
        if (params.search && params.class) {
          let searchText = params.search;

          api.student
            .related(params.class, searchText)
            .then((students) => {
              setState((prevState) => ({
                ...prevState,
                students,
                classId: params.class,
                loading: false,
              }));
              // if (onFetch) onFetch(data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
            })
            .catch(() => {});
        } else if (params.class) {
          api.student
            .related(params.class)
            .then((students) => {
              setState((prevState) => ({
                ...prevState,
                students,
                classId: params.class,
                loading: false,
              }));
              let selectStudent = students.map(
                ({ id, firstName, lastName }) => ({
                  value: id,
                  label: `${firstName} ${lastName}`,
                })
              );
              setState((prevState) => ({ ...prevState, selectStudent }));

              // if (onFetch) onFetch(data.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })));
            })
            .catch(() => {});
        } else {
          setState((prevState) => ({ ...prevState, data: [] }));
        }

        if (params.search) {
          api.student
            .related(classId, searchText)
            .then((students) => {})
            .catch(() => {
              setState((prevState) => ({ ...prevState, loading: false }));
            });
        } else {
          setState((prevState) => ({ ...prevState, data: [] }));
        }
      });
  }, 1000);

  // };
  const handleStudents = (selectedStudent) => {
    setState((prevState) => ({ ...prevState, selectedStudent }));
    // console.log("log student 163 ", students);
  };

  const handleForm = (selected, reload) => {
	
    if (reload) {
      fetchData();
    } else {
      setState((prevState) => ({ ...prevState, selected }));

      navigate(
        `${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`
      );
    }
  };

  const handleAddToClassModalModal = (selected, reload) => {
    if (reload) {
      fetchData();
    } else {
      navigate(`${pathname}${search}#${Modals.ADDTOCLASS}`);
    }
  };

  const handleRefer = (id) => {
    modal.confirm({
      title: resources.referConfirm,
      okText: resources.yes,
      okType: "danger",
      cancelText: resources.no,
      onOk: () => {
        setState((prevState) => ({ ...prevState, loading: true }));

        api.eduReport
          .refer(id)
          .then(() => {
            fetchData();
          })
          .catch(() => {
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      },
    });
  };

  const handleDelete = (id) => {
    modal.confirm({
      title: resources.deleteConfirm,
      okText: resources.yes,
      okType: "danger",
      cancelText: resources.no,
      onOk: () => {
        setState((prevState) => ({ ...prevState, loading: true }));

        api.library
          .delete(id)
          .then(() => {
            fetchData();
          })
          .catch(() => {
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      },
    });
  };


  const renewHandler = (id) => {
	modal.confirm({
		title: resources.renewText,
		okText: resources.yes,
		okType: "danger",
		cancelText: resources.no,
		onOk: () => {
		  setState((prevState) => ({ ...prevState, loading: true }));
  
		  api.library
			.renew(id)
			.then(() => {
			  fetchData();
			})
			.catch(() => {
			  setState((prevState) => ({ ...prevState, loading: false }));
			});
		},
	  });
  }
  // const handleTable = ({ current }) => {
  // 	const params = GetUrlParams(search);
  // 	const query = ObjectToURL({ ...params, page: current });

  // 	navigate(`${pathname}?${query}`);
  // };

  const handleFilter = debounce(() => {
    form.validateFields().then((values) => {
      const params = GetUrlParams(search);
      if (values.Start) {
        let months = values.Start.$M + 1;
        let start = `${values.Start.$y}-${months}-${values.Start.$D}`;
        values.Start = start;
      }
      if (values.End) {
        let months = values.End.$M + 1;
        let end = `${values.End.$y}-${months}-${values.End.$D}`;
        values.End = end;
      }
      const _params = params.class === values.class ? params : {};

      const _search = ObjectToURL({ ..._params, ...values });

      navigate(`${pathname}?${_search}`);
    });
  }, 1000);

  const componentDidUpdate = () => {
    fetchData();
  };

  const componentDidMount = () => {
    setRoute();

    api.class
      .related()
      .then((classes) => {
        setState((prevState) => ({ ...prevState, classes }));
      })
      .catch(() => {});

    api
      .lessons()
      .then((lessons) => {
        setState((prevState) => ({ ...prevState, lessons }));
      })
      .catch(() => {});

    api.eduRegulation
      .list({ paging: false })
      .then((data) => {
        setState((prevState) => ({
          ...prevState,
          regulations: data.map(({ id, title }) => ({
            value: id,
            label: title,
          })),
        }));
      })
      .catch(() => {});

    api.assessment
      .list({ paging: false })
      .then((data) => {
        setState((prevState) => ({
          ...prevState,
          assessments: data.map(({ id, title }) => ({
            value: id,
            label: title,
          })),
        }));
      })
      .catch(() => {});

    api.educationalYear
      .list()
      .then((EducationalYear) => {
        setState((prevState) => ({ ...prevState, EducationalYear }));
      })
      .catch(() => {});
  };

  const expandedRowRender = (record) => {
    let img = `${ENV.basePath}${record.image}`;

    const columns = [
      {
        title: resources.description,
        dataIndex: "description",
        key: "description",
      },
      {
        title: `${resources.description} ${resources.parent}`,
        dataIndex: "parentDescription",
        key: "parentDescription",
      },
     
    ];
    const data = [];
    data.push({
      description: record.description,
      parentDescription: record.parentDescription,
      parentImage: record.parentImage,
    });

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  useEffect(componentDidUpdate, [search, students]);
  useEffect(componentDidMount, []);
	const types = [{label:"کتاب", value:"Book"},{label:"بازی فکری", value:"Toy"}]

  if (permissions.refer || permissions.update || permissions.remove) {
    tableColumns.push({
      title: resources.action,
      dataIndex: "id",
      className: "center-cell",
      width: "120px",
      render: (value, record) => (
        <>
		
          {permissions.refer && (
            <Tooltip
              title={`${resources.refer}${
                record.isReferred ? ` ${resources.done}` : ""
              }`}
            >
              <Button
                type="link"
                icon={<BookOutlined />}
                onClick={record.isReferred ? null : () => handleRefer(value)}
                disabled={record.isReferred}
              />
            </Tooltip>
          )}

          {permissions.update && (
			  <Tooltip title={resources.edit}>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleForm(record)}
              />
            </Tooltip>
          )}
          {permissions.remove && (
            <Tooltip title={resources.delete}>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(value)}
              />
            </Tooltip>

          )}
        <Tooltip title={resources.renew}>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => renewHandler(value)}
              />
            </Tooltip>


        </>
      ),
    });
  }



  return (
    <>
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="class">
              <Select
                placeholder={resources.class}
                options={classes}
                onChange={handleStudentFilter}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="Start">
              <DatePickerJalali
                onChange={handleFilter}
                style={{ display: "block" }}
                placeholder={`${resources.date} ${resources.start}`}
              />
            </Form.Item>
          </Col>{" "}
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="End">
              <DatePickerJalali
                onChange={handleFilter}
                style={{ display: "block" }}
                placeholder={`${resources.date} ${resources.end}`}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <StudentCarousel students={students} onFetch={handleStudents} />

      <Table
        columns={tableColumns}
        dataSource={data}
        loading={loading}
        // onChange={handleTable}
        pagination={false}
        scroll={{ x: 930 }}
        size="small"
        rowKey="id"
  
      />

      {permissions.create && (
        <FloatButton
          type="primary"
          tooltip={resources.add}
          icon={<PlusOutlined />}
          onClick={() => handleForm()}
        />
      )}



      {(permissions.create || permissions.update) && (
        <LibraryForm
        types={types}
          data={selected}
          onUpdate={handleForm}
          options={{ assessments, lessons, regulations, selectStudent, classes }}
        />
      )}

      {/* {permissions.create && (
        <LibraryAddForm
     
          data={classes}
          onUpdate={handleAddToClassModalModal}
          options={{
            classes,
            assessments,
            lessons,
            regulations,
            selectStudent,
          }}
        />
      )} */}

      {contextHolder}
    </>
  );
};

export default Component;
