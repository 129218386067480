import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Card,
  Col,
  Form,
  Row,
  Select,
  Table,
  DatePicker,
  Image,
  Input,
  Button,
  Checkbox,
} from "antd";
import {
  DatePicker as DatePickerJalali,
  Calendar as CalendarJalali,
  useJalaliLocaleListener,
} from "antd-jalali";
import { SearchOutlined } from "@ant-design/icons";

import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import StudentCarousel from "components/StudentCarousel";
import BarComponent from "components/BarComponent";

const { RangePicker } = DatePicker;

const Component = ({ setRoute }) => {
  useJalaliLocaleListener();
  dayjs.calendar("jalali");
  const initialState = {
    classes: [],
    data: [],
    pagination: {},
    students: [],
    EducationalYear: [],
  };
  const tableColumns = [
    {
      title: resources.row,
      dataIndex: "index",
      className: "center-cell",
      width: "60px",
      render: (value, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    Table.EXPAND_COLUMN,
    {
      title: resources.date,
      dataIndex: "date",
      className: "center-cell desc-cell ltr-cell",
      width: "100px",
      render: (value) => {
        const [date, time] = moment(value)
          .format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
          .split(" ");

        return (
          <>
            <span className="name">{date}</span>
          </>
        );
      },
    },
    {
      title: resources.title,
      dataIndex: "title",
    },
    {
      title: resources.assessment,
      dataIndex: "assessment",
      className: "center-cell",
    },
    // {
    //   title: `${resources.Excused}`,
    //   dataIndex: "isExcused",
    //   className: "center-cell",
    //   editable: true,
    //   render: (value, record) => (
    //     <>
    //       {record.isExcused}
    //       <Checkbox
    //         title={resources.edit}
    //         checked={value}
    //         onClick={() => handleCheckbox(value, record)}
    //       ></Checkbox>
    //     </>
    //   ),
    // },
  ];
  const [state, setState] = useState(initialState);
  const {
    classes,
    data,
    barState,
    fileState,
    loading,
    pagination,
    students,
    selectedStudent,
    EducationalYear,
  } = state;
  const { pathname, search } = useLocation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const fetchData = () => {
    AddNotification();
    const params = GetUrlParams(search);

    form.resetFields();
    form.setFieldsValue({
      ...params,
      startDate: params.startDate ? dayjs(params.startDate) : undefined,
      endDate: params.endDate ? dayjs(params.endDate) : undefined,
    });

    if (params.student) {
      setState((prevState) => ({ ...prevState, loading: true }));

      api
        .disGeneralReports({
          ...params,
          pageSize: process.env.REACT_APP_PAGE_SIZE,
        })
        .then(
          ({ list: data, state: barState, file: fileState, pagination }) => {
            setState((prevState) => ({
              ...prevState,
              data,
              barState,
              fileState,
              pagination,
              loading: false,
            }));
          }
        )
        .catch(() => {
          setState((prevState) => ({ ...prevState, loading: false }));
        });
    } else {
      setState((prevState) => ({ ...prevState, data: [] }));
    }
  };
  const handleStudentFilter = debounce(() => {
    form
      .validateFields()
      .then((values) => {
        const params = GetUrlParams(search);
        const _params = params.search === values.search ? params : {};

        const _search = ObjectToURL({ ..._params, ...values });

        navigate(`${pathname}?${_search}`);
      })
      .then(() => {
        const params = GetUrlParams();
        const classId = params.class;
        const searchText = params.search;
        if (params.search && params.class) {
          let searchText = params.search;

          api.student
            .related(params.class, searchText)
            .then((students) => {
              setState((prevState) => ({
                ...prevState,
                students,
                classId: params.class,
                loading: false,
              }));
              if (onFetch)
                onFetch(
                  data.map(({ id, firstName, lastName }) => ({
                    value: id,
                    label: `${firstName} ${lastName}`,
                  }))
                );
            })
            .catch(() => {});
        } else if (params.class) {
          api.student
            .related(params.class)
            .then((students) => {
              setState((prevState) => ({
                ...prevState,
                students,
                classId: params.class,
                loading: false,
              }));

              if (onFetch)
                onFetch(
                  data.map(({ id, firstName, lastName }) => ({
                    value: id,
                    label: `${firstName} ${lastName}`,
                  }))
                );
            })
            .catch(() => {});
        } else {
          setState((prevState) => ({ ...prevState, data: [] }));
        }

        if (params.search) {
          api.student
            .related(classId, searchText)
            .then((students) => {})
            .catch(() => {
              setState((prevState) => ({ ...prevState, loading: false }));
            });
        } else {
          setState((prevState) => ({ ...prevState, data: [] }));
        }
      });
  }, 1000);

  //



  const handleStudents = (selectedStudent) => {
    setState((prevState) => ({ ...prevState, selectedStudent }));
  };

  const handleTable = ({ current }) => {
    const params = GetUrlParams(search);
    const query = ObjectToURL({ ...params, page: current });

    navigate(`${pathname}?${query}`);
  };

  const handleFilter = debounce(() => {
    form.validateFields().then((values) => {
      const params = GetUrlParams(search);

      if (values.startDate) {
        let months = values.startDate.$M + 1;
        let start = `${values.startDate.$y}-${months}-${values.startDate.$D}`;
        values.startDate = start;
      }
      if (values.endDate) {
        let months = values.endDate.$M + 1;
        let end = `${values.endDate.$y}-${months}-${values.endDate.$D}`;
        values.endDate = end;
      }

      const _params = params.class === values.class ? params : {};

      const _search = ObjectToURL({ page: 1, ..._params, ...values });

      navigate(`${pathname}?${_search}`);
    });
  }, 1000);

  const handleCheckbox = (value, record) => {
    let isExcused = value ? false : true;
    let recordId = record.id;

    api.disReport
      .makeExcuse(record.id)
      .then(() => {
        fetchData();
      })
      .catch(() => {});
  };
  const componentDidUpdate = () => {
    fetchData();
  };

  const componentDidMount = () => {
    setRoute();

    api.class
      .related()
      .then((classes) => {
        setState((prevState) => ({ ...prevState, classes }));
      })
      .catch(() => {});

    api.educationalYear
      .list()
      .then((EducationalYear) => {
        setState((prevState) => ({ ...prevState, EducationalYear }));
      })
      .catch(() => {});
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: resources.description,
        dataIndex: "description",
        key: "description",
      },
      {
        title: `${resources.description} ${resources.parent}`,
        dataIndex: "parentDescription",
        key: "parentDescription",
      },
      {
        title: ` ${resources.image} ${resources.description} ${resources.parent}`,
        key: "parentImage",
        render: (value) => (
          <>
            {value.parentImage ? (
              <Image
                src={`${ENV.basePath}${value.parentImage}`}
                // shape="square"
                width={40}
                height={40}
              />
            ) : (
              <>-</>
            )}
          </>
        ),
      },
    ];
    const data = [];
    data.push({
      description: record.description,
      parentDescription: record.parentDescription,
      parentImage: record.parentImage,
    });

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
  useEffect(componentDidUpdate, [search]);
  useEffect(componentDidMount, []);


  return (
    <>
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="class">
              <Select
                placeholder={resources.class}
                options={classes}
                onChange={handleStudentFilter}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="search">
              <Input
                suffix={<SearchOutlined />}
                onChange={handleStudentFilter}
                placeholder={`${resources.search} ${resources.student}`}
                allowClear
              />
            </Form.Item>
          </Col>
          {/*  */}
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="EducationalYear">
              <Select
                placeholder={`${resources.academicYear}`}
                options={EducationalYear}
                onChange={handleStudentFilter}
                allowClear
              />
            </Form.Item>
          </Col>
          {/*  */}
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="startDate">
              <DatePickerJalali
                placeholder={`${resources.date} ${resources.start}`}
                onChange={handleFilter}
                style={{ display: "block" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="endDate">
              <DatePickerJalali
                placeholder={`${resources.date} ${resources.end}`}
                onChange={handleFilter}
                style={{ display: "block" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <StudentCarousel students={students} onFetch={handleStudents} />
      {barState && (
        <Row className="bar-row">
          <CSVLink
            data={data}
            filename={`QR-${dayjs()}.csv`}
            headers={[
              // { label: "id", key: "id" },
              { label: "تاریخ", key: "date" },
              { label: "سرنویس", key: "title" },
              { label: "ارزیابی", key: "assessment" },
              { label: "توضیحات", key: "description" },
              // { label: "Scanned", key: "effectOnBar" },
              // { label: "Status", key: "parentDescription" },
              // { label: "Date", key: "isExcused" },
              // { label: "Date", key: "needsParentDescription" },
            ]}
            target="_blank"
            style={{ marginTop: "30px", backgroundColor: "#c0d7bc" }}
          >
            دانلود به صورت فایل
          </CSVLink>
        </Row>
      )}
      {barState && (
        <Card className="bar-card">
          <Row className="bar-row">
            <Col xs={24} sm={12} md={8} lg={6} xxl={4} className="bar-col">
              {resources.totalAbsences}
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4} className="bar-col">
              {resources.totalDelay}
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4} className="bar-col">
              {resources.totalAccelerations}
            </Col>
          </Row>
          <Row className="bar-row">
            <Col xs={24} sm={12} md={8} lg={6} xxl={4} className="bar-col">
              <p>{barState.absence}</p>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4} className="bar-col">
              <p>{barState.beingLate}</p>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xxl={4} className="bar-col">
              <p>{barState.hurry}</p>
            </Col>
          </Row>
          <BarComponent
            // leftAmount={bar.leftAmount}
            // leftColor={bar.leftColor}
            amount={barState.bar}
            maxAmountAtEachSide="25"
            // rightAmount={bar.rightAmount}
            // rightColor={bar.rightColor}
          />
          <Row className="bar-row">
            <Button
              type="primary"
              href={`${ENV.basePath}${fileState}`}
              target="_blank"
              style={{ marginTop: "30px" }}
            >
              دانلود به صورت فایل
            </Button>
          </Row>
        </Card>
      )}
      <Table
        columns={tableColumns}
        dataSource={data}
        loading={loading}
        onChange={handleTable}
        pagination={pagination}
        scroll={{ x: 930 }}
        size="small"
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
        }}
      />
      {/* expandable={{ expandedRowRender: (record) => `${resources.description}: ${record.description}` }} */}
    </>
  );
};

export default Component;
