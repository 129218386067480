import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, DatePicker, Drawer, Form, Input, Select } from "antd";
import {
  DatePicker as DatePickerJalali,
  Calendar as CalendarJalali,
  useJalaliLocaleListener,
} from "antd-jalali";

import api from "scripts/api";
import { Modals, Types } from "scripts/settings";
import resources from "scripts/resources";
import dayjs from "dayjs";
import rules from "scripts/rules";
import moment from "moment-jalaali";

const eventTypeOptions = [
  {
    value: Types.event.ceremony,
    label: resources.ceremony,
  },
  {
    value: Types.event.vacation,
    label: resources.vacation,
  },
  {
    value: Types.event.camping,
    label: resources.camping,
  },
  {
    value: Types.event.games,
    label: resources.games,
  },
  {
    value: Types.event.pollution,
    label: resources.pollution,
  },
];

const Component = function ({ data = "", onUpdate }) {
  const initialState = {};
  const [state, setState] = useState(initialState);
  const { submitting, visible } = state;
  const navigate = useNavigate();
  const { hash, search, pathname } = useLocation();
  const [form] = Form.useForm();

  const formattedDate = (date) => {
    date.$D = date.$D < 10 ? `0${date.$D}` : date.$D;
    date.$M = date.$M < 10 ? `0${date.$M + 1}` : date.$M + 1;
    const formattedDate = `${date.$y}-${date.$M}-${date.$D}`;
    return formattedDate;
  };

  const disabledDate = (current) => {
    console.log(current, dayjs(moment(), { jalali: true }));

    return current && current < dayjs().startOf("day");
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        values.from = formattedDate(values.date[0]);
        values.to = formattedDate(values.date[1]);
        // values.to = dayjs(values.date[1]).toISOString();
        delete values.date;
        api.calendar
          .createEvent(values)
          .then((res) => {
            onUpdate(null, true);
            navigate(-1);
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch(() => {});
  };
  const componentDidUpdate = () => {
    switch (hash) {
      case `#${Modals.CREATE}`: {
        setState((prevState) => ({ ...prevState, visible: true }));
        if (data) {
          form.setFieldValue("date", [dayjs(data), null]);
        }
        break;
      }

      default: {
        if (visible) form.resetFields();

        setState(initialState);

        break;
      }
    }
  };

  useEffect(componentDidUpdate, [hash]);

  return (
    <Drawer
      className="sp-form-drawer"
      placement="right"
      footer={
        <>
          <Button type="primary" onClick={handleSubmit} loading={submitting}>
            {resources.confirm}
          </Button>
          <Button onClick={() => navigate(-1)} disabled={submitting}>
            {resources.cancel}
          </Button>
        </>
      }
      onClose={() => navigate(-1)}
      title={
        hash === `#${Modals.EDIT}`
          ? `${resources.edit} ${resources.event} "${title}"`
          : `${resources.add} ${resources.event}`
      }
      maskClosable={false}
      open={visible}
      width={320}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label={resources.title} rules={rules.required}>
          <Input />
        </Form.Item>
        <Form.Item name="date" label={resources.date} rules={rules.required}>
          <DatePicker.RangePicker disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item
          name="description"
          label={resources.description}
          rules={rules.required}
        >
          <Input.TextArea maxLength={60} />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default Component;
