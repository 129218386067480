import { Avatar, Card, Spin, Grid, Row, Col, Button, Upload } from "antd";
import { useState, useEffect } from "react";
import BarComponent from "../../components/BarComponent";
import moment from "moment-jalaali";
import api from "../../scripts/api";
import resources from "scripts/resources";
import { ENV } from "scripts/settings";

import { UploadOutlined } from "@ant-design/icons";

import UploadImage from "components/UploadAvatar";

const Component = () => {
	const UserInfoCardData = {
		width: "100%",
		rowGap: "16px",
		display: "flex",
		flexWrap: "wrap",
		marginTop: "16px",
		alignItems: "center",
		justifyContent: "space-evenly",
		// backgroundColor: "#fafafa",
	};
	const BackCard = {
		backgroundColor: "#d5e5d2",
		padding: "30px",
		marginRight: "10%",
		marginLeft: "10%",
		borderRadius: "10px",
	};

	const BorderedStyle = {
		border: "2px dotted",
		borderRadius: "5px",
		borderColor: "#5a7156",
		paddingLeft: "10px",
		paddingRight: "10px",
		textAlign: "right",
	};
	const style = {
		background: "#abcaa5",
		padding: "20px 10px 20px 10px",
		borderRadius: "5px",
		marginTop: "20px",
	};
	const initialState = {};

	const [data, setData] = useState([]);
	const [state, setState] = useState(initialState);
	const [loading, setLoading] = useState(true);
	const [imageUrl, setImageUrl] = useState();

	const fetchData = () => {
		// setState((prevState) => ({ ...prevState, loading: true }));

		api.auth
			.me()
			.then((res) => {
				// responseData = res;
				setData(res);

				setLoading(false);
			})
			.catch((e) => {

				setState((prevState) => ({ ...prevState, submitting: false }));
			});
	};

	const ChangeAvatar = (info) => {
		// setLoading(true);

		if (info.id) {
			let avatarId = {
				avatarId: info.id,
			};

			// Update the image URL in the state
			setImageUrl(info.uri);
			api.student
				.updateAvatar(avatarId)
				.then((res) => {
					// responseData = res;
					fetchData();
					// setData(res);
					//console.log("log line 64 dash is ", res);
					// setLoading(false);
				})
				.catch((e) => {
					setState((prevState) => ({ ...prevState, submitting: false }));
				});
		}
	};

	useEffect(() => {
		api.auth
			.me()
			.then((res) => {
				// responseData = res;
				setData(res);

				//console.log("log line 64 dash is ", res);
				setLoading(false);
			})
			.catch((e) => {
				setState((prevState) => ({ ...prevState, submitting: false }));
			});
	}, []);

	const RenderUserInfo = () => {
		if (data == [] || !data) {
			return (
				<div>
					<p>کلاسی وارد نشده است!</p>
				</div>
			);
		}
		return (
			<div style={BackCard}>
				<Row
					justify="center"
					gutter={16}
				>
					<Col
						className="gutter-row"
						span={8}
					></Col>
					<Col
						className="gutter-row"
						span={8}
					>
						<Avatar
							style={{ marginRight: "20%", border: "2px solid  #5a7156" }}
							src={`${ENV.basePath}${data.user.avatar}`}
							size={{ xs: 24, sm: 60, md: 80, lg: 100, xl: 120, xxl: 250 }}
						/>
						<UploadImage onChange={ChangeAvatar}>
							<Button icon={<UploadOutlined />}>Click to Upload</Button>
						</UploadImage>
					</Col>
					<Col
						className="gutter-row"
						span={8}
					></Col>
				</Row>

				<Row
					style={style}
					gutter={24}
				>
					<Col
						className="gutter-row"
						span={8}
						xs={24}
						sm={24}
						md={8}
					>
						<div>
							{`${resources.firstName} ${resources.and} ${resources.lastName} :`}
							<br />
							{`${data.user.firstName} ${data.user.lastName}`}
						</div>
					</Col>
					<Col
						className="gutter-row"
						span={8}
						xs={24}
						sm={24}
						md={8}
					>
						<div>
							{resources.grade} :
							<br />
							{data.user.grade}
						</div>
					</Col>

					<Col
						className="gutter-row"
						span={8}
						xs={24}
						sm={24}
						md={8}
					>
						<div>
							{resources.class} :
							<br />
							{data.user.class}
						</div>
					</Col>
				</Row>

				<Row
					style={style}
					gutter={16}
				>
					<Col
						className="gutter-row"
						span={8}
						xs={24}
						sm={24}
						md={8}
					>
						<div>
							{`${resources.nationalCode} :`}
							<br /> {data.user.nationalCode}
						</div>
					</Col>

					<Col
						className="gutter-row"
						span={8}
						xs={24}
						sm={24}
						md={8}
					>
						<div>
							{`${resources.name} ${resources.father} :`}
							<br /> {data.user.fatherName}
						</div>
					</Col>

					<Col
						className="gutter-row"
						span={8}
						xs={24}
						sm={24}
						md={8}
					>
						<div>
							{resources.birthday} :
							<br /> {moment(data.user.birthDate, "YYYY-M-D").locale("fa").format("jYYYY-jM-jD")}
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	return <div>{loading ? <Spin>Loading...</Spin> : <RenderUserInfo />}</div>;
};

export default Component;
