import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Checkbox, DatePicker, Drawer, Form, Input, Select, Col } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";
import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";

const Component = ({ data = {}, types, onUpdate, options: { assessments = [], lessons = [], classes = [], selectStudent = [] } }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");
	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible, classId, student } = state;
	const { id, type } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	// console.log("data ", data);
	// console.log("selectStudent", selectStudent);

	const params = GetUrlParams(search);

	const handleStudentFilter = (value) => {
		setState((prev) => ({
			...prev,
			classId: value,
		}));
	};

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {

					if (values.start) {
						let date = new Date(values.start);
						let formattedDate = date.toISOString();
						values.start = formattedDate;
					}
					if (values.end) {
						let date = new Date(values.end);
						let formattedDate = date.toISOString();
						values.end = formattedDate;
					}

					values.type = values.type || types[0]?.value;
					// values.studentId = params.student;
					values.studentId = values.student;

					delete values.class;
					delete values.student;

					console.log("values", values);

					// values.effectOnBar = !values.effectOnBar;
					setState((prevState) => ({ ...prevState, submitting: true }));
					const action = hash === `#${Modals.EDIT}` ? api.library.update(values, id) : api.library.create(values);

					// api.library.update(id, values)
					action
						.then((res) => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};
	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));
				form.setFieldsValue({
					type: types[0]?.value, // Set default type when creating
				});
				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true }));          
					form.setFieldsValue({
						...data,
						type: data.type ?? types[0]?.value, // Set default if undefined
						start: dayjs(data.start),
						end: dayjs(data.end),
						assessmentId: data.assessment?.id,
						lesson: data.lesson?.value,
						regulationId: data.regulation?.id,
						student: data.student?.id,
					});
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}
				break;
			}
			default: {
				if (visible) form.resetFields();
				setState(initialState);
				break;
			}
		}
	};

	const fetchStudent = async () => {
		try {
			const res = await api.student.related(classId);

			const transformedOptions = res.map(({ id, firstName, lastName }) => ({
				value: id,
				label: `${firstName} ${lastName}`,
			}));
			setState((prev) => ({
				...prev,
				student: transformedOptions,
			}));
		} catch (err) {
			console.log("Error fetching student data:", err);
		}
	};

	useEffect(() => {
		if (classId) {
			fetchStudent();
		}
	}, [classId]);
	// const componentDidUpdate = () => {
	// 	switch (hash) {
	// 		case `#${Modals.CREATE}`: {
	// 			setState((prevState) => ({ ...prevState, visible: true }));

	// 			break;
	// 		}
	// 		case `#${Modals.EDIT}`: {
	// 			if (id) {
	// 				setState((prevState) => ({ ...prevState, visible: true }));

	// 				form.setFieldsValue({
	// 					...data,
	// 					start: dayjs(data.start),
	// 					end: dayjs(data.end),
	// 					assessmentId: data.assessment?.id,
	// 					lesson: data.lesson?.value,
	// 					regulationId: data.regulation?.id,
	// 					studentId: data.student?.id,
	// 					// effectOnBar: !data.effectOnBar,
	// 				});
	// 			} else {
	// 				navigate(`${pathname}${search}`, { replace: true });
	// 			}

	// 			break;
	// 		}
	// 		default: {
	// 			if (visible) form.resetFields();

	// 			setState(initialState);

	// 			break;
	// 		}
	// 	}
	// };

	useEffect(componentDidUpdate, [hash]);
	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={`${hash === `#${Modals.EDIT}` ? resources.edit : resources.add} ${`${resources.book} ${resources.and} ${resources.toy}`}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="name"
					label={`${resources.name}`}
					rules={rules.required}
				>
					<Input />
				</Form.Item>

				<Form.Item
					name="start"
					label={`${resources.date} ${resources.take}`}
					rules={rules.required}
				>
					<DatePickerJalali style={{ display: "block" }} />
				</Form.Item>
				<Form.Item
					name="end"
					label={`${resources.date} ${resources.return}`}
					rules={rules.required}
				>
					<DatePickerJalali style={{ display: "block" }} />
				</Form.Item>

				<Form.Item
					name="type"
					label={resources.type}
					rules={rules.required}
				>
					<Select
						options={types}
						label={resources.type}
						rules={rules.required}
					/>
				</Form.Item>

				<Form.Item
					name="class"
					label={resources.class}
					rules={rules.required}
				>
					<Select
						placeholder={resources.class}
						options={classes}
						onChange={handleStudentFilter}
					/>
				</Form.Item>

				<Form.Item
					name="student"
					label={resources.student}
					rules={rules.required}
				>
					<Select
						placeholder={resources.students}
						options={student}
					/>
				</Form.Item>
			</Form>
			{/* {resArray && (
				<Card>
					{data.map((el) => {
						return <p>{el}</p>;
					})}
				</Card>
			)} */}
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
