import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
	Button,
	Col,
	DatePicker,
	FloatButton,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Table,
	Tabs,
	Image,
} from "antd";
import {
	DatePicker as DatePickerJalali,
	Calendar as CalendarJalali,
	useJalaliLocaleListener,
} from "antd-jalali";
import {
	EditOutlined,
	DeleteOutlined,
	PlusOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import dayjs from "dayjs";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/ClassAssessmentForm";
import StudentCarousel from "components/StudentCarousel";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = {
		assessments: [],
		classes: [],
		data: [],
		lessons: [],
		students: [],
		pagination: {},
	};
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) =>
				(pagination.current - 1) * pagination.pageSize + index + 1,
		},
		Table.EXPAND_COLUMN,
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: value => {
				const [date, time] = moment(value)
					.format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
					.split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: resources.student,
			dataIndex: "student",
			className: "center-cell",
			render: value => `${value.firstName} ${value.lastName}`,
		},
		{
			title: resources.regulation,
			dataIndex: "regulation",
			className: "center-cell",
			render: value => value?.title,
		},
		{
			title: resources.assessment,
			dataIndex: "assessment",
			className: "center-cell",
			render: value => value?.title,
		},
	];
	const [state, setState] = useState(initialState);
	const {
		assessments,
		classes,
		data,
		lessons,
		loading,
		selected,
		students,
		selectStudent,
		pagination,
	} = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);
		form.resetFields();
		form.setFieldsValue({
			...params,
			date: params.date ? dayjs(params.date) : undefined,
		});

		setState(prevState => ({ ...prevState, loading: true }));
		let studentId = params.student;
		api.parentDescription
			.disList({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then(data => {
				setState(prevState => ({
					...prevState,
					data: data.list,
					loading: false,
					pagination: data.pagination,
				}));
			})
			.catch(() => {
				setState(prevState => ({ ...prevState, loading: false }));
			});
	};
	const handleClassFilter = debounce(() => {
		form
			.validateFields()
			.then(values => {
				const params = GetUrlParams(search);
				const _params = params.search === values.search ? params : {};

				const _search = ObjectToURL({ ..._params, ...values });

				navigate(`${pathname}?${_search}`);
			})
			.then(() => {
				const params = GetUrlParams();
				// const classId = params.class;
				params.classId = params.class;
				delete params.class;
				const searchText = params.search;
				if (params.search && params.classId) {
					let searchText = params.search;

					api.student
						.related(params.class, searchText)
						.then(students => {
							setState(prevState => ({
								...prevState,
								students,
								classId: params.class,
								loading: false,
							}));
							if (onFetch)
								onFetch(
									data.map(({ id, firstName, lastName }) => ({
										value: id,
										label: `${firstName} ${lastName}`,
									}))
								);
						})
						.catch(() => {});
				} else if (params.classId) {
					api.parentDescription
						.disList({ pageSize: process.env.REACT_APP_PAGE_SIZE, ...params })
						.then(data => {
							setState(prevState => ({
								...prevState,
								data: data.list,
								classId: params.classId,
								loading: false,
								pagination: data.pagination,
							}));
							let selectStudent = students.map(
								({ id, firstName, lastName }) => ({
									value: id,
									label: `${firstName} ${lastName}`,
								})
							);
							setState(prevState => ({ ...prevState, selectStudent }));
						})
						.catch(() => {});
				} else {
					setState(prevState => ({ ...prevState, data: [] }));
				}
			});
	}, 1000);

	const handleView = selected => {};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState(prevState => ({ ...prevState, selected }));

			navigate(
				`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`
			);
		}
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then(values => {
			const params = GetUrlParams(search);

			const _params = params.class === values.class ? params : {};

			const _search = ObjectToURL({ page: 1, ..._params, ...values });

			navigate(`${pathname}?${_search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.class
			.related()
			.then(classes => {
				setState(prevState => ({ ...prevState, classes }));
			})
			.catch(() => {});
	};

	const expandedRowRender = record => {
		let img = `${ENV.basePath}${record.image}`;

		const columns = [
			{
				title: resources.description,
				dataIndex: "description",
				key: "description",
			},
			{
				title: `${resources.description} ${resources.parent}`,
				dataIndex: "parentDescription",
				key: "parentDescription",
			},
			{
				title: ` ${resources.image} ${resources.description} ${resources.parent}`,
				key: "parentImage",
				render: (value, record) => {
					return (
						<>
							{record.parentImage != "-" ? (
								<Image
									src={`${ENV.basePath}${record.parentImage}`}
									// shape="square"
									width={40}
									height={40}
								/>
							) : (
								<>-</>
							)}
						</>
					);
				},
			},
		];
		const data = [];
		data.push({
			description: record.description,
			parentDescription: record.parentDescription,
			parentImage: record.parentImage,
		});

		return <Table columns={columns} dataSource={data} pagination={false} />;
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	return (
		<>
			{/* <Tabs
				className="sp-tabs"
				activeKey={2}
				items={[
					{ key: 1, label: <Link to={paths.educational.dailyReports}>{`${resources.dailyreport}`}</Link> },
					{ key: 2, label: <Link to={paths.educational.classAssessments}>{`${resources.assessment} ${resources.class}${resources.y}`}</Link> },
					{ key: 3, label: <Link to={paths.educational.monthlyWorkbooks}>{`${resources.workbook} ${resources.monthly}`}</Link> },
					{ key: 4, label: <Link to={paths.educational.generalExams}>{`${resources.exam} ${resources.general}`}</Link> },
					{ key: 5, label: <Link to={paths.educational.generalReports}>{`${resources.report} ${resources.total}`}</Link> },
					{ key: 6, label: <Link to={paths.educational.regulations}>{`${resources.regulation} ${resources.education}${resources.y}`}</Link> },
				]}
			/> */}

			<Form layout="vertical" form={form}>
				<Row gutter={24}>
					<Col xs={24} sm={12} md={8} lg={6} xxl={4}>
						<Form.Item name="classId">
							<Select
								placeholder={resources.class}
								options={classes}
								onChange={handleClassFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={12} md={8} lg={6} xxl={4}>
						<Form.Item name="date">
							<DatePickerJalali
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
				expandable={{
					expandedRowRender: record => expandedRowRender(record),
				}}
			/>

			{contextHolder}
		</>
	);
};

export default Component;
