import { Outlet } from "react-router-dom";
import { Layout } from "antd";

const { Content } = Layout;

const Component = () => {
	return (
		<Layout className="sp-auth-layout">
			<Content>
				<Outlet />
			</Content>
		</Layout>
	);
};

export default Component;
