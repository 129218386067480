import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Col,
	DatePicker,
	Divider,
	Empty,
	Form,
	Row,
	Spin,
	FloatButton,
	Badge,
	Tooltip,
	Space,
	Button,
	Typography,
	Modal,
} from "antd";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import {
	DatePicker as DatePickerJalali,
	Calendar as CalendarJalali,
	useJalaliLocaleListener,
} from "antd-jalali";

import {
	enToFaDigits,
	GetUrlParams,
	MinuteFormat,
	ObjectToURL,
} from "scripts/helpers";
import { Modals, Types } from "scripts/settings";
import resources from "scripts/resources";
import api from "scripts/api";
import dayjs from "dayjs";

import FormModal from "modals/EventForm";
import clsx from "clsx";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	const initialState = { data: [] };
	const [state, setState] = useState(initialState);
	const { data, loading, selected } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	const disabledDate = current => {
		console.log(dayjs().endOf("day"));

		// return current && current < dayjs().endOf("month");
	};

	const fetchData = () => {
		const params = GetUrlParams(search);
		form.resetFields();
		const jalaliDate = moment(`${params.year}/${params.month}`, "jYYYY/jM");
		const gregorianDate = jalaliDate.format("YYYY-MM");

		// const jalaliDate = moment(`${params.year}/${params.month}`, "jYYYY/jM");
		// const gregorianDate = jalaliDate.format("YYYY-MM");

		// date: dayjs(`${params.year}/${params.month}`, { jalali: true }) || null,
		form.setFieldsValue({
			...params,
			date: params.year
				? dayjs(`${params.year}/${params.month}`, { jalali: true })
				: null,
			// date: params.year ? dayjs(gregorianDate) : null,
		});

		setState(prevState => ({ ...prevState, loading: false }));
		api.calendar
			.list({ ...params })
			.then(({ data }) => {
				setState(prevState => ({
					...prevState,
					data,
					loading: false,
				}));
			})
			.catch(() => {
				setState(prevState => ({ ...prevState, loading: false }));
			});
	};

	const handleFilter = debounce(() => {
		form.validateFields().then(values => {
			values.year = values.date?.$jy;
			values.month = values.date?.$jM + 1;
			delete values.date;
			const search = ObjectToURL({
				...values,
			});
			navigate(`${pathname}?${search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	useEffect(componentDidUpdate, [search]);

	return (
		<>
			<Form layout="vertical" form={form}>
				<Row gutter={24}>
					<Col xs={24} sm={12} md={12} lg={6} xxl={4}>
						<Form.Item name="date">
							<DatePickerJalali.MonthPicker
								onChange={handleFilter}
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Card style={{}}>
				{loading ? (
					<Spin className="sp-center-spin" />
				) : (
					<>
						<Row justify="center" style={{ textAlign: "center" }}>
							<Col xs={3}>{resources.saturday}</Col>
							<Col xs={3}>{resources.sunday}</Col>
							<Col xs={3}>{resources.monday}</Col>
							<Col xs={3}>{resources.tuesday}</Col>
							<Col xs={3}>{resources.wednesday}</Col>
							<Col xs={3}>{resources.thursday}</Col>
							<Col xs={3}>{resources.friday}</Col>
						</Row>
						<Divider />
						{data.length ? (
							data?.map((week, i) => (
								<Row justify="center" key={i}>
									{week.map(day => (
										<Col xs={3} key={day?.date || Math.random()}>
											{day && (
												<div
													className={clsx("day", {
														today: dayjs(day?.date).isSame(dayjs(), "day"),
														holiday: day?.isHoliday,
														event: day?.events.length,
													})}
												>
													<div className="day-number">{`${enToFaDigits(
														moment(day.date).jDate()
													)}`}</div>
													{day?.events.map(e => (
														<Row key={e.id}>
															<Badge
																className="content"
																text={
																	<Tooltip title={e.description}>
																		<small className="title">{e?.title}</small>
																	</Tooltip>
																}
																color="green"
															/>
														</Row>
													))}
												</div>
											)}
										</Col>
									))}
								</Row>
							))
						) : (
							<Empty />
						)}
					</>
				)}
			</Card>

			{contextHolder}
		</>
	);
};

export default Component;
