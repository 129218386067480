import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Checkbox, Col, Drawer, Form, Input, Row } from "antd";
import PropTypes from "prop-types";

import { Modals } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

const Component = ({ data = {}, onUpdate, options: { permissions = [] } }) => {
	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible } = state;
	const { id, name } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));

					const action = hash === `#${Modals.EDIT}` ? api.role.update(values, id) : api.role.create(values);

					action
						.then(() => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true }));

					form.setFieldsValue(data);
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};
	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={hash === `#${Modals.EDIT}` ? `${resources.edit} ${resources.role} "${name}"` : `${resources.add} ${resources.role}`}
			maskClosable={false}
			open={visible}
			width={768}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="name"
					label={resources.title}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				{permissions.map(({ activation, alias, create, name, read, remove, role, update, refer, suspend }) => (
					<Row
						gutter={[16, 8]}
						key={name}
						style={{ marginBottom: 16 }}
					>
						<Col
							xs={24}
							className="rolf-form-title"
						>
							{alias}
						</Col>
						{create && (
							<Col
								xs={24}
								sm={12}
								md={6}
							>
								<Form.Item
									name={["permissions", name, "create"]}
									valuePropName="checked"
									noStyle
								>
									<Checkbox>{resources.add}</Checkbox>
								</Form.Item>
							</Col>
						)}
						{read && (
							<Col
								xs={24}
								sm={12}
								md={6}
							>
								<Form.Item
									name={["permissions", name, "read"]}
									valuePropName="checked"
									noStyle
								>
									<Checkbox>{resources.read}</Checkbox>
								</Form.Item>
							</Col>
						)}
						{remove && (
							<Col
								xs={24}
								sm={12}
								md={6}
							>
								<Form.Item
									name={["permissions", name, "remove"]}
									valuePropName="checked"
									noStyle
								>
									<Checkbox>{resources.delete}</Checkbox>
								</Form.Item>
							</Col>
						)}
						{update && (
							<Col
								xs={24}
								sm={12}
								md={6}
							>
								<Form.Item
									name={["permissions", name, "update"]}
									valuePropName="checked"
									noStyle
								>
									<Checkbox>{resources.update}</Checkbox>
								</Form.Item>
							</Col>
						)}
						{activation && (
							<Col
								xs={24}
								sm={12}
								md={6}
							>
								<Form.Item
									name={["permissions", name, "activation"]}
									valuePropName="checked"
									noStyle
								>
									<Checkbox>{resources.activation}</Checkbox>
								</Form.Item>
							</Col>
						)}
						{role && (
							<Col
								xs={24}
								sm={12}
								md={6}
							>
								<Form.Item
									name={["permissions", name, "role"]}
									valuePropName="checked"
									noStyle
								>
									<Checkbox>{resources.role}</Checkbox>
								</Form.Item>
							</Col>
						)}
						{refer && (
							<Col
								xs={24}
								sm={12}
								md={6}
							>
								<Form.Item
									name={["permissions", name, "refer"]}
									valuePropName="checked"
									noStyle
								>
									<Checkbox>{resources.refer}</Checkbox>
								</Form.Item>
							</Col>
						)}
						{suspend && (
							<Col
								xs={24}
								sm={12}
								md={6}
							>
								<Form.Item
									name={["permissions", name, "suspend"]}
									valuePropName="checked"
									noStyle
								>
									<Checkbox>{resources.suspend}</Checkbox>
								</Form.Item>
							</Col>
						)}
					</Row>
				))}
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired,
};

export default Component;
