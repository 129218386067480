// import { useEffect, useState } from "react";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { Button, Modal, Table, Select, Tooltip, Form, Row, Col } from "antd";
// import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
// import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
// import moment from "moment-jalaali";
// import dayjs from "dayjs";
// import { debounce } from "lodash";

// import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
// import { ENV, Modals } from "scripts/settings";
// import api from "scripts/api";
// import paths from "scripts/paths";
// import resources from "scripts/resources";

// import FormModal from "modals/student/MeetManagmentForm";

// const Component = ({ permissions, setRoute }) => {
// 	useJalaliLocaleListener();
// 	dayjs.calendar("jalali");

// 	const initialState = { data: [], pagination: {} };
// 	const tableColumns = [
// 		{
// 			title: resources.row,
// 			dataIndex: "index",
// 			className: "center-cell",
// 			width: "60px",
// 			render: (value, record, index) => index + 1,
// 		},
// 		{
// 			title: resources.date,
// 			dataIndex: "date",
// 			className: "center-cell desc-cell ltr-cell",
// 			width: "100px",
// 			render: (value) => {
// 				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

// 				return (
// 					<>
// 						<span className="name">{date}</span>
// 					</>
// 				);
// 			},
// 		},
// 		{
// 			title: resources.start,
// 			dataIndex: "start",
// 			className: "center-cell",
// 			render: (value) => {
// 				// const [date, time] = moment(value)
// 				//   .format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
// 				//   .split(" ");

// 				return (
// 					<>
// 						<span className="name">{value}</span>
// 					</>
// 				);
// 			},
// 		},
// 		{
// 			title: resources.end,
// 			dataIndex: "end",
// 			className: "center-cell",
// 			render: (value) => {
// 				// const [date, time] = moment(value)
// 				//   .format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
// 				//   .split(" ");

// 				return (
// 					<>
// 						<span className="name">{value}</span>
// 					</>
// 				);
// 			},
// 		},
// 		{
// 			title: `${resources.parent}`,
// 			dataIndex: "student",
// 			className: "center-cell",
// 		},
// 		{
// 			title: resources.action,
// 			dataIndex: "id",
// 			className: "center-cell",
// 			width: "120px",
// 			render: (value, record) => (
// 				<>
// 					{record.isReserved ? (
// 						<>
// 							{record.canChange ? (
// 								<>
// 									<Tooltip title={resources.reserve}>
// 										<Button
// 											type="link"
// 											icon={<EditOutlined />}
// 											onClick={() => reserveManagementMeeting(record, "removeReserved")}
// 										/>
// 									</Tooltip>
// 								</>
// 							) : (
// 								<span>رزرو شده</span>
// 							)}
// 						</>
// 					) : (
// 						<>
// 							{record.canChange ? (
// 								<>
// 									<Tooltip title={resources.delete}>
// 										<Button
// 											type="link"
// 											icon={<DeleteOutlined />}
// 											onClick={() => handleDelete(value)}
// 										/>
// 									</Tooltip>
// 								</>
// 							) : (
// 								<Tooltip title={resources.reserve}>
// 									<Button
// 										type="link"
// 										icon={<EditOutlined />}
// 										onClick={() => reserveManagementMeeting(record, "reserve")}
// 									/>
// 								</Tooltip>
// 							)}
// 						</>
// 					)}
// 				</>
// 			),
// 		},
// 	];
// 	const [state, setState] = useState(initialState);
// 	const { data, loading, pagination, selected } = state;
// 	const { pathname, search } = useLocation();
// 	const [modal, contextHolder] = Modal.useModal();
// 	const [form] = Form.useForm();

// 	const navigate = useNavigate();

// 	const fetchData = () => {
// 		AddNotification();
// 		const params = GetUrlParams(search);

// 		form.resetFields();
// 		form.setFieldsValue({
// 			...params,
// 			date: params.date ? dayjs(params.date) : undefined,
// 		});
// 		setState((prevState) => ({ ...prevState, loading: true }));

// 		api.stPanel
// 			.getMeetingManagment({
// 				...params,
// 				pageSize: process.env.REACT_APP_PAGE_SIZE,
// 			})
// 			.then((res) => {
// 				setState((prevState) => ({
// 					...prevState,
// 					data: res,
// 					loading: false,
// 				}));
// 			})
// 			.catch(() => {
// 				setState((prevState) => ({ ...prevState, loading: false }));
// 			});
// 	};

// 	const reserveManagementMeeting = (selected, reserve) => {
// 		console.log("reserve ", reserve);
// 		const params = GetUrlParams(search);
// 		params.meeting = selected.id;
// 		if (reserve === "reserve") {
// 			modal.confirm({
// 				title: resources.reserveManagementMeeting,
// 				okText: resources.yes,
// 				okType: "danger",
// 				cancelText: resources.no,
// 				onOk: () => {
// 					setState((prevState) => ({ ...prevState, loading: true }));

// 					api.stPanel
// 						.reserveMeetingManagment(params)
// 						.then(() => {
// 							fetchData();
// 						})
// 						.catch(() => {
// 							setState((prevState) => ({ ...prevState, loading: false }));
// 						});
// 				},
// 			});
// 		} else {
// 			modal.confirm({
// 				title: resources.removeReserveManagementMeeting,
// 				okText: resources.yes,
// 				okType: "danger",
// 				cancelText: resources.no,
// 				onOk: () => {
// 					setState((prevState) => ({ ...prevState, loading: true }));

// 					api.stPanel
// 						.removeReserveMeetingManagment(params)
// 						.then(() => {
// 							fetchData();
// 						})
// 						.catch(() => {
// 							setState((prevState) => ({ ...prevState, loading: false }));
// 						});
// 				},
// 			});
// 		}
// 	};

// 	const handleDelete = (value) => {
// 		const params = GetUrlParams(search);

// 		modal.confirm({
// 			title: resources.deleteConfirm,
// 			okText: resources.yes,
// 			okType: "danger",
// 			cancelText: resources.no,
// 			onOk: () => {
// 				setState((prevState) => ({ ...prevState, loading: true }));
// 				const params = {
// 					meeting: `${value}`,
// 				};

// 				api.stPanel
// 					.removeReserveMeetingManagment(params)
// 					.then(() => {
// 						fetchData();
// 					})
// 					.catch(() => {
// 						setState((prevState) => ({ ...prevState, loading: false }));
// 					});
// 			},
// 		});
// 	};

// 	const handleTable = ({ current }) => {
// 		const params = GetUrlParams(search);
// 		const query = ObjectToURL({ ...params, page: current });

// 		navigate(`${pathname}?${query}`);
// 	};
// 	const handleFilter = debounce(() => {
// 		form.validateFields().then((values) => {
// 			const search = ObjectToURL({ page: 1, ...values });

// 			navigate(`${pathname}?${search}`);
// 		});
// 	}, 1000);
// 	const componentDidUpdate = () => {
// 		fetchData();
// 	};

// 	const componentDidMount = () => {
// 		setRoute();
// 	};

// 	useEffect(componentDidUpdate, [search]);
// 	useEffect(componentDidMount, []);

// 	return (
// 		<>
// 			<Form
// 				layout="vertical"
// 				form={form}
// 			>
// 				<Row gutter={24}>
// 					<Col
// 						xs={24}
// 						sm={12}
// 						md={8}
// 						lg={6}
// 						xxl={4}
// 					>
// 						<Form.Item name="date">
// 							<DatePickerJalali
// 								onChange={handleFilter}
// 								style={{ display: "block" }}
// 							/>
// 						</Form.Item>
// 					</Col>
// 				</Row>
// 			</Form>
// 			<Table
// 				columns={tableColumns}
// 				dataSource={data}
// 				loading={loading}
// 				onChange={handleTable}
// 				pagination={pagination}
// 				scroll={{ x: 930 }}
// 				size="small"
// 				rowKey="id"
// 			/>
// 			<FormModal
// 				data={selected}
// 				onUpdate={handleForm}
// 			/>
// 			{contextHolder}
// 		</>
// 	);
// };

// export default Component;

import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Modal, Table, Select, Tooltip, Form, Row, Col, Checkbox } from "antd";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment-jalaali";
import dayjs from "dayjs";
import { debounce } from "lodash";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/student/MeetManagmentForm";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	dayjs.calendar("jalali");

	const initialState = { data: [], pagination: {}, teachers: [] };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => index + 1,
		},
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: resources.start,
			dataIndex: "start",
			className: "center-cell",
			render: (value) => {
				// const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{value}</span>
					</>
				);
			},
		},
		{
			title: resources.end,
			dataIndex: "end",
			className: "center-cell",
			render: (value) => {
				// const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{value}</span>
					</>
				);
			},
		},
		{
			title: `${resources.subject} ${resources.meet}`,
			dataIndex: "title",
			className: "center-cell",
		},
		{
			title: resources.reserve,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{record.isReserved ? (
						<>
							<Checkbox
								title={resources.edit}
								checked={record.isReserved}
							></Checkbox>
						</>
					) : (
						<>
							<Checkbox
								title={resources.edit}
								checked={false}
							></Checkbox>
						</>
					)}
				</>
			),
		},
		{
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{record.isReserved ? (
						<>
							{record.canChange ? (
								<>
									<Tooltip title={resources.reserve}>
										<Button
											type="link"
											icon={<EditOutlined />}
											onClick={() => handleForm(record)}
										/>
									</Tooltip>
									<Tooltip title={resources.delete}>
										<Button
											type="link"
											icon={<DeleteOutlined />}
											onClick={() => handleDelete(value)}
										/>
									</Tooltip>
								</>
							) : (
								<span>رزرو شده</span>
							)}
						</>
					) : (
						<>
							{record.canChange ? (
								<>
									<Tooltip title={resources.delete}>
										<Button
											type="link"
											icon={<DeleteOutlined />}
											onClick={() => handleDelete(value)}
										/>
									</Tooltip>
								</>
							) : (
								<Tooltip title={resources.reserve}>
									<Button
										type="link"
										icon={<EditOutlined />}
										onClick={() => handleForm(record)}
									/>
								</Tooltip>
							)}
						</>
					)}
				</>
			),
		},
	];
	const [state, setState] = useState(initialState);
	const { data, loading, pagination, teachers, selected } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();

	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue({ ...params, date: params.date ? dayjs(params.date) : undefined });
		setState((prevState) => ({ ...prevState, loading: true }));

		api.stPanel
			.getMeetingManagment({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then((res) => {
				setState((prevState) => ({ ...prevState, data: res, pagination, loading: false }));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected }));

			navigate(`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`);
		}
	};

	const handleDelete = (value) => {
		const params = GetUrlParams(search);

		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));
				const params = {
					meeting: `${value}`,
				};

				api.stPanel
					.removeReserveMeetingManagment(params)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};
	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			const search = ObjectToURL({ page: 1, ...values });

			navigate(`${pathname}?${search}`);
		});
	}, 1000);
	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	return (
		<>
			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="date">
							<DatePickerJalali
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>
			<FormModal
				data={selected}
				onUpdate={handleForm}
			/>
			{contextHolder}
		</>
	);
};

export default Component;
