import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
	Avatar,
	Button,
	Col,
	FloatButton,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Table,
	Tabs,
	Tooltip,
} from "antd";
import {
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
	SearchOutlined,
	FileAddOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import moment from "moment-jalaali";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/StudentForm";
import FileModal from "modals/FileForm";

const Component = ({ permissions, setRoute }) => {
	const initialState = { data: [], pagination: {} };
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) =>
				(pagination.current - 1) * pagination.pageSize + index + 1,
		},
		{
			title: resources.user,
			dataIndex: "avatar",
			className: "profile-cell",
			render: (value, record) => (
				<>
					<Tooltip
						title={
							<Avatar
								src={`${ENV.basePath}${value?.uri}`}
								size={200}
								shape="square"
							/>
						}
					>
						<Avatar src={`${ENV.basePath}${value?.uri}`} />
					</Tooltip>
					{`${record.firstName} ${record.lastName}`}
				</>
			),
		},
		{
			title: `${resources.name} ${resources.father}`,
			dataIndex: "fatherName",
			className: "center-cell",
		},
		{
			title: resources.class,
			dataIndex: "class",
			className: "center-cell",
			render: value => value?.name,
		},
		{
			title: resources.phone,
			dataIndex: "phone",
			className: "center-cell ltr-cell",
			width: "100px",
		},
		{
			title: resources.nationalCode,
			dataIndex: "nationalCode",
			className: "center-cell ltr-cell",
			width: "100px",
		},
		{
			title: resources.birthday,
			dataIndex: "birthDate",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: value => {
				const [date, time] = moment(value)
					.format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI)
					.split(" ");
				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
	];
	const [state, setState] = useState(initialState);
	const { classes, data, loading, pagination, selected } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue(params);

		setState(prevState => ({ ...prevState, loading: true }));

		api.student
			.list({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then(({ list: data, pagination }) => {
				setState(prevState => ({
					...prevState,
					data,
					pagination,
					loading: false,
				}));
			})
			.catch(() => {
				setState(prevState => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState(prevState => ({ ...prevState, selected }));

			navigate(
				`${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`
			);
		}
	};

	const handleFileModal = (selected, reload) => {
		//console.log("selected line 123", reload);
		if (reload) {
			fetchData();
		} else {
			navigate(`${pathname}${search}#${Modals.FILE}`);
		}
	};

	const handleDelete = id => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState(prevState => ({ ...prevState, loading: true }));

				api.student
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState(prevState => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then(values => {
			const search = ObjectToURL({ page: 1, ...values });

			navigate(`${pathname}?${search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();

		api.class
			.list({ paging: false })
			.then(data => {
				setState(prevState => ({
					...prevState,
					classes: data.map(o => ({ value: o.id, label: o.name })),
				}));
			})
			.catch(() => {});
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.remove || permissions.update) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			{/* <Tabs
				className="sp-tabs"
				activeKey={1}
				items={[
					{ key: 1, label: <Link to={paths.configs.students}>{resources.students}</Link> },
					{ key: 2, label: <Link to={paths.configs.users}>{resources.users}</Link> },
					{ key: 3, label: <Link to={paths.configs.classes}>{`${resources.class}${resources.ha}`}</Link> },
					{ key: 4, label: <Link to={paths.configs.assessments}>{`${resources.assessment}${resources.ha}`}</Link> },
					{ key: 5, label: <Link to={paths.configs.roles}>{`${resources.role}${resources.ha}`}</Link> },
				]}
			/> */}

			<Form layout="vertical" form={form}>
				<Row gutter={24}>
					<Col xs={24} sm={12} md={8} lg={6} xxl={4}>
						<Form.Item name="search">
							<Input
								suffix={<SearchOutlined />}
								onChange={handleFilter}
								placeholder={resources.search}
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={12} md={8} lg={6} xxl={4}>
						<Form.Item name="class">
							<Select
								placeholder={resources.class}
								options={classes}
								onChange={handleFilter}
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={resources.add}
					icon={<PlusOutlined />}
					onClick={() => handleForm()}
				/>
			)}
			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={`${resources.add} ${resources.file}`}
					style={{ left: 90 }}
					icon={<FileAddOutlined />}
					onClick={() => handleFileModal()}
				/>
			)}

			{(permissions.create || permissions.update) && (
				<FormModal
					data={selected}
					onUpdate={handleForm}
					options={{ classes }}
				/>
			)}

			{permissions.create && (
				<FileModal data={classes} onUpdate={handleFileModal} />
			)}

			{contextHolder}
			
		</>
	);
};

export default Component;
