const ENV = {
	basePath: process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_DEV_URL,
};

const Modals = {
	CREATE: "CREATE",
	EDIT: "EDIT",
	ROLE: "ROLE",
	FILE: "FILE",
	ADDTOCLASS: "ADDTOCLASS",
	UPLOAD: "UPLOAD",
};

const Patterns = {
	mobile: /^(09)[0-9]{9}$/,
	email: /^\w+([-.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
};

const Permissions = {
	assessment: "Assessment",
	class: "Class",
	classAssessment: "ClassAssessment",
	course: "Course",
	calendar: "Calendar",
	disciplinaryGeneralReport: "DisciplinaryGeneralReport",
	disciplinaryRegulation: "DisciplinaryRegulation",
	disciplinaryReport: "DisciplinaryReport",
	disciplinaryReference: "DisciplinaryReference",
	document: "Document",
	educationalDR: "EducationalDR",
	educationalGeneralReport: "EducationalGeneralReport",
	educationalRegulation: "EducationalRegulation",
	educationalReference: "EducationalReference",
	generalExam: "GeneralExam",
	lesson: "Lesson",
	monthlyWorkbook: "MonthlyWorkbook",
	permission: "Permission",
	role: "Role",
	student: "Student",
	user: "User",
	salary: "Salary",
	meeting: "Meeting",
	pixel: "Pixel",
	managementMeeting: "ManagementMeeting",
};

const Status = {
	default: { active: "active", inactive: "inactive" },
};

const Types = {
	dashboard: { admin: "Admin", default: "Default" },
	event: {
		ceremony: "Ceremony",
		camping: "Camping",
		emergency: "Emergency",
		games: "Games",
		pollution: "Pollution",
		vacation: "Vacation",
	},
	uploadImageFormats: ["image/jpg", "image/jpeg", "image/png", "image/bmp", "application/pdf"],
	uploadVideoFormats: [
		"video/mp4",
		"video/webm",
		"video/mkv",
		"video/avi",
		"video/mpeg",
		"video/quicktime",
		"video/x-msvideo",
		"video/x-matroska",
		"image/jpg",
		"image/jpeg",
		"image/png",
		"image/bmp",
		"application/pdf",
	],
	uploadFileFormats: ["file/csv", "text/csv"],
};

export { ENV, Modals, Patterns, Permissions, Status, Types };
