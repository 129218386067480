import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
	Breadcrumb,
	Card,
	Col,
	DatePicker,
	Divider,
	Empty,
	Form,
	Row,
	Spin,
	FloatButton,
	Badge,
	Tooltip,
	Space,
	Button,
	Typography,
	Modal,
} from "antd";
import { debounce } from "lodash";
import moment from "moment-jalaali";
import {
	DatePicker as DatePickerJalali,
	Calendar as CalendarJalali,
	useJalaliLocaleListener,
} from "antd-jalali";

import {
	enToFaDigits,
	GetUrlParams,
	MinuteFormat,
	ObjectToURL,
} from "scripts/helpers";
import { Modals, Types } from "scripts/settings";
import resources from "scripts/resources";
import api from "scripts/api";
import dayjs from "dayjs";

import FormModal from "modals/EventForm";
import clsx from "clsx";

const PersianMonths = [
	resources.farvardin,
	resources.ordibehesht,
	resources.khordad,
	resources.tir,
	resources.mordad,
	resources.sharivar,
	resources.mehr,
	resources.aban,
	resources.azar,
	resources.dey,
	resources.bahman,
	resources.esfand,
];
const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();
	const initialState = { data: [] };
	const [state, setState] = useState(initialState);
	const { data, years, loading, selected } = state;
	const { pathname, search } = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [modal, contextHolder] = Modal.useModal();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	const disabledDate = current => {
		console.log(dayjs().endOf("day"));

		return current && current < dayjs().endOf("month");
	};

	const fetchData = () => {
		setState(prevState => ({ ...prevState, loading: true }));

		const params = GetUrlParams(search);
		form.resetFields();
		const jalaliDate = moment(`${params.year}/${params.month}`, "jYYYY/jM");
		// const gregorianDate = jalaliDate.format("YYYY-MM");

		// const jalaliDate = moment(`${params.year}/${params.month}`, "jYYYY/jM");
		// const gregorianDate = jalaliDate.format("YYYY-MM");

		// date: dayjs(`${params.year}/${params.month}`, { jalali: true }) || null,
		form.setFieldsValue({
			...params,
			date: params.year
				? dayjs(`${params.year}/${params.month}`, { jalali: true })
				: dayjs(moment(), { jalali: true }),
			// date: params.year ? dayjs(gregorianDate) : null,
		});

		setState(prevState => ({ ...prevState, loading: false }));
		api.calendar
			.getYear()
			.then(({ years }) => setState(ps => ({ ...ps, years })));
		api.calendar
			.list({ ...params })
			.then(({ data }) => {
				setState(prevState => ({
					...prevState,
					data,
					loading: false,
				}));
			})
			.catch(() => {
				setState(prevState => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (selected, reload) => {
		if (reload) {
			fetchData();
		} else {
			setState(prevState => ({ ...prevState, selected }));
			navigate(`${pathname}${search}#${Modals.CREATE}`);
		}
	};

	const handleDelete = id => {
		modal.confirm({
			title: resources.deleteEventConfirm,
			okText: resources.yes,
			okType: "primary",
			cancelText: resources.no,
			onOk: () => {
				setState(ps => ({ ...ps, loading: true }));
				api.calendar
					.deleteEvent(id)
					.then(() => {
						handleForm(null, true);
					})
					.catch(() => {});
			},
		});
	};
	const handleFilter = debounce(() => {
		form.validateFields().then(values => {
			values.year = values.date?.$jy;
			values.month = values.date?.$jM + 1;
			delete values.date;
			const search = ObjectToURL({
				...values,
			});
			navigate(`${pathname}?${search}`);
		});
	}, 1000);

	const componentDidUpdate = () => {
		fetchData();
	};

	useEffect(componentDidUpdate, [search]);
	const calcMonth = () => {
		const month = searchParams.get("month");
		return month ? month - 1 : dayjs(moment(), { jalali: true }).month();
	};
	return (
		<>
			<Form layout="vertical" form={form} style={{ marginBottom: "10px" }}>
				<Row gutter={24} justify="space-between" align="middle">
					<Col xs={24} sm={12} md={12} lg={6} xxl={4}>
						<Form.Item name="date" noStyle>
							<DatePicker.MonthPicker
								onChange={handleFilter}
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
					<Col>
						<h3>{resources.calendar}</h3>
					</Col>
					<Col xs={24} sm={6} md={4}>
						{years && (
							<Space>
								<p>{PersianMonths[calcMonth()]}</p>
								<p>{`${years?.[0]}-${years[1]}`}</p>
							</Space>
						)}
					</Col>
				</Row>
			</Form>

			<Card style={{ paddingLeft: null }}>
				<Row
					justify="center"
					style={{ textAlign: "center" }}
					className="weeknames"
				>
					<Col xs={3}>{resources.saturday}</Col>
					<Col xs={3}>{resources.sunday}</Col>
					<Col xs={3}>{resources.monday}</Col>
					<Col xs={3}>{resources.tuesday}</Col>
					<Col xs={3}>{resources.wednesday}</Col>
					<Col xs={3}>{resources.thursday}</Col>
					<Col xs={3}>{resources.friday}</Col>
				</Row>
				<Divider style={{ margin: "5px 0" }} />
				<>
					<Spin spinning={loading}>
						{data.length ? (
							data?.map((week, i) => (
								<Row justify="center" gutter={4} key={i}>
									{week.map(day => (
										<Col xs={3} md={3} key={day?.date || Math.random()}>
											{day && (
												<div
													className={clsx("day", {
														passed: dayjs(day?.date).isBefore(dayjs(), "day"),
														today: dayjs(day?.date).isSame(dayjs(), "day"),
														holiday: day?.isHoliday,
														event: day?.events.length,
													})}
													onClick={() => {
														if (day.isHoliday || day.events.length > 0) return;
														handleForm(day.date);
													}}
												>
													<div className="day-number">{`${enToFaDigits(
														moment(day.date).jDate()
													)}`}</div>
													{/* <Space wrap> */}
													{day?.events.map(e => (
														<Row key={e.id}>
															<Badge
																className="content"
																text={
																	<Tooltip
																		title={
																			<Space>
																				{e.description}
																				{permissions.remove && (
																					<Space>
																						<Button
																							size="small"
																							onClick={() => {
																								if (!permissions.remove) return;
																								handleDelete(e.id);
																							}}
																						>
																							{resources.delete}
																						</Button>
																					</Space>
																				)}
																			</Space>
																		}
																	>
																		<small className="title">{e?.title}</small>
																	</Tooltip>
																}
																color="green"
															/>
														</Row>
													))}
													{/* </Space> */}
												</div>
											)}
										</Col>
									))}
								</Row>
							))
						) : (
							<Empty />
						)}
					</Spin>
				</>
			</Card>
			{permissions.create && (
				<FloatButton
					type="primary"
					tooltip={`${resources.add} ${resources.event}`}
					onClick={() => handleForm()}
				/>
			)}
			{(permissions.create || permissions.update) && (
				<FormModal data={selected} onUpdate={handleForm} />
			)}

			{contextHolder}
		</>
	);
};

export default Component;

// TODO
/*
 - day clickable
 - selecet day object 

*/
