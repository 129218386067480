import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Avatar, Button, Col, FloatButton, Form, Modal, Row, Image, Table, Tabs, Tooltip } from "antd";
import { DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

const Component = ({ permissions, setRoute }) => {
	const initialState = { classes: [], data: [], students: [] };
	const [state, setState] = useState(initialState);
	const { classes, data, loading, selected, students, selectedStudent } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => index + 1,
		},
		{
			title: resources.image,
			dataIndex: "image",
			render: (value) => (
				<>
					<Image
						src={`${ENV.basePath}${value}`}
						// shape="square"
						width={40}
						height={40}
					/>
				</>
			),
		},
	];

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue(params);
		setState((prevState) => ({ ...prevState, loading: true }));

		api.stPanel
			.generalExam()
			.then((res) => {
				setState((prevState) => ({ ...prevState, data: res, loading: false }));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	const handleStudents = (selectedStudent) => {
		setState((prevState) => ({ ...prevState, selectedStudent }));
	};

	const handleView = (selected) => {
		//console.log(selected);
	};

	const handleForm = (reload) => {
		if (reload) {
			fetchData();
		} else {
			navigate(`${pathname}${search}#${Modals.CREATE}`);
		}
	};

	const handleDelete = (id) => {
		modal.confirm({
			title: resources.deleteConfirm,
			okText: resources.yes,
			okType: "danger",
			cancelText: resources.no,
			onOk: () => {
				setState((prevState) => ({ ...prevState, loading: true }));

				api.generalExam
					.delete(id)
					.then(() => {
						fetchData();
					})
					.catch(() => {
						setState((prevState) => ({ ...prevState, loading: false }));
					});
			},
		});
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const componentDidUpdate = () => {
		fetchData();
	};

	const componentDidMount = () => {
		setRoute();
	};

	useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	return (
		<>
			<Tabs
				className="sp-tabs"
				activeKey={5}
				items={[
					{
						key: 1,
						label: <Link to={paths.edustudent.dailyReports}>{`${resources.dailyreport}`}</Link>,
					},
					{
						key: 2,
						label: <Link to={paths.edustudent.dailyActivity}>{`${resources.eduDailyActivity}`}</Link>,
					},
					{
						key: 3,
						label: <Link to={paths.edustudent.classAssessments}>{`${resources.assessment} ${resources.class}`}</Link>,
					},
					{
						key: 4,
						label: <Link to={paths.edustudent.monthlyGradeSheet}>{`${resources.workbook} ${resources.monthly}`}</Link>,
					},
					{
						key: 5,
						label: <Link to={paths.edustudent.generalExams}>{`${resources.exam} ${resources.general}`}</Link>,
					},
					{
						key: 6,
						label: <Link to={paths.edustudent.generalReports}>{`${resources.report} ${resources.total}`}</Link>,
					},
				]}
			/>
			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={false}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
			/>

			{contextHolder}
		</>
	);
};

export default Component;
