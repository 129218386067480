import { Card, Spin, Row, Col, Button, Divider, message } from "antd";
import { useState, useEffect } from "react";
import BarComponent from "../../components/BarComponent";

import api from "../../scripts/api";
import resources from "scripts/resources";

const OverviewCard = ({ cardTitle, BarData, style }) => {
	return (
		<Card
			title={cardTitle}
			style={style}
		>
			{BarData.map((bar, index) => {
				return (
					<div key={index}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: "8px",
								marginTop: "8px",
							}}
						>
							{bar.disciplinaryBarTitle}
						</div>
						<BarComponent
							amount={bar.disciplinaryBarAmount}
							maxAmountAtEachSide={bar.maxAmountAtEachSide}
						/>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: "8px",
								marginTop: "8px",
							}}
						>
							{bar.educationalBarTitle}
						</div>
						<BarComponent
							amount={bar.educationalBarAmount}
							maxAmountAtEachSide={bar.maxAmountAtEachSide}
						/>
						{/* <div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: "8px",
								marginTop: "8px",
							}}
						>
							{bar.bankBarTitle}
						</div> */}
						{/* <BarComponent
							amount={bar.bankBarAmount}
							maxAmountAtEachSide={bar.maxAmountAtEachSide}
						/> */}
						{/* <div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
								marginBottom: "8px",
								marginTop: "8px",
							}}
						>
							{bar.libraryBarTitle}
						</div>
						<BarComponent
							amount={bar.libraryBarAmount}
							maxAmountAtEachSide={bar.maxAmountAtEachSide}
						/> */}
					</div>
				);
			})}
		</Card>
	);
};

const Component = () => {
	const presidentMainDivStyle = {
		width: "100%",
		rowGap: "16px",
		display: "flex",
		flexWrap: "wrap",
		marginTop: "16px",
		alignItems: "center",
		justifyContent: "space-evenly",
	};

	const initialState = {};

	const [data, setData] = useState([]);
	const [state, setState] = useState(initialState);
	const [loading, setLoading] = useState(true);

	const handleeducational = () => {
		api.bar
			.educational()
			.then((res) => {
				// responseData = res;
				// console.log("educational");
				message.success("تغییرات با موفقیت اعمال شد.");
			})
			.catch((e) => {
				setState((prevState) => ({ ...prevState, submitting: false }));
			});
	};
	const handledisciplinary = () => {
		api.bar
			.disciplinary()
			.then((res) => {
				// responseData = res;
				// console.log("disciplinary");
				message.success("تغییرات با موفقیت اعمال شد.");
			})
			.catch((e) => {
				setState((prevState) => ({ ...prevState, submitting: false }));
			});
	};
	useEffect(() => {
		api.dashboard
			.list()
			.then((res) => {
				// responseData = res;
				setData(res);
				// //console.log("log line 64 dash is ", responseData);
				setLoading(false);
			})
			.catch((e) => {
				setState((prevState) => ({ ...prevState, submitting: false }));
			});
	}, []);

	const RenderDashboard = () => {
		if (data == [] || !data) {
			return (
				<div>
					<p>کلاسی وارد نشده است!</p>
				</div>
			);
		}
		return (
			<>
				<Row
					gutter={24}
					style={{ alignItems: "left", justifyContent: "flex-end" }}
				>
					<Col>
						<Button
							onClick={handleeducational}
							type="primary"
						>
							{resources.update} {resources.score} {resources.education}
							{resources.y}
						</Button>
					</Col>
					<Col>
						<Button
							onClick={handledisciplinary}
							type="primary"
						>
							{resources.update} {resources.score} {resources.discipline}
						</Button>
					</Col>
				</Row>
				<Divider />
				<div style={presidentMainDivStyle}>
					{data.map((el, i) => {
						return (
							<OverviewCard
								// TODO => use data here
								BarData={[
									{
										maxAmountAtEachSide: 25,
										disciplinaryBarAmount: el.disciplinaryBar,
										disciplinaryBarTitle: `${resources.state} ${resources.discipline}`,
										educationalBarAmount: el.educationalBar,
										educationalBarTitle: `${resources.state} ${resources.education}${resources.y}`,
										bankBarAmount: el.bankBar,
										bankBarTitle: `${resources.state} ${resources.library}`,
										libraryBarAmount: el.libraryBar,
										libraryBarTitle: `${resources.state} ${resources.bank}`,
									},
								]}
								style={{
									width: "32%",
								}}
								cardTitle={el.className}
								key={i}
							/>
						);
					})}
				</div>
			</>
		);
	};

	return <div>{loading ? <Spin>Loading...</Spin> : <RenderDashboard />}</div>;
};

export default Component;
