import { useState } from "react";
import { Progress, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { ENV, Types } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";

const Component = ({ aspect = 1, onChange, value }) => {
	const [progress, setProgress] = useState(0);

	const customRequest = (options) => {
		const { file } = options;

		if (Types.uploadImageFormats.indexOf(file.type) >= 0) {
			if (file.size < parseInt(process.env.REACT_APP_IMAGE_SIZE_LIMIT)) {
				const formData = new FormData();

				console.log("newFileList newFileList", file);
				formData.append("file", file);

				api
					.file(formData, (progress) => setProgress(progress))
					.then((data) => {
						setProgress(0);

						onChange(data);
					})
					.catch((error) => {
						message.error(error);
					});
			} else {
				message.error(resources.uploadErros?.size);
			}
		} else {
			message.error(resources.uploadErros?.extension);
		}
	};

	const uploadOpt = { showUploadList: false, customRequest };

	return (
		<Upload
			{...uploadOpt}
			className="sp-upload-image"
		>
			{progress > 0 ? (
				<>
					<span className="uploading">{resources.upload}</span>
					<Progress
						percent={progress}
						showInfo={false}
					/>
				</>
			) : !!value?.uri ? (
				<img
					src={`${ENV.basePath}${value.uri}`}
					alt="avatar"
					className="image"
				/>
			) : (
				<PlusOutlined className="icon" />
			)}
		</Upload>
	);
};

Component.propTypes = {
	aspect: PropTypes.number,
	onChange: PropTypes.func,
	value: PropTypes.any,
};

export default Component;
