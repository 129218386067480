import { useEffect, useRef, useState } from "react";
import { Button, Form, Input } from "antd";

import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";
import rules from "scripts/rules";

import Logo from "images/logo.png";

const Component = () => {
  const initialState = {};
  const [state, setState] = useState(initialState);
  const { submitting } = state;
  const [form] = Form.useForm();

  const handleSubmit = () => {
    if (!submitting) {
      form
        .validateFields()
        .then((values) => {
          setState((prevState) => ({ ...prevState, submitting: true }));

          api.auth
            .login(values)
            .then((response) => {
              api.setCookie(response.token, 1);
              location = paths.root;
            })
            .catch((e) => {
              setState((prevState) => ({ ...prevState, submitting: false }));
            });
        })
        .catch(() => {});
    }
    setTimeout(function () {}, 5000);
  };

  const enterRef = useRef();
  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        enterRef.current.click();
      }
    });
  }, []);
  
  return (
    <div className="wrapper">
      <img src={Logo} className="brand" />
      <h1 className="heading">{`${resources.enter} ${resources.to} ${resources.account}`}</h1>
      <Form form={form} layout="vertical">
        <Form.Item
          name="username"
          label={resources.username}
          rules={rules.required}
        >
          <Input size="large" placeholder={resources.username} />
        </Form.Item>
        <Form.Item
          name="password"
          label={resources.password}
          rules={rules.required}
        >
          <Input.Password
            type="password"
            size="large"
            placeholder={resources.password}
            visibilityToggle={true}
          />
        </Form.Item>
        <Form.Item style={{ paddingTop: 16 }}>
          <Button
            ref={enterRef}
            type="primary"
            size="large"
            loading={submitting}
            onClick={handleSubmit}
            block
          >
            {resources.enter}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Component;
