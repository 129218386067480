import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { Avatar, Button, Col, FloatButton, Form, Image, Modal, Row, Select, Table, Tabs, Tooltip, DatePicker } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import moment, { months } from "moment-jalaali";
import { DatePicker as DatePickerJalali, Calendar as CalendarJalali, useJalaliLocaleListener } from "antd-jalali";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { ENV, Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/student/EduExcusedForm";

const Component = ({ permissions, setRoute }) => {
	useJalaliLocaleListener();

	// If you want to all new instanses of dayjs use jalali calendar (no matter what is the locale),
	// you can set default calendar for dayjs and remove useJalaliLocaleListener hook.
	dayjs.calendar("jalali");

	const initialState = {
		data: [],
		pagination: {},
		date: moment().format(process.env.REACT_APP_DATE_FORMAT).split(" "),
	};
	const tableColumns = [
		{
			title: resources.row,
			dataIndex: "index",
			className: "center-cell",
			width: "60px",
			render: (value, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
		},
		Table.EXPAND_COLUMN,
		{
			title: resources.date,
			dataIndex: "date",
			className: "center-cell desc-cell ltr-cell",
			width: "100px",
			render: (value) => {
				const [date, time] = moment(value).format(process.env.REACT_APP_DATE_TIME_FORMAT_JALALI).split(" ");

				return (
					<>
						<span className="name">{date}</span>
					</>
				);
			},
		},
		{
			title: `${resources.title}`,
			dataIndex: "regulation",
			className: "center-cell",
		},
		{
			title: `${resources.firstName} ${resources.lesson}`,
			dataIndex: "lesson",
			className: "center-cell",
			render: (value) => (value ? value : "-"),
		},
		{
			title: `${resources.subject} ${resources.lesson}`,
			dataIndex: "topic",
			className: "center-cell",
		},
		{
			title: resources.assessment,
			dataIndex: "assessment",
			className: "center-cell ltr-cell",
		},
		{
			title: `${resources.description} ${resources.parent}`,
			dataIndex: "parentDescription",
			className: "center-cell",
			// width: "120px",
			render: (value, record) => (
				<>
					{record.needsParentDescription ? (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					) : (
						<></>
					)}
				</>
			),
		},
	];
	const [state, setState] = useState(initialState);
	const { classes, data, loading, pagination, selected, date } = state;
	const { pathname, search } = useLocation();
	const [modal, contextHolder] = Modal.useModal();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const fetchData = () => {
		AddNotification();
		const params = GetUrlParams(search);

		form.resetFields();
		form.setFieldsValue({
			...params,
			date: params.date ? dayjs(params.date) : undefined,
		});
		params.type = "DailyActivity";

		setState((prevState) => ({ ...prevState, loading: true }));

		api.stPanel
			.dailyReport({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
			.then(({ list: data, pagination }) => {
				setState((prevState) => ({
					...prevState,
					data,
					pagination,
					loading: false,
				}));
			})
			.catch(() => {
				setState((prevState) => ({ ...prevState, loading: false }));
			});
	};

	const handleForm = (record, reload) => {
		// //console.log("line 92 is == ", value);
		//console.log("line 93 is == ", record);

		if (reload) {
			fetchData();
		} else {
			setState((prevState) => ({ ...prevState, selected: record }));

			navigate(`${pathname}${search}#${Modals.EDIT}`);
		}
	};

	const handleTable = ({ current }) => {
		const params = GetUrlParams(search);
		const query = ObjectToURL({ ...params, page: current });

		navigate(`${pathname}?${query}`);
	};

	const handleFilter = debounce(() => {
		form.validateFields().then((values) => {
			let months = values.date.$M + 1;
			let selectedDate = `${values.date.$y}-${months}-${values.date.$D}`;
			values.date = selectedDate;
			const search = ObjectToURL({ page: 1, ...values });

			navigate(`${pathname}?${search}`);
		});
	}, 1000);

	const handleDate = (value) => {
		let months = value.$M + 1;
		let selectedDate = `${value.$y}-${months}-${value.$D}`;
		//console.log("log line 120 value ", selectedDate);

		api.stPanel
			.dailyReport(selectedDate)
			.then((data) => {
				//console.log(data);
				// setState((prevState) => ({
				// 	...prevState,
				// 	classes: data.map((o) => ({ value: o.id, label: o.name })),
				// }));
			})
			.catch(() => {});
	};

	// const componentDidUpdate = () => {
	// 	fetchData();
	// };

	const componentDidMount = () => {
		setRoute();
		fetchData();
		// api.stPanel
		// 	.dailyReport({ pageSize: process.env.REACT_APP_PAGE_SIZEE })
		// 	.then(({ list: data, pagination }) => {
		// 		setState((prevState) => ({
		// 			...prevState,
		// 			data,
		// 			pagination,
		// 			loading: false,
		// 		}));
		// 	})
		// 	.catch(() => {
		// 		setState((prevState) => ({ ...prevState, loading: false }));
		// 	});
	};

	const expandedRowRender = (record) => {
		let img = `${ENV.basePath}${record.image}`;

		const columns = [
			{
				title: resources.description,
				dataIndex: "description",
				key: "description",
			},
			{
				title: `${resources.description} ${resources.parent}`,
				dataIndex: "parentDescription",
				key: "parentDescription",
			},
			{
				title: ` ${resources.image} ${resources.description} ${resources.parent}`,
				key: "parentImage",
				render: (value, record) => {
					return (
						<>
							{/* {record.parentImage != "-" ? ( */}
							{record.parentImage ? (
								<Image
									src={`${ENV.basePath}${record.parentImage}`}
									// shape="square"
									width={40}
									height={40}
								/>
							) : (
								<>-</>
							)}
						</>
					);
				},
			},
		];
		const data = [];
		data.push({
			description: record.description,
			parentDescription: record.parentDescription,
			parentImage: record.parentImage,
		});

		return (
			<Table
				columns={columns}
				dataSource={data}
				pagination={false}
			/>
		);
	};
	// useEffect(componentDidUpdate, [search]);
	useEffect(componentDidMount, []);

	if (permissions.remove || permissions.update) {
		tableColumns.push({
			title: resources.action,
			dataIndex: "id",
			className: "center-cell",
			width: "120px",
			render: (value, record) => (
				<>
					{permissions.update && (
						<Tooltip title={resources.edit}>
							<Button
								type="link"
								icon={<EditOutlined />}
								onClick={() => handleForm(record)}
							/>
						</Tooltip>
					)}
					{permissions.remove && (
						<Tooltip title={resources.delete}>
							<Button
								type="link"
								icon={<DeleteOutlined />}
								onClick={() => handleDelete(value)}
							/>
						</Tooltip>
					)}
				</>
			),
		});
	}

	return (
		<>
			<Tabs
				className="sp-tabs"
				activeKey={2}
				items={[
					{
						key: 1,
						label: <Link to={paths.edustudent.dailyReports}>{`${resources.dailyreport}`}</Link>,
					},
					{
						key: 2,
						label: <Link to={paths.edustudent.dailyActivity}>{`${resources.eduDailyActivity}`}</Link>,
					},
					{
						key: 3,
						label: <Link to={paths.edustudent.classAssessments}>{`${resources.assessment} ${resources.class}`}</Link>,
					},
					{
						key: 4,
						label: <Link to={paths.edustudent.monthlyGradeSheet}>{`${resources.workbook} ${resources.monthly}`}</Link>,
					},
					{
						key: 5,
						label: <Link to={paths.edustudent.generalExams}>{`${resources.exam} ${resources.general}`}</Link>,
					},
					{
						key: 6,
						label: <Link to={paths.edustudent.generalReports}>{`${resources.report} ${resources.total}`}</Link>,
					},
				]}
			/>

			<Form
				layout="vertical"
				form={form}
			>
				<Row gutter={24}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xxl={4}
					>
						<Form.Item name="date">
							<DatePickerJalali
								onChange={handleFilter}
								style={{ display: "block" }}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<Table
				columns={tableColumns}
				dataSource={data}
				loading={loading}
				onChange={handleTable}
				pagination={pagination}
				scroll={{ x: 930 }}
				size="small"
				rowKey="id"
				expandable={{
					expandedRowRender: (record) => expandedRowRender(record),
				}}
			/>
			<FormModal
				data={selected}
				onUpdate={handleForm}
			/>
			{contextHolder}
		</>
	);
};

export default Component;
