import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Drawer, Form, Input, Select } from "antd";
// import { usePdf } from '@mikecousins/react-pdf';
import PropTypes from "prop-types";


import { Modals, Types } from "scripts/settings";
import api from "scripts/api";
import resources from "scripts/resources";
import rules from "scripts/rules";

// import UploadImage from "components/UploadImageCropper";
import UploadImage from "components/UploadImage";

const Component = ({ data = {}, onUpdate, options: { roles = [] } }) => {
	const initialState = {};
	const [state, setState] = useState(initialState);
	const { submitting, visible } = state;
	const { id, firstName, lastName } = data;
	const { hash, pathname, search } = useLocation();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const handleSubmit = () => {
		if (!submitting) {
			form
				.validateFields()
				.then((values) => {
					setState((prevState) => ({ ...prevState, submitting: true }));

					if (values.birthDate) values.birthDate = values.birthDate.format();
					if (values.avatar?.id) values.avatarId = values.avatar.id;

					delete values.avatar;

					const action = hash === `#${Modals.EDIT}` ? api.user.update(values, id) : api.user.create(values);

					action
						.then(() => {
							setTimeout(function () {
								onUpdate(null, true);
								navigate(-1);
							}, 500);
						})
						.catch(() => {
							setState((prevState) => ({ ...prevState, submitting: false }));
						});
				})
				.catch(() => {});
		}
	};

	const componentDidUpdate = () => {
		switch (hash) {
			case `#${Modals.CREATE}`: {
				setState((prevState) => ({ ...prevState, visible: true }));

				break;
			}
			case `#${Modals.EDIT}`: {
				if (id) {
					setState((prevState) => ({ ...prevState, visible: true }));

					form.setFieldsValue(data);
				} else {
					navigate(`${pathname}${search}`, { replace: true });
				}

				break;
			}
			default: {
				if (visible) form.resetFields();

				setState(initialState);

				break;
			}
		}
	};

	useEffect(componentDidUpdate, [hash]);

	return (
		<Drawer
			className="sp-form-drawer"
			placement="right"
			footer={
				<>
					<Button
						type="primary"
						onClick={handleSubmit}
						loading={submitting}
					>
						{resources.confirm}
					</Button>
					<Button
						onClick={() => navigate(-1)}
						disabled={submitting}
					>
						{resources.cancel}
					</Button>
				</>
			}
			onClose={() => navigate(-1)}
			title={hash === `#${Modals.EDIT}` ? `${resources.edit} ${resources.user} "${firstName} ${lastName}"` : `${resources.add} ${resources.user}`}
			maskClosable={false}
			open={visible}
			width={320}
		>
			<Form
				layout="vertical"
				form={form}
			>
				<Form.Item
					name="firstName"
					label={resources.firstName}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="lastName"
					label={resources.lastName}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="nationalCode"
					label={resources.nationalCode}
					rules={rules.required}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="role"
					label={resources.role}
					rules={rules.required}
				>
					<Select options={roles} />
				</Form.Item>
				<Form.Item
					name="dashboard"
					label={resources.dashboard}
					rules={rules.required}
				>
					<Select
						options={[
							{ value: Types.dashboard.default, label: `${resources.wihtout} ${resources.dashboard}` },
							{ value: Types.dashboard.admin, label: resources.management },
						]}
					/>
				</Form.Item>
				<Form.Item
					name="password"
					label={resources.password}
				>
					<Input.Password />
				</Form.Item>
				<Form.Item
					name="avatar"
					label={resources.image}
				>
					<UploadImage />
				</Form.Item>
			</Form>
		</Drawer>
	);
};

Component.propTypes = {
	data: PropTypes.object,
	onUpdate: PropTypes.func.isRequired,
};

export default Component;
