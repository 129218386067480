import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  FloatButton,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";

import { GetUrlParams, ObjectToURL, AddNotification } from "scripts/helpers";
import { Modals } from "scripts/settings";
import api from "scripts/api";
import paths from "scripts/paths";
import resources from "scripts/resources";

import FormModal from "modals/ClassForm";

const Component = ({ permissions, setRoute }) => {
  const initialState = { data: [], pagination: {} };
  const tableColumns = [
    {
      title: resources.row,
      dataIndex: "index",
      className: "center-cell",
      width: "60px",
      render: (value, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: resources.name,
      dataIndex: "name",
    },
    {
      title: resources.teacher,
      dataIndex: "teacher",
      className: "center-cell",
      render: (value) => (value ? `${value.firstName} ${value.lastName}` : "-"),
    },
    {
      title: resources.manciple,
      dataIndex: "floorMaster",
      className: "center-cell",
      render: (value) =>
        value.firstName ? `${value.firstName} ${value.lastName}` : "-",
    },
    {
      title: `${resources.teacher}${resources.ha}${resources.y} ${resources.general}`,
      dataIndex: "specialTeachers",
      className: "center-cell",
      render: (value) =>
        value
          .map((item) =>
            item.firstName ? `${item.firstName} ${item.lastName}` : "-"
          )
          .join(", "),
    },
    {
      title: resources.grade,
      dataIndex: "grade",
      className: "center-cell",
      render: (value) => value.title,
    },
  ];
  const [state, setState] = useState(initialState);
  const { data, grades, loading, pagination, selected } = state;
  const { pathname, search } = useLocation();
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const fetchData = () => {
    AddNotification();
    const params = GetUrlParams(search);

    form.resetFields();
    form.setFieldsValue(params);

    setState((prevState) => ({ ...prevState, loading: true }));

    api.class
      .list({ ...params, pageSize: process.env.REACT_APP_PAGE_SIZE })
      .then(({ list: data, pagination }) => {
        console.log("data data 0", data);

        setState((prevState) => ({
          ...prevState,
          data,
          pagination,
          loading: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  };

  const handleForm = (selected, reload) => {
    if (reload) {
      fetchData();
    } else {
      setState((prevState) => ({ ...prevState, selected }));

      navigate(
        `${pathname}${search}#${selected ? Modals.EDIT : Modals.CREATE}`
      );
    }
  };

  const handleDelete = (id) => {
    modal.confirm({
      title: resources.deleteConfirm,
      okText: resources.yes,
      okType: "danger",
      cancelText: resources.no,
      onOk: () => {
        setState((prevState) => ({ ...prevState, loading: true }));

        api.class
          .delete(id)
          .then(() => {
            fetchData();
          })
          .catch(() => {
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      },
    });
  };

  const handleTable = ({ current }) => {
    const params = GetUrlParams(search);
    const query = ObjectToURL({ ...params, page: current });

    navigate(`${pathname}?${query}`);
  };

  const handleFilter = debounce(() => {
    form.validateFields().then((values) => {
      const search = ObjectToURL({ page: 1, ...values });

      navigate(`${pathname}?${search}`);
    });
  }, 1000);

  const componentDidUpdate = () => {
    fetchData();
  };

  const componentDidMount = () => {
    setRoute();

    api
      .grades()
      .then((grades) => {
        setState((prevState) => ({ ...prevState, grades }));
      })
      .catch(() => {});
  };

  useEffect(componentDidUpdate, [search]);
  useEffect(componentDidMount, []);

  if (permissions.remove || permissions.update) {
    tableColumns.push({
      title: resources.action,
      dataIndex: "id",
      className: "center-cell",
      width: "120px",
      render: (value, record) => (
        <>
          {permissions.update && (
            <Tooltip title={resources.edit}>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleForm(record)}
              />
            </Tooltip>
          )}
          {permissions.remove && (
            <Tooltip title={resources.delete}>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(value)}
              />
            </Tooltip>
          )}
        </>
      ),
    });
  }

  return (
    <>
      {/* <Tabs
				className="sp-tabs"
				activeKey={3}
				items={[
					{ key: 1, label: <Link to={paths.configs.students}>{resources.students}</Link> },
					{ key: 2, label: <Link to={paths.configs.users}>{resources.users}</Link> },
					{ key: 3, label: <Link to={paths.configs.classes}>{`${resources.class}${resources.ha}`}</Link> },
					{ key: 4, label: <Link to={paths.configs.assessments}>{`${resources.assessment}${resources.ha}`}</Link> },
					{ key: 5, label: <Link to={paths.configs.roles}>{`${resources.role}${resources.ha}`}</Link> },
				]}
			/> */}

      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="search">
              <Input
                suffix={<SearchOutlined />}
                onChange={handleFilter}
                placeholder={resources.search}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Form.Item name="grade">
              <Select
                placeholder={resources.grade}
                options={grades}
                onChange={handleFilter}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        columns={tableColumns}
        dataSource={data}
        loading={loading}
        onChange={handleTable}
        pagination={pagination}
        scroll={{ x: 930 }}
        size="small"
        rowKey="id"
      />

      {permissions.create && (
        <FloatButton
          type="primary"
          tooltip={resources.add}
          icon={<PlusOutlined />}
          onClick={() => handleForm()}
        />
      )}

      {(permissions.create || permissions.update) && (
        <FormModal data={selected} onUpdate={handleForm} options={{ grades }} />
      )}

      {contextHolder}
    </>
  );
};

export default Component;
